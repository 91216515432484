import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuidv4 } from 'uuid';
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import * as gtestComparisonActions from "../../store/gtestComparison";
import * as dialogActions from "../../store/components/dialog";

// 아래 form components의 name과 연계
const defaultValues = {
  item: "",
  unit: "",
  criterion: "",
  result: "합격",
  method: "",
};

const GTestComparisonDialog = ({
  g04docuGCertificationId,
}) => {
  const handleDialogClose = () => {
    dispatch(dialogActions.setOptions({ open: false, crudMode: '', name: "GTestComparisonDialog" }));

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    
  }

  const { handleSubmit, reset, control, getValues, setValue } = useForm({ defaultValues: defaultValues });
  
  const options = useSelector((state) => state.dialog.options);
  const selectedGTestComparison = useSelector((state) => state.gtestComparison.gtestComparison);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  const addGTestComparison = ({ id, g04docuGCertificationId, item, unit, criterion, result, method }) => dispatch(gtestComparisonActions.create({ id, g04docuGCertificationId, item, unit, criterion, result, method }))
  const modifyGTestComparison = ({ id, g04docuGCertificationId, item, unit, criterion, result, method }) => dispatch(gtestComparisonActions.modify({ id, g04docuGCertificationId, item, unit, criterion, result, method }))
  const selectAll = (g04docuGCertificationId) => dispatch(gtestComparisonActions.selectAllByG04docuGCertifications(g04docuGCertificationId))

  const onSubmit = async ({ id, item, unit, criterion, result, method }) => {
    
    // console.log({ id, g04docuGCertificationId, item, unit, criterion, result, method });
    // return;

    let func;
    if (options?.crudMode === 'U') {
      func = modifyGTestComparison;
    } else if (options?.crudMode === 'C') {
      func = addGTestComparison;
    }

    try {
      await func({ id, g04docuGCertificationId, item, unit, criterion, result, method });
      selectAll(g04docuGCertificationId);
      handleDialogClose();
    } catch (e) {

    }
  }

  useEffect(
    () => {
      if (options?.name !== "GTestComparisonDialog") return; // 한 라우터의 화면에 여러 개의 다이얼로그가 있을 때 해당 다이얼로그인지를 구분하기 위해 options.name 사용

      if (options?.crudMode === 'C') {
        setValue("id", uuidv4());
      }
      
    }, [options]
  );

  useEffect(
    () => {
      if (selectedGTestComparison) {
        for (const [item, value] of Object.entries(selectedGTestComparison)) {
          setValue(item, value);
        }
      }
    }, [selectedGTestComparison]
  )

  const handleChangeResult = (e) => {
    setValue("result", e.target.value);
  }

  return (
    <>
      <Dialog
        open={options?.name === "GTestComparisonDialog" && options?.open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        // maxWidth="md"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          { options?.crudMode === 'C' && "시험성과 항목 등록"}
          { options?.crudMode === 'R' && "시험성과 항목 상세"}
          { options?.crudMode === 'U' && "시험성과 항목 수정"}
        </DialogTitleClose>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"id"}
                  control={control}
                  label={"아이디"}
                  inputProps={{ readOnly: options?.crudMode === 'R' && true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"item"}
                  control={control}
                  label={"구분"}
                  inputProps={{ readOnly: options?.crudMode === 'R' && true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"unit"}
                  control={control}
                  label={"단위"}
                  inputProps={{ readOnly: options?.crudMode === 'R' && true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"criterion"}
                  control={control}
                  label={"기준"}
                  inputProps={{ readOnly: options?.crudMode === 'R' && true }}
                  multiline
                  maxRows={30}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  select
                  name={"result"}
                  control={control}
                  label={"판정"}
                  options={[
                    { label: "합격", value: "합격" },
                    { label: "불합격", value: "불합격" },
                    { label: "참조값", value: "참조값" },
                  ]}
                  onChange={handleChangeResult}
                  inputProps={{ readOnly: options?.crudMode === 'R' && true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"method"}
                  control={control}
                  label={"시험방법"}
                  inputProps={{ readOnly: options?.crudMode === 'R' && true }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GTestComparisonDialog;
