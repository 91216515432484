// TODO : 추후 divider 사용하지 않으면 삭제. DB 테이블 필드도 삭제
// TODO : 여기 나오는 유리선택 부분은 GProjectG04Dialog에서 사용하던 것으로 공통된 사용이므로 추후 컴포넌트화 고려할 것
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { GlassUtils } from "../../utils";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputMultipleSelect,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import * as gstandardTypeActions from "../../store/gstandardType";
import * as gstandardActions from "../../store/gstandard";
import * as g04docuGTestActions from "../../store/g04docuGTest";
import * as gglassActions from "../../store/gglass";
import * as gtypeDetailActions from "../../store/gtypeDetail";

const today = new Date();
let endDate = new Date();
// endDate.setFullYear(today.getFullYear() + 2);

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props}/>
))(({ theme }) => ({
  minHeight: 40,
    maxHeight: 40,
    '&.Mui-expanded': {
      maxHeight: 40,
    }
}));

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  gcomponentItemId: null,
  g04docuGCertificationId: null,
  division: "",
  name: "",
  code: "",
  divider: " ",
  comments: "",
  majority: false,
  gstandardTypeId: null,
  classificationManualSetting: false,
};

// TODO : 이렇게 객체로 쓰면 간편한데??? 추후 검토
const cellStyle = {
  borderColor: '#dcdcdc',
  borderBottomWidth: 0, borderBottomWidth: 1, borderBottomStyle: 'solid',
  borderRightWidth: 0, borderRightWidth: 1, borderRightStyle: 'solid',
};

const G04docuDialogGTest = ({
  open,
  setOpen,
  crudMode,
  setCrudMode,
  selectedRow,
  selectedGComponentItemName,
  gstandardId,
  gclientId,
  gclient,
  refresh,
}) => {
  const dependentGcomponentMap = new Map();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [g04docu, setG04docu] = useState('STANDARD');
  // const [details, setDetails] = useState([]);
  const [sample, setSample] = useState({});
  const [majorityCheckValue, setMajorityCheckValue] = useState(false);
  const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [expanded, setExpanded] = useState(true);
  const [selectedGGlass, setSelectedGGlass] = useState(undefined);
  const [gglassesByG04docuGCertification, setGGlassesByG04docuGCertification] = useState([]);
  const [currentSelectedClassifications, setCurrentSelectedClassifications] = useState([]);
  const [classification, setClassification] = useState(""); // TODO : 유리템플릿에서 선택한 복층유리의 인증등급
  // const [classificationManualSetting, setClassificationManualSetting] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }
    
    // 그외 초기화할 것들은 여기서 초기화
    // setDetails([]);
    setSample({});
    initializeGStandard();
    setValue("glassThickness", "");
    setValue("filmThickness", "");
    setValue("gglassId", "");
    initGTypeDetailsWithGComponent()
    setSelectedGcomponentItems([]);
    setCurrentSelectedClassifications([]);
    // setSelectedGComponentItemId(null);
    // setSelectedGComponentItemType("");
    // setSelectedG04docuGCertificationId(null);
  }

  const gstandard = useSelector((state) => state.gstandard.gstandard);
  const gstandards = useSelector((state) => state.gstandard.gstandards);
  const gglasses = useSelector((state) => state.gglass.gglasses);
  const gtypeDetailsWithGComponent = useSelector((state) => state.gtypeDetail.gtypeDetailsWithGComponent);
  const gstandardTypes = useSelector((state) => state.gstandardType.gstandardTypes);
  const gcomponentItemsSpecific = useSelector((state) => state.gcomponentItem.gcomponentItemsSpecific);

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectAllGStandardTypes = () => dispatch(gstandardTypeActions.selectAll())
  const selectGStandardById = (id) => dispatch(gstandardActions.select(id))
  const selectAllGStandard = () => dispatch(gstandardActions.selectAll())
  const initializeGStandard = () => dispatch(gstandardActions.initGStandard())
  const addG04docuGTest = ({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }) => dispatch(g04docuGTestActions.create({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }))
  const modifyG04docuTest = ({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }) => dispatch(g04docuGTestActions.modify({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications }))
  const countGTestsByGClientAndDivisionAndGComponentItemByQueryByDirect = (gclientId, division, gcomponentItemId) => g04docuGTestActions.countByGClientAndDivisionAndGComponentItemByQueryByDirect(gclientId, division, gcomponentItemId)
  const selectGlasses = () => dispatch(gglassActions.selectAllByQuery())
  const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))
  const initGTypeDetailsWithGComponent = () => dispatch(gtypeDetailActions.initWithComponent())

  const onSubmit = async ({ id, name, code, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId }) => {
    setErrors([]);

    switch (selectedRow.division) {
      case 'RAW_MATERIAL':
        if (!getValues("glassThickness")) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: "성적서 규격(원판두께)를 선택해주세요.",
            open: true,
          });
          return;
        }
        break;
        
      case 'PROCESS':
        if (!getValues("gglassId")) {
          setAlertInfo({
            titleAlert: "안내", 
            messageAlert: "유리 템플릿을 선택해주세요.",
            open: true,
          });
          return;
        }
        break;
    }

    // 표준종류 필수 체크 추가
    if (!gstandardTypeId) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "표준종류를 선택해주세요.",
        open: true,
      });
      return;
    }

    const { division } = selectedRow;

    let func;
    if (crudMode === 'C') {
      /**
       * 시가공부자재의 경우 제품별 성적서는 하나만 등록한다.
       * 원자재 및 가공품은 스펙이 존재 (예를 들면 Clear 원판에 3T, 4T, 5T, ... 가 있는 것처럼)
       * 시가공부자재도 실제로는 스펙이 있으나 자재승인서 발급시 거의 사용하지 않아 성적서는 하나만 두는 것으로 결정 (자재승인서류 트리 계층과 연관되어 있음)
       * 그러나 추후 원자재나 가공과 유사한 구조(인증규격이 제품의 상위에 있으므로 완전히 같지는 않음)로 보완될 수 있음을 알아둘 것
       * => 2024-05-02 : 복수개 지원하도록 변경
       */
      // if (division === 'SUB_MATERIAL_BUILD' || division === 'SUB_MATERIAL_PROCESS') {
      //   const count = await countGTestsByGClientAndDivisionAndGComponentItemByQueryByDirect(gclientId, division, gcomponentItemId);
      //   if (count > 0) {
      //     let subMaterial = "";
      //     if (division === 'SUB_MATERIAL_BUILD') {
      //       subMaterial = "시공부자재";
      //     } else if (division === 'SUB_MATERIAL_PROCESS') {
      //       subMaterial = "가공부자재";
      //     }

      //     const messageAlert = (
      //       <div>
      //         <span style={{ color: "#1976d2" }}>{`${subMaterial}`}</span>{`는 `}
      //         <span style={{ color: "#1976d2",  }}><i><u>{`성적서를 포함한 성적서 정보 한 행`}</u></i></span>
      //         {`만 등록할 수 있습니다.`}
      //         <br />
      //         <br />
      //         {"성적서 정보를 변경하려면 아래에서 수정하거나 삭제하고 새로 등록할 수 있습니다."}
      //       </div>
      //     );

      //     setAlertInfo({
      //       titleAlert: "안내",
      //       messageAlert,
      //       open: true,
      //     });

      //     return;
      //   }
      // }
      
      func = addG04docuGTest;
    } else if (crudMode === 'U') {
      // TODO : 자기 자신을 상위(또는 하위)로 가질 수 없으므로 id, pid 같이 않도록 유효성 체크 필요
      func = modifyG04docuTest;
    }    
    
    console.log({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications: currentSelectedClassifications });
    // return;
    
    // TODO : 저장시 currentSelectedClassifications중 하나는 반드시 체크되어 있어야 함

    func({ id, division, name, code, sample, divider, comments, majority, gcomponentItemId, g04docuGCertificationId, gstandardTypeId, gclientId, gclient, selectedClassifications: currentSelectedClassifications })
      .then (async (res) => {
        handleDialogClose();

        refresh(division === 'PROCESS' ? g04docuGCertificationId : gcomponentItemId, division, gclientId);
        // if (g04docuDivision === "ALL") {
        //   await selectAllByQuery();
        // } else {
        //   await selectAllByDivisionByQuery(g04docuDivision);
        // }

        // if (selectedRows.length === 1) {
        //   selectByPid(selectedRows[0].id);
        // }
      })
      .catch (async (res) => {
        console.log(res)
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      await selectAllGStandard();
    }, [dispatch]
  )

  useEffect(
    () => {
      const filteredGGlasses = gglasses.filter(gglass => {
        const exist = gglass.selectedG04docuGCertifications.filter(gcertification => gcertification.gstandardId === gstandardId && gcertification.checked === true);
        return exist.length > 0 ? true : false;
      });
      setGGlassesByG04docuGCertification(filteredGGlasses);
    }, [gglasses]
  )

  // useEffect([selectedRow]) 보다 일찍 실행되므로 id 갱신이 안됨
  // useEffect(
  //   () => {
  //     if (crudMode === 'C') {
  //       setValue("id", uuidv4());
  //     }
  //   }, [crudMode]
  // );

  useEffect(
    async () => {
      if (open) {
        // if (selectedGComponentItemId) {
        //   setValue("gcomponentItemId", selectedGComponentItemId);
        // }

        // if (selectedG04docuGCertificationId) {
        //   setValue("g04docuGCertificationId", selectedG04docuGCertificationId);
        // }
  
        if (gstandardId) {
          setValue("gstandardId", gstandardId);
          selectGStandardById(gstandardId);
        }

        selectAllGStandardTypes();
        selectGlasses();
      }
    }, [open]
  )

  useEffect(
    async () => {
      if (selectedRow) {
        console.log(selectedRow)
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 초기화
        if (crudMode === 'C') {
          setValue("id", uuidv4());
        }
        
        setMajorityCheckValue(selectedRow["majority"]); // TODO : 현재 대표성적서 사용 논리 없음
        
        // 세부 설정 정보 표시
        const { division, code, sample, classifications, selectedClassifications, g04docuGCertificationCode } = selectedRow;
        // console.log({ division, sample, selectedClassifications});
        if (selectedClassifications.filter(i => i.disabled).length === 0) {
          setValue("classificationManualSetting", true);
        } else {
          setValue("classificationManualSetting", false);
        }
        /**
         * sample은 원판의 경우 유리두께 설정값, 가공의 경우 선택한 유리품종 및 설정값을 의미
         * 성적서 규격 등록 모드에서는 sample은 undefined이고, 성적서 규격 상세/수정 모드에서는 설정값이 있음 (undefined일 경우 예외처리 필요)
         */
        if (division) {
          setSample(sample);

          if (division === 'RAW_MATERIAL') { // 원판일 경우 두께정보. 저방사유리의 경우 인증규격(등급) 정보 필요
            const selectedGlassThickness = gcomponentItemsSpecific.find(g => g.id === sample?.details?.id);
            setValue("glassThickness", selectedGlassThickness?.id);

            if (selectedClassifications && Array.isArray(selectedClassifications) && selectedClassifications.length > 0) {
              if (crudMode === 'C') {
                // 원자재에서 설정했다가 설정을 지워도 selectedClassifications는 null이 아님 배열이 존재함
                // 따라서 원자재에서 등급을 설정하지 않은 경우를 배열내 checked가 있는지 여부로 판단
                const checkedClassification = selectedClassifications.find(classification => (classification.checked === true));
                setCurrentSelectedClassifications(selectedClassifications.map(classification => ({ ...classification, disabled: checkedClassification ? true : false })));
              } else {
                setCurrentSelectedClassifications(selectedClassifications);
              }
            } else {
              setCurrentSelectedClassifications(classifications);
            }
          } else if (division === 'PROCESS') { // 가공일 경우 유리 품종 정보와 인증규격(등급) 정보
            console.log(selectedClassifications);
            if (selectedClassifications && Array.isArray(selectedClassifications) && selectedClassifications.length > 0) {
              setCurrentSelectedClassifications(selectedClassifications);
            } else {
              setCurrentSelectedClassifications(classifications);
            }

            // console.log(sample)
            if (sample?.details) {
              const { gglassId, selectedGcomponentItems, gtypeId } = sample?.details;
              setValue("gglassId", gglassId);
              setSelectedGcomponentItems(selectedGcomponentItems);
              await selectGTypeDetailsWithGComponent(gtypeId);
            }
          } else if (division === 'SUB_MATERIAL_PROCESS' || division === 'SUB_MATERIAL_BUILD') { // 시가공부자재의 경우 제품 정보
            if (g04docuGCertificationCode === 'LAMINATED_FILM') {
              const selectedFilmThickness = gcomponentItemsSpecific.find(g => g.id === sample?.details?.id);
              setValue("filmThickness", selectedFilmThickness?.id);
            }

            if (selectedClassifications && Array.isArray(selectedClassifications) && selectedClassifications.length > 0) {
              if (crudMode === 'C') {
                // 시공부자재에서 설정했다가 설정을 지워도 selectedClassifications는 null이 아님 배열이 존재함
                // 따라서 원자재에서 등급을 설정하지 않은 경우를 배열내 checked가 있는지 여부로 판단
                const checkedClassification = selectedClassifications.find(classification => (classification.checked === true));
                setCurrentSelectedClassifications(selectedClassifications.map(classification => ({ ...classification, disabled: checkedClassification ? true : false })));
              } else {
                setCurrentSelectedClassifications(selectedClassifications);
              }
            } else {
              setCurrentSelectedClassifications(classifications);
            }

            if (crudMode === 'C') {
              // 복수개 지원으로 인해 성적서명 자동입력이 아닌 기입하도록 변경
              // setValue("name", selectedGComponentItemName);
              // setValue("code", code);
            } else {
              if (sample) {
                setValue("name", sample?.details?.name);
                setValue("code", sample?.details?.code);
              }
            }
          }
        }
      }
    }, [selectedRow]
  );

  const handleChangeClassificationManualSetting = (event, checked, name) => {
    // console.log({ event, checked, name });
    // setClassificationManualSetting(checked);

    if (checked) { // 수동설정하면 사용자가 직접 "종류·등급·호칭 또는 모델"을 선택함
      const newCurrentSelectedClassifications = currentSelectedClassifications.map(item => ({
        ...item,
        disabled: false,
        checked: false,
      }))

      setCurrentSelectedClassifications(newCurrentSelectedClassifications);
    } else { // 자동설정하면 현 상태 맞는 "종류·등급·호칭 또는 모델" 활성화
      const classification = GlassUtils.getClassification({ type: selectedRow.g04docuGCertificationCode, specificationElements: [], selectedGcomponentItems });
      if (classification) {
        const newCurrentSelectedClassifications = currentSelectedClassifications.map(item => {
          const classificationCompare = item.type.substring(0, classification.length);
          if (classificationCompare === classification) {
            // gstandard.durabilityYN으로 판단해도 되나 길이로 판단하는 것이 좀 더 쉬워보임
            // 전체 문자열이 같다면 바로 그 인증등급이므로 선택이 불필요하여 disabled, checked 모두 true
            if (item.type.length === classification.length) {
              return ({
                ...item,
                disabled: true,
                checked: true,
              })
            } else { // 부분 문자열이 같다면 선택이 필요하므로 (실제로는 내구도가 있는 경우) disabled만 false
              return ({
                ...item,
                disabled: false,
              })
            }
          } else {
            return (
              {
                ...item,
                disabled: true,
                checked: false,
              }
            )
          }
        })

        setCurrentSelectedClassifications(newCurrentSelectedClassifications);
      }
    }
  }

  // useEffect(
  //   () => {
  //     if (classificationManualSetting) { // 수동설정하면 사용자가 직접 "종류·등급·호칭 또는 모델"을 선택함
  //       const newCurrentSelectedClassifications = currentSelectedClassifications.map(item => ({
  //         ...item,
  //         disabled: false,
  //         checked: false,
  //       }))
  
  //       setCurrentSelectedClassifications(newCurrentSelectedClassifications);
  //     } else { // 자동설정하면 현 상태 맞는 "종류·등급·호칭 또는 모델" 활성화
  //       const classification = GlassUtils.getClassification({ type: selectedRow.g04docuGCertificationCode, specificationElements: [], selectedGcomponentItems });
  //       if (classification) {
  //         const newCurrentSelectedClassifications = currentSelectedClassifications.map(item => {
  //           const classificationCompare = item.type.substring(0, classification.length);
  //           if (classificationCompare === classification) {
  //             // gstandard.durabilityYN으로 판단해도 되나 길이로 판단하는 것이 좀 더 쉬워보임
  //             // 전체 문자열이 같다면 바로 그 인증등급이므로 선택이 불필요하여 disabled, checked 모두 true
  //             if (item.type.length === classification.length) {
  //               return ({
  //                 ...item,
  //                 disabled: true,
  //                 checked: true,
  //               })
  //             } else { // 부분 문자열이 같다면 선택이 필요하므로 (실제로는 내구도가 있는 경우) disabled만 false
  //               return ({
  //                 ...item,
  //                 disabled: false,
  //               })
  //             }
  //           } else {
  //             return (
  //               {
  //                 ...item,
  //                 disabled: true,
  //                 checked: false,
  //               }
  //             )
  //           }
  //         })

  //         setCurrentSelectedClassifications(newCurrentSelectedClassifications);
  //       }
  //     }
  //   }, [classificationManualSetting]
  // )

  const handleChangeGlassThickness = (e) => {
    const { value } = e.target;
    const selectedGlassThickness = gcomponentItemsSpecific.find(gt => gt.id === value);
    if (selectedGlassThickness) {
      setValue("glassThickness", value);
      setValue("name", selectedGlassThickness.name);
      setValue("code", selectedGlassThickness.code);
      // setDetails({
      //   selectedValue: {
      //     id: value,
      //     name: selectedGlassThickness.name,
      //     code : selectedGlassThickness.code,
      //   }
      // })
      setSample({
        name: selectedGlassThickness.name,
        details: {
          id: value,
          name: selectedGlassThickness.name,
          code : selectedGlassThickness.code,
        }
      });
    }
  }

  const handleChangeFilmThickness = (e) => {
    const { value } = e.target;
    const selectedFilmThickness = gcomponentItemsSpecific.find(gt => gt.id === value);
    if (selectedFilmThickness) {
      setValue("filmThickness", value);
      setValue("name", selectedFilmThickness.name);
      setValue("code", selectedFilmThickness.code);
      
      setSample({
        name: selectedFilmThickness.name,
        details: {
          id: value,
          name: selectedFilmThickness.name,
          code : selectedFilmThickness.code,
        }
      });
    }
  }

  const handleChangeGGlass = async (e) => {
    const gglassId = e.target.value;
    setSelectedGcomponentItems([]);
    setValue("gglassId", gglassId);

    const selGGlass = gglassesByG04docuGCertification.find(gglass => gglass.id === gglassId);
    console.log(selGGlass)
    if (selGGlass) {
      const { selectedGcomponentItems, gtypeId } = selGGlass;
      console.log(selGGlass)
      setSelectedGGlass(selGGlass);
      setSelectedGcomponentItems(selectedGcomponentItems);
      await selectGTypeDetailsWithGComponent(gtypeId);
    }
  }

  const handleChangeMultiGcomponentItem = (value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/) => {
    // console.log({value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/});
    const itemNew = {
      id: item.id,
      name: item.name,
      value: value.length > 0 ? value.map(v => JSON.parse(v)) : [], // 모두 선택해제되면 []로 설정
    };

    const selectedNew = [].concat(selectedGcomponentItems);
    selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
    
    console.log(selectedNew)
    setSelectedGcomponentItems(selectedNew);

    // TODO : 2024-04-26: 등급에 대한 논리 재검토. 만일 7월 오픈 전까지 정립되지 않으면 disabled 모두 풀어 사용자가 (잘못된 정보일지라도) 선택할 수 있도록 할것
    // 정해진 인증등급이 있다면 자동설정. TODO : 현재 다중선택 속성은 유리 구성시 사용하지 않으므로 아래는 검토하지 않은 상태임
    const classification = GlassUtils.getClassification({ type: selectedRow.g04docuGCertificationCode, specificationElements: [], selectedGcomponentItems: selectedNew });
    // console.log(classification)
    // console.log(currentSelectedClassifications)
    if (classification) {
      const newCurrentSelectedClassifications = currentSelectedClassifications.map(item => {
        const classificationCompare = item.type.substring(0, classification.length);
        if (classificationCompare === classification) {
          // gstandard.durabilityYN으로 판단해도 되나 길이로 판단하는 것이 좀 더 쉬워보임
          // 전체 문자열이 같다면 바로 그 인증등급이므로 선택이 불필요하여 disabled, checked 모두 true
          if (item.type.length === classification.length) {
            return ({
              ...item,
              disabled: true,
              checked: true,
            })
          } else { // 부분 문자열이 같다면 선택이 필요하므로 (실제로는 내구도가 있는 경우) disabled만 false
            return ({
              ...item,
              disabled: false,
            })
          }
        } else {
          return (
            {
              ...item,
              disabled: true,
              checked: false,
            }
          )
        }
      })

      setCurrentSelectedClassifications(newCurrentSelectedClassifications);
    }
  }

  const handleChangeGcomponentItem = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent) => {
    
    // "없음" 선택한 경우 해당 구성요소가 의존하고 있는 구성요소가 있을 경우 안내메시지 띄우고 "없음" 선택안되도록 함
    // properties가 0("없음")인 경우 selectedGcomponentItems[gtypeDetailsOrder]에서 dependentGcomponentItem가 있는 것 중 gcomponentId가 item.id인 것이 있으면 안내 메시지 출력 
    // console.log(selectedGcomponentItems);
    const properties = item.properties.filter(property => property.id === e.target.value);
    if (properties.length <= 0) { // "없음"일 경우
      const gcomponentItems = selectedGcomponentItems[gtypeDetailsOrder];
      // console.log(gcomponentItems)
      const result = gcomponentItems.filter(gcomponentItem => {
        return gcomponentItem.value?.dependentGcomponentItem && JSON.parse(gcomponentItem.value?.dependentGcomponentItem).gcomponentId === item.id
      });
      // console.log(result)
      if (result.length > 0) {
        const { name, value } = result[0];
        const messageAlert = (
          <div>
            <span style={{ color: "#1976d2" }}>{`'${name}'`}</span>{`(이)가 '`}
            <span style={{ color: "#1976d2" }}>{`${value.name}`}</span>{`'(으)로 선택된 경우 `}
            <span style={{ color: "#1976d2" }}>{`'${item.name}'`}</span>{`(은)는 `}
            {/* <span style={{ color: "red" }}>{`'없음'`}</span>{`을 선택할 수 없고,`} */}
            <br/>
            <span style={{ color: "#d32f2f",  }}><i><u>{`반드시 값을 선택`}</u></i></span>
            {`해야 합니다.`}
          </div>
        );

        setAlertInfo({
          titleAlert: "안내",
          messageAlert,
          open: true,
        });

        return;
      }
    }

    let value = "";
    if (properties.length > 0) {
      value = properties[0];
    }
    
    setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, value?.id || value);
    // console.log(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
    
    const itemNew = {
      id: item.id,
      name: item.name,
      code: item.code, // TODO : 다른 곳도 필요시 코드 추가해야 함
      value,
    };

    // const gcomponentItems = selectedGcomponentItems?.length > 0 ?  selectedGcomponentItems : selectedGGlass?.selectedGcomponentItems;
    const gcomponentItems = selectedGcomponentItems;
    
    const selectedNew = [].concat(gcomponentItems);
    selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
    
    // TODO : 해당 값이 변할 때 의존구성요소값이 있다면 그 구성요소의 값을 설정해야 한다. 값을 변화시킬 수 있는데 화면에 설정값으로 렌터링은 아직...
    if (properties.length > 0 && properties[0]?.dependentGcomponentItem?.length > 0) {
      const dependentGcomponentItem = JSON.parse(properties[0].dependentGcomponentItem);
      // console.log(dependentGcomponentItem)

      let targetGcomponentItemOrder = "";
      gcomponent.forEach((component, idx) => {
        if (component.id === dependentGcomponentItem.gcomponentId) {
          targetGcomponentItemOrder = idx;
        }
      });
      
      // console.log(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`);
      setValue(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`, `${dependentGcomponentItem.id}`);
      
      const dependentGcomponentItemNew = {
        id: dependentGcomponentItem.gcomponentId,
        name: dependentGcomponentItem.name,
        value: dependentGcomponentItem,
      }

      selectedNew[gtypeDetailsOrder][targetGcomponentItemOrder] = dependentGcomponentItemNew;

      // TODO : 등록하기에서는 템플릿구조가 선택되지 않아 map 구성이 안되어 있으므로 여기서 구성 필요
      dependentGcomponentMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, {
        ...dependentGcomponentItemNew,
        gtypeOrder: gtypeDetailsOrder,
        gcomponentOrder: targetGcomponentItemOrder,
      });

      // console.log(dependentGcomponentMap);
    } else {
      const map = dependentGcomponentMap.get(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
      if (map) {
        setValue(`${map.gtypeOrder}_${map.gcomponentOrder}_${map.id}`, "");
        selectedNew[map.gtypeOrder][map.gcomponentOrder] = {
          id: map.id,
          name: map.name,
          value: "",
        };
      }
    }
    
    // console.log(selectedNew)
    // console.log(gstandard)
    // console.log(selectedRow)
    setSelectedGcomponentItems(selectedNew);

    // TODO : 아래처럼 할지 useEffect 쓸지 추후 검토
    // setSpec(selectedNew);
    
    // TODO : 2024-04-26: 등급에 대한 논리 재검토. 만일 7월 오픈 전까지 정립되지 않으면 disabled 모두 풀어 사용자가 (잘못된 정보일지라도) 선택할 수 있도록 할것
    // 정해진 인증등급이 있다면 자동설정
    const classification = GlassUtils.getClassification({ type: selectedRow.g04docuGCertificationCode, specificationElements: [], selectedGcomponentItems: selectedNew });
    // console.log(classification)
    // console.log(currentSelectedClassifications)
    if (classification) {
      const newCurrentSelectedClassifications = currentSelectedClassifications.map(item => {
        const classificationCompare = item.type.substring(0, classification.length);
        if (classificationCompare === classification) {
          // gstandard.durabilityYN으로 판단해도 되나 길이로 판단하는 것이 좀 더 쉬워보임
          // 전체 문자열이 같다면 바로 그 인증등급이므로 선택이 불필요하여 disabled, checked 모두 true
          if (item.type.length === classification.length) {
            return ({
              ...item,
              disabled: true,
              checked: true,
            })
          } else { // 부분 문자열이 같다면 선택이 필요하므로 (실제로는 내구도가 있는 경우) disabled만 false
            return ({
              ...item,
              disabled: false,
            })
          }
        } else {
          return (
            {
              ...item,
              disabled: true,
              checked: false,
            }
          )
        }
      })

      setCurrentSelectedClassifications(newCurrentSelectedClassifications);
    }
  }

  // useEffect[selectedGcomponentItems]와 useEffect[details]가 서로 호출해 유리템플릿 설정 변경시 원래대로 돌아가는 문제 발생하였었음
  // useEffect[details]를 삭제하고 useEffect[details] 내부에서 하던 일들을 setDetails 호출하는 부분에서 수행하도록 수정
  // TODO : 테스트 후 이상없으면 아래 주석 코드 영구 삭제할 것
  useEffect(
    () => {
      const gglassId = getValues("gglassId");
      console.log({ gglassId, gtypeId: selectedGGlass?.gtypeId, selectedGcomponentItems})
      // setDetails({
      //   selectedValue: {
      //     gglassId,
      //     gtypeId: selectedGGlass?.gtypeId,
      //     selectedGcomponentItems
      //   }
      // });
      setSample({
        name: gglasses.find(glass => glass.id === gglassId)?.name,
        details: {
          gglassId,
          gtypeId: selectedGGlass?.gtypeId,
          selectedGcomponentItems
        }
      });
    }, [selectedGcomponentItems]
  )

  const generateGTypeDetailsWithRawMaterials = () => {
    // console.log(selectedGGlass);
    // const gcomponentItems = selectedGcomponentItems?.length > 0 ?  selectedGcomponentItems : selectedGGlass?.selectedGcomponentItems;
    const gcomponentItems = selectedGcomponentItems;
    return (
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {
            gtypeDetailsWithGComponent.map((gtypeDetail, i) => {
              return (
                <Grid container spacing={1} sx={{ mt: 1 }}>
                {
                  gtypeDetail.map((gcomponent, j) => {
                    // TODO : 같은 레벨의 properties안에서도 applyType이 productName/etc 섞여 있음
                    if (gcomponent.type === 'property' &&
                        gcomponent.properties &&
                        gcomponent.properties.length &&
                        gcomponent.properties.length > 0 &&
                        gcomponent.properties.filter(property => property.applyType === 'productName').length === gcomponent.properties.length // && // TODO : applyType이 "productName"이 조건이 아니라 별도의 속성이 추가해야 할 수도 있음(2023-03-31)
                        // gcomponent.properties.filter(property => property.applyType === 'productName').length > 0
                      ) {
                      console.log(gcomponent);
                      const materials = gcomponent.properties.filter(property => (property.itemType === "RAW_MATERIAL" || property.itemType === "SUB_MATERIAL_PROCESS"))
                      const manufacturers = gcomponent.properties.filter(property => property.id === getValues(`${i}_${j}_${gcomponent.id}`))[0];
                      console.log(manufacturers?.gclients);
                      return (
                        <>
                          <Grid item sx={{ width: 300 }}>
                            {
                              gcomponent.multipleYN ? (
                                <FormInputMultipleSelect
                                  id="multiple-type-select"
                                  name={`${i}_${j}_${gcomponent.id}`}
                                  control={control}
                                  label={gcomponent.name}
                                  setValue={setValue}
                                  onChangeItem={(value) => handleChangeMultiGcomponentItem(value, gcomponent, i, j, gtypeDetail)}
                                  dynamicItems={{ selectedGcomponentItems: gcomponentItems, gtypeDetailsIndex: i, gcomponentItemIndex: j, gcomponentItem: gcomponent }} // selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex] 넘기면 값이 고정되므로 안됨
                                  options={gcomponent.properties}
                                  chipSize={"small"}
                                  inputProps={{ readOnly: crudMode === 'R' }}
                                />
                              ) : (
                                <FormInputText
                                  select
                                  name={`${i}_${j}_${gcomponent.id}`}
                                  control={control}
                                  label={gcomponent.name}
                                  onChange={(e) => handleChangeGcomponentItem(e, gcomponent, i, j, gtypeDetail)}
                                  options={
                                    [{ label: '없음', value: "" }].concat(gcomponent.properties.map(property => {
                                      return {
                                        label: property.name,
                                        value: property.id,
                                      }
                                    }))
                                  }
                                  inputProps={{ readOnly: crudMode === 'R' }}
                                />
                              )
                            }
                          </Grid>
                        </>
                      )
                    }
                  })
                }
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    )
  }

  const setGcomponentItems = () => {
    const gcomponentItems = selectedGcomponentItems;
    // const gcomponentItems = selectedGcomponentItems?.length > 0 ?  selectedGcomponentItems : selectedGGlass?.selectedGcomponentItems;
    gcomponentItems?.forEach((gtypeDetails, i) => {
      gtypeDetails.forEach((gcomponentItem, j) => {
        if (Array.isArray(gcomponentItem.value)) { // 다중선택일 경우. TODO : 현재 value가 배열인 경우가 다중선택일 경우엔데 속성값으로 판단이 아니라 배열여부이므로 추후 재검토할 것
          // setValue(`${i}_${j}_${gcomponentItem.id}`, gcomponentItem.value);
          // setMultipleValue(gcomponentItem.value);
          // console.log(gcomponentItem.value);
        } else {
          // console.log(`${i}_${j}_${gcomponentItem.id}`);
          // console.log(gcomponentItem.value.id);
          setValue(`${i}_${j}_${gcomponentItem.id}`, gcomponentItem.value.id || ""); // gcomponentItem.value.id가 undefined이면 || "" 없으면 값 초기화 안됨. (setValue(item, undefined) 안됨)
          setValue(`${i}_${j}_${gcomponentItem.id}_gclient`, gcomponentItem.value.selectedGClientId || ""); // gcomponentItem.value.id가 undefined이면 || "" 없으면 값 초기화 안됨. (setValue(item, undefined) 안됨)
        }
      });
    });
  }

  const setSpec = () => {
    // let specification = "";

    // const selected = selectedGcomponentItems;
    // selected && selected.forEach(gtypeDetails => {
    //   let productName = "";
    //   gtypeDetails.forEach(gcomponentItem => {
    //     if (Array.isArray(gcomponentItem.value)) {
    //       gcomponentItem.value.forEach(item => {
    //         const { applyType, code } = item;
    //         if (applyType === 'productName') {
    //           productName += code ? `${code}|` : "";
    //         }
    //       })
    //     } else {
    //       const { applyType, code } = gcomponentItem.value;
    //       if (applyType === 'productName') {
    //         productName += code ? `${code}|` : "";
    //       }
    //     }
    //   });
    //   productName = productName.substring(0, productName.length - 1);
    //   specification += (productName !== "" ? `${productName}+` : "");
    // });
    
    // // return specification.substring(0, specification.length - 1);
    // specification = specification.substring(0, specification.length - 1);
    // setValue("name", specification.replaceAll("|", " ").replaceAll("+", " + "));
    // setValue("code", specification);
    const [specification] = GlassUtils.getSpecification(selectedGcomponentItems);
    setValue("name", specification.replaceAll("|", " ").replaceAll("+", " + "));
    setValue("code", specification);
  }

  const handleChangeCheckValue = (e, checked, name) => {
    // const { selectedClassifications } = selectedRow;
    // console.log(currentSelectedClassifications)
    // console.log(e)
    // console.log(checked)
    // console.log(name)

    // const { name } = e.target;
    const arr = [].concat(currentSelectedClassifications);

    const newCertifications = arr.map(certification => {
      if (certification.name === name) {
        return {
          ...certification,
          checked,
        }
      } else {
        return {
          ...certification,
          checked: false,
        }
      }
    })

    console.log(newCertifications);
    setCurrentSelectedClassifications(newCertifications);
  }

  const generateClassification = () => {
    // console.log(selectedRow)
    // const division = selectedRow?.division;
    // if (!division) return;

    // let classification = "";
    // if (division === 'RAW_MATERIAL') {
    //   classification = GlassUtils.getClassification({ rawMaterial: { selectedClassifications: currentSelectedClassifications } });
    // } else if (division === 'PROCESS') {
    //   // console.log(sample)
    //   // classification = GlassUtils.getClassification({ type: 'INSULATED_GLASS_UNIT', specificationElements: [], selectedGcomponentItems });
    //   console.log({ classification, durabilityYN: gstandard.durabilityYN })
    // }

    // if (classification) {
    //   const { durabilityYN, classificationComponents } = gstandard;
    //   if (durabilityYN) {
    //     return (
    //       <>
    //         <Stack direction="row" alignItems="center">
    //           <Box sx={{ width: 200 }}>
    //             {classification}
    //           </Box>
    //           <FormInputText
    //             select
    //             name={"durability"}
    //             control={control}
    //             // onChange={(e) => handleChangeDurability(e, idx, i)}
    //             options={
    //               classificationComponents[classificationComponents.length-1]?.map(item => { // 내구도는 항상 마지막 컬럼임
    //                 return {
    //                   label: item[`type${classificationComponents.length-1}`],
    //                   value: item[`type${classificationComponents.length-1}`],
    //                 }
    //               })
    //             }
    //             // value={g04docuGCertification.selectedDurability}
    //             sx={{ width: 200, ml: 1 }}
    //           />
    //         </Stack>
    //       </>
    //     );
    //   } else {
    //     return (
    //       <>
    //         {classification}
    //       </>
    //     );
    //   }
    // } else {
      return (
        <Stack direction="row">
          {
            gstandard.classificationComponents.map((types, i) => {
              let prevArrayLength = 1;
              if (i > 0) {
                for(let k=i-1; k>=0; k--) prevArrayLength = gstandard.classificationComponents[k].length*prevArrayLength; // 현재 배열의 앞 배열들의 곱집합 크기
              }

              if (i === gstandard.classificationComponents.length - 1) {
                return (
                  <>
                    <Grid container>
                      {
                        Array.from(' '.repeat(prevArrayLength)).map(() => ( // Array.from의 첫번째 인자에 '' 빈 문자열은 안됨에 유의
                          types.map(type => (<Grid item xs={12} display="flex" sx={cellStyle}>
                            <Typography sx={{ p: 1 }}>{type[`type${i}`]}</Typography>
                          </Grid>))
                        ))
                      }
                    </Grid>
                    {/* <Grid container sx={{ width: '42px' }}>
                      {
                        Array.from(' '.repeat(prevArrayLength)).map((value, j) => {
                          return (
                            types.map((type, k) => (<Grid item xs={12} display="flex" sx={cellStyle}>
                              {types.length*j+k}
                              {`${i}_${k}`}
                              <FormInputCheckbox
                                // name={`${i}_certificationStandard`}
                                control={control}
                                // label={item}
                                // setValue={setValue}
                                // onChangeCheckValue={handleChangeCheckValue}
                                // checked={item.checked}
                              />
                            </Grid>))
                          )
                        })
                      }
                    </Grid> */}
                  </>
                )
              } else {
                return (
                  <Grid container>
                    {
                      Array.from(' '.repeat(prevArrayLength)).map(() => ( // Array.from의 첫번째 인자에 '' 빈 문자열은 안됨에 유의
                        types.map(type => (<Grid item xs={12} display="flex" sx={cellStyle}>
                          <Typography sx={{ p: 1 }}>{type[`type${i}`]}</Typography>
                        </Grid>))
                      ))
                    }
                  </Grid>
                )
              }
            })
          }
          <Grid container sx={{ width: '42px' }}>
            {
              currentSelectedClassifications?.map((item, i) => {
                console.log(currentSelectedClassifications)
                return (
                  <Grid item xs={12} display="flex" alignItems="center" sx={{ ...cellStyle }}>
                    <FormInputCheckbox
                      name={`${i}_classificationStandard`}
                      control={control}
                      // label={item}
                      setValue={setValue}
                      onChangeCheckValue={handleChangeCheckValue}
                      // item.check가 초기에 undefined이면 checked가 초기값이 설정되지 않으므로 state 변경시 rendering에 문제
                      // TODO : controlled / uncontrolled 학습할 것 => 다른 form input 들도 내부에서 강제 rendering 했는데 이 문제일 수 있음
                      checked={item.checked ? item.checked : false}
                      disabled={crudMode === 'R' ? true : (item.disabled ? item.disabled : false)}
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        </Stack>
      )
    // }
  }

  return (
    <>
      <Dialog
        PaperProps={{style: {overflowY: 'visible'}}}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={"lg"}
        scroll={"body"}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {/* { crudMode === 'C' && `${selectedGComponentItemName} 성적서 목록 등록` }
          { crudMode === 'R' && `${selectedGComponentItemName} 성적서 목록 상세` }
          { crudMode === 'U' && `${selectedGComponentItemName} 성적서 목록 수정` } */}
          { crudMode === 'C' && <Typography><span style={{ color: "#1976d2", fontWeight: 'bold' }}>{selectedRow?.division === 'PROCESS' ? selectedRow?.g04docuGCertificationName : selectedGComponentItemName}</span>{" 성적서 규격 등록"}</Typography> }
          { crudMode === 'R' && <Typography><span style={{ color: "#1976d2", fontWeight: 'bold' }}>{selectedRow?.division === 'PROCESS' ? selectedRow?.g04docuGCertificationName : selectedGComponentItemName}</span>{" 성적서 규격 상세"}</Typography> }
          { crudMode === 'U' && <Typography><span style={{ color: "#1976d2", fontWeight: 'bold' }}>{selectedRow?.division === 'PROCESS' ? selectedRow?.g04docuGCertificationName : selectedGComponentItemName}</span>{" 성적서 규격 수정"}</Typography> }
        </DialogTitleClose>
        <DialogContent style={{ overflowY: 'visible' }}>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            {/* {
              JSON.stringify(selectedRow, null, 2)
            } */}
          </ul>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" alignItems="end" sx={{ display: majorityCheckValue ? 'none' : '' }}>
              <FormInputDropdown
                name={"gstandardId"}
                control={control}
                label={"표준명(한글)"}
                // onChangeSelectValue={handleChangeGStandard}
                options={
                  [{ label: '없음', value: "" }].concat(gstandards.map(gstandard => {
                    return {
                      label: gstandard.no,
                      value: gstandard.id,
                      name: gstandard.name,
                    }
                  }))
                }
                // inputProps={
                //   { readOnly: crudMode === 'R' }
                // }
                inputProps={
                  { readOnly: true }
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
                inputProps={
                  { readOnly : true }
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"gcomponentItemId"}
                control={control}
                label={"제품아이디"}
                inputProps={
                  { readOnly : true }
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"g04docuGCertificationId"}
                control={control}
                label={"인증규격아이디"}
                inputProps={
                  { readOnly : true }
                }
              />
            </Grid>
            {
              selectedRow?.division === 'RAW_MATERIAL' && (<Grid item xs={12}>
                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                  <StyledAccordionSummary aria-controls="basic-content" id="basic-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">{"원판두께선택"}</Typography>
                      </Grid>
                    </Grid>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item sx={{ width: 300}}>
                        <FormInputText
                          select
                          name={"glassThickness"}
                          control={control}
                          label={"성적서 규격(원판두께)"}
                          inputProps={{ readOnly: crudMode === 'R' }}
                          options={gcomponentItemsSpecific.map(item => ({ label: item.name, value: item.id }))}
                          onChange={handleChangeGlassThickness}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>)
            }
            {
              selectedRow?.division === 'PROCESS' && (<Grid item xs={12}>
                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                  <StyledAccordionSummary aria-controls="basic-content" id="basic-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">{"유리선택"}</Typography>
                      </Grid>
                    </Grid>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    {/* <Grid item sm={12} sx={{ mt: 2 }}>
                      <Box sx={{ p: 0.8, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                        {"유리선택"}
                      </Box>
                    </Grid> */}
                    <Grid container spacing={1}>
                      <Grid item sx={{ width: 300}}>
                        <FormInputText
                          select
                          name={"gglassId"}
                          control={control}
                          label={"유리 템플릿"}
                          options={gglassesByG04docuGCertification.map(gglass => {
                            return {
                              label: gglass.name,
                              value: gglass.id,
                            }
                          })}
                          onChange={handleChangeGGlass}
                          inputProps={{ readOnly: crudMode === 'R' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      { generateGTypeDetailsWithRawMaterials() }
                      { setGcomponentItems() }
                      { setSpec() }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>)
            }
            {
              // 가공부자재 중 접합필름이 있는 경우 접합필름 두께 설정
              selectedRow?.division === 'SUB_MATERIAL_PROCESS' && selectedRow?.g04docuGCertificationCode === 'LAMINATED_FILM' && (<Grid item xs={12}>
                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                  <StyledAccordionSummary aria-controls="basic-content" id="basic-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">{"필름두께선택"}</Typography>
                      </Grid>
                    </Grid>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item sx={{ width: 300}}>
                        <FormInputText
                          select
                          name={"filmThickness"}
                          control={control}
                          label={"성적서 규격(필름두께)"}
                          inputProps={{ readOnly: crudMode === 'R' }}
                          options={gcomponentItemsSpecific.map(item => ({ label: item.name, value: item.id }))}
                          onChange={handleChangeFilmThickness}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>)
            }
            <Grid item xs={12}>
              <FormInputText
                name={"name"}
                control={control}
                // label={"제품명"}
                label={"성적서명"}
                inputProps={{
                  readOnly: selectedRow?.division === 'RAW_MATERIAL' || selectedRow?.division === 'PROCESS' ||
                            (selectedRow?.division === 'SUB_MATERIAL_PROCESS' && selectedRow?.g04docuGCertificationCode === 'LAMINATED_FILM') ? 
                            true : 
                            (crudMode === 'R')
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"code"}
                control={control}
                label={"코드"}
                inputProps={
                  { readOnly : true }
                }
              />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="end">
              <FormInputDropdown
                name={"gstandardTypeId"}
                control={control}
                label={"표준 종류"}
                // onChangeSelectValue={handleChangeGStandardType}
                options={
                  gstandardTypes.map(gstandardType => {
                    return {
                      label: gstandardType.name,
                      value: gstandardType.id,
                    }
                  })
                }
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            {/* {
              selectedRow?.division === 'SUB_MATERIAL_PROCESS' && "제품명"
            }
            {
              selectedRow?.division === 'SUB_MATERIAL_BUILD' && "제품명"
            } */}
            {/* {
              gstandard && (
                <Grid item xs={12} sx={{ display: majorityCheckValue ? 'none' : '' }}>
                  <Grid container spacing={1}>
                    {
                      gstandard.testCertifications?.map((testCertification, i) =>  (
                        <Grid item xs={12/gstandard.testCertifications?.length}>
                          <FormInputText
                            select
                            control={control}
                            label={"성적서 규격"}
                            inputProps={
                              { readOnly: crudMode === 'R' }
                            }
                            options={
                              testCertification.map(item => ({
                                label: item[`type${i}`],
                                value: item[`type${i}`],
                              }))
                            }
                            onChange={(e) => handleChangeDetails(e, i)}
                            value={details && details.length > i && details[i]}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              )
            } */}
            {/* <Grid item xs={12} sx={{ display: majorityCheckValue ? 'none' : '' }}>
              <FormInputText
                select
                name={"divider"}
                control={control}
                label={"구분자"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
                options={[
                  // { label: "없음", value: "none" },
                  { label: "공백", value: " " },
                  { label: "+", value: "+" },
                  { label: "-", value: "-" },
                  { label: "*", value: "*" },
                  { label: "/", value: "/" },
                  { label: ",", value: "," },
                ]}
                onChange={handleChangeDivider}
              />
            </Grid> */}
            <Grid item xs={12}>
            {
              gstandard && gstandard.classificationComponents && (
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Card>
                    <CardHeader
                      titleTypographyProps={{ variant: 'subtitle1' }}
                      title={"종류·등급·호칭 또는 모델"}
                      sx={{ bgcolor: "#eaeaea" }}
                      action={
                        <Stack direction="row" display="flex" alignItems="center" sx={{ mr: 1 }}>
                          <Typography>{"수동설정"}</Typography>
                          <FormInputCheckbox
                            name="classificationManualSetting"
                            control={control}
                            setValue={setValue}
                            onChangeCheckValue={handleChangeClassificationManualSetting}
                            inputProps={
                              { readOnly: crudMode === 'R' }
                            }
                          />
                        </Stack>
                      }
                    />
                    <CardContent>
                      <Grid conatiner>
                        <Grid item xs={12}>
                          {
                            generateClassification()
                          }
                          {/* {
                            gstandard.durabilityYN === false ? (
                              <>
                                {
                                  selectedRow?.selectedClassifications?.filter(classification => classification.checked === true).length === 1 ?
                                        selectedRow.selectedClassifications.filter(classification => classification.checked === true)[0].type
                                      : 
                                        <span style={{ color: "#1976d2" }}><i>{"원자재에 인증등급(종류·등급·호칭 또는 모델)을 설정해주세요."}</i></span>
                                }
                              </>
                            ) : (
                              <></>
                            )
                          } */}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
            </Grid>
            {/* TODO : 추후 성적서 목록시 대표성적서 등록이 되게 하려면 나머지는 대표성적서 해제가 필요하고 성적서 규격을 선택하는 것이 아닌 이름도 직접 쓸 수 있도록 해야 한다. */}
            {/* {
              crudMode === 'U' && (
                <Grid item xs={12} display="flex" alignItems="center">
                  <FormInputCheckbox
                    name="majority"
                    control={control}
                    // label={"주로 사용"}
                    setValue={setValue}
                    onChangeCheckValue={handleChangeCheckButton}
                    inputProps={
                      { readOnly: crudMode === 'R' }
                    }
                  />
                  <Typography>
                    {
                      selectedRow?.division === 'PROCESS' ? `가공품 ` : `제품 `
                    }
                    <span style={{ color: "#1976d2", fontWeight: 'bold' }}><u>
                    {
                      selectedRow?.division === 'PROCESS' ?
                        selectedRow?.g04docuGCertificationName :
                        selectedGComponentItemName
                    }
                    </u></span>
                    {"의 "}
                    <span style={{ color: "#1976d2", fontWeight: 'bold' }}><u>{"대표성적서"}</u></span>
                    {"로 등록"}
                  </Typography>
                </Grid>
              )
            } */}
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            crudMode === 'C' && (
              <>
                <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
                <Button onClick={() => reset()}>{"초기화"}</Button>
              </>
            )
          }
          { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default G04docuDialogGTest;
