import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import {
  DialogTitleClose,
  PaperComponent,
} from "./components/dialog";
import "./Consent.css";
import { uploadCmsFile } from './store/gclient';
import { SERVICE_AMOUNTS } from './constants/serviceAmounts'; 

// 파일 업로드를 위한 스타일드 컴포넌트
const UploadBox = styled('div')(({ theme }) => ({
  border: '2px dashed #e0e0e0',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: '#fafafa',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: '#f5f5f5'
  }
}));

const CmsDialog = ({
  open,
  setOpen,
}) => {

  const [partnership, setPartnership] = useState('일반회원사'); 
  const [membership, setMembership] = useState('OPTION01'); 
  const [uploadedFile, setUploadedFile] = useState(null);

  // dialog가 열릴 때 상태 초기화
  useEffect(() => {
    if (open) {
      setPartnership('일반회원사');
      setMembership('OPTION01');
      setUploadedFile(null);
    }
  }, [open]);

  const handleDialogClose = (e, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    
    if (setOpen) {
      setOpen(false);
    }
  };

  // useEffect(() => {
  //   if (!open) {
  //     handleDialogClose();
  //   }
  // }, [open]);

  const handleEmptyClose = () => {};


  const initDialog = () => {
  }

  const sessionUser = useSelector(state => state.session.sessionUser);

  // 데이터 관리
  const dispatch = useDispatch();

  const handleClickCmsUpload = async () => {
    if (!uploadedFile) {
      alert('CMS 신청서 파일을 첨부해주세요.');
      return;
    }

    try {
      const cmsFilePath = await dispatch(uploadCmsFile(
        sessionUser.id,
        uploadedFile,
        partnership,
        membership
      ));
      if (cmsFilePath) {
        alert('CMS 신청서가 성공적으로 업로드되었습니다.');
        console.log('업로드된 CMS 파일 경로:', cmsFilePath);
        setOpen(false);
      } else {
        alert('CMS 신청서 업로드에 실패했습니다.');
      }
    } catch (error) {
      alert('CMS 신청서 업로드에 실패했습니다.');
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleDownload = () => {
    // CMS 신청서 다운로드 로직 구현
    let fileName = '';
 
  // 회원 구분과 서비스 옵션에 따라 파일명 결정
  if (partnership === '일반회원사') {
    fileName = membership === 'OPTION01' 
      ? '공사다큐 CMS 신청서_회원사_자재_납품.pdf'
      : '공사다큐 CMS 신청서_회원사_자재.pdf';
  } else {
    fileName = membership === 'OPTION01'
      ? '공사다큐 CMS 신청서_비회원사_자재_납품.pdf'
      : '공사다큐 CMS 신청서_비회원사_자재.pdf';
  }
    window.open(`/${fileName}`);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      setUploadedFile(files[0]);
    }
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="lg"
      onClose={handleDialogClose}
      disableEscapeKeyDown
      sx={{ 
        zIndex: 9999,
        '& .MuiDialog-paper': {
          minHeight: '98vh',  // 뷰포트 높이의 90%
        }
      }}
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        // onClose={handleEmptyClose}
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
        // hideCloseButton={true} 
      >
        {"공사다큐 서비스 가입신청"}
      </DialogTitleClose>
      <DialogContent sx={{ width: '800px' }} >
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ fontSize: '0.9rem' }}>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>{"한국판유리창호협회 회원"}</Typography>
              <FormControl component="fieldset" sx={{ mt: 1, ml: 3 }}>
                <RadioGroup
                  row
                  name="partnership"
                  value={partnership}
                  onChange={(e) => setPartnership(e.target.value)}
                >
                  <FormControlLabel 
                    value="일반회원사" 
                    control={<Radio />} 
                    label="일반 회원사" 
                  />
                  <FormControlLabel 
                    value="비회원사" 
                    control={<Radio />} 
                    label="비 회원사" 
                  />
                </RadioGroup>
              </FormControl>

              <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 1 }}>{"공사다큐 서비스"}</Typography>
              <FormControl component="fieldset" sx={{ mt: 1, ml: 3 }}>
                <RadioGroup
                  row 
                  name="membership"
                  value={membership}
                  onChange={(e) => setMembership(e.target.value)}
                >
                  <FormControlLabel 
                    value="DEFAULT" 
                    control={<Radio />} 
                    label={
                      <Typography 
                        sx={{   
                          textDecoration: 'line-through'  // 취소선 추가
                        }}  
                      >
                        자재승인서
                      </Typography>
                    }
                    disabled
                  />
                  <FormControlLabel 
                    value="OPTION01" 
                    control={<Radio />} 
                    label="자재승인서+납품확인서" 
                    defaultChecked
                  />
                </RadioGroup>
              </FormControl>
              {/* 알림 메시지 추가 */}
              <Alert severity="info" 
                sx={{ mt: 1, mb: 1, ml: 3, fontSize: '0.8rem','& .MuiAlert-message': {fontWeight: 'bold' } }}>
                납품확인서는 2025년 12월 31일까지 무료로 사용하실 수 있습니다.
                2026년 1월 1일부터 유료로 전환됩니다.
              </Alert>
              {/* 서비스 요금 테이블 */}
              <TableContainer component={Paper} 
                sx={{ 
                  mt: 1,
                  ml: 3,  // 왼쪽 마진 추가
                  width: 'calc(100% - 24px)'  // ml: 3은 24px이므로 그만큼 너비 감소
                }}
              >
                <Table size="small"
                  sx={{ 
                    '& td, & th': { 
                      border: '1px solid #e0e0e0'  // 진한 회색 테두리
                    },
                    border: '1px solid #e0e0e0'    // 테이블 외곽 테두리
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                      <TableCell rowSpan={2} align="center" sx={{ fontWeight: 'bold', verticalAlign: 'middle' }}>공사다큐 서비스</TableCell>
                      <TableCell rowSpan={2} align="center" sx={{ fontWeight: 'bold', verticalAlign: 'middle' }}>이용기간</TableCell>
                      <TableCell colSpan={2} align="center" sx={{ fontWeight: 'bold' }}>요금 (VAT포함)</TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                      <TableCell align="center" sx={{ fontWeight: 'bold' }}>일반 회원사</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold' }}>비 회원사</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>자재승인서</TableCell>
                      <TableCell align="center">12개월</TableCell>
                      <TableCell align="right"><b>{SERVICE_AMOUNTS.MEMBER_BASIC.toLocaleString()}원</b></TableCell>
                      <TableCell align="right" sx={{ whiteSpace: 'pre-line' }}>
                        <span style={{ textDecoration: 'line-through' }}>264,000원</span> &nbsp; <b>{SERVICE_AMOUNTS.NON_MEMBER_BASIC.toLocaleString()}원</b> {'\n'}
                        <span style={{ color: '#1976d2' }}>(16% 할인)</span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>자재승인서 + 납품확인서</TableCell>
                      <TableCell align="center">12개월</TableCell>
                      <TableCell align="right" sx={{ whiteSpace: 'pre-line' }}>
                        <span style={{ textDecoration: 'line-through' }}>264,000원</span> &nbsp; <b>{SERVICE_AMOUNTS.MEMBER_BASIC.toLocaleString()}원</b> {'\n'} 
                        <span style={{ color: '#1976d2' }}>(2025년 12월 31일 까지)</span>
                      </TableCell>
                      <TableCell align="right" sx={{ whiteSpace: 'pre-line' }}>
                        <span style={{ textDecoration: 'line-through' }}>528,000원</span> &nbsp; <b>{SERVICE_AMOUNTS.NON_MEMBER_OPTION01.toLocaleString()}원</b> {'\n'}
                        <span style={{ color: '#1976d2' }}>(16% 할인)</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 2 }}>{"CMS 신청서 등록"}</Typography>
              {/* 다운로드 버튼 */}
              <Button
                variant="outlined"
                startIcon={<CloudDownloadIcon />}
                onClick={handleDownload}
                sx={{ mt: 1, mb: 2, ml: 3, width: 'calc(100% - 24px)' }}
                fullWidth
              >
                CMS 신청서 서식 다운로드
              </Button>

              {/* 파일 업로드 영역 */}
              <input
                type="file"
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                style={{ display: 'none' }}
                id="cms-file-upload"
                onChange={handleFileUpload}
              />
              <label htmlFor="cms-file-upload" style={{ width: '100%' }}>
                <UploadBox 
                  sx={{ ml: 3 }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  {!uploadedFile ? (
                    <>
                      <CloudUploadIcon sx={{ color: 'primary.main', mb: 1 }} />
                      <Typography sx={{ mb: 1 }}>
                        작성하신 CMS 신청서를 첨부해주세요.
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        여기에 파일을 놓거나 파일찾기를 누르세요.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="body2" color="primary" sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                        CMS 신청서 파일: {uploadedFile.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        다른 파일로 변경하려면 클릭하거나 파일을 끌어다 놓으세요.
                      </Typography>
                    </>
                  )}
                </UploadBox>
              </label>
            </Grid>
          </Grid>

      </DialogContent>
      <DialogActions>
        <Grid sx={{ p: 1, width: '100%' }}>
          <Button variant="contained" onClick={handleClickCmsUpload} 
                  fullWidth sx={{ fontSize: '1.1rem', ml: 3, width: 'calc(100% - 24px)' }} >
            {"신청하기"}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CmsDialog;
