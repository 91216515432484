import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  Stack,
} from '@mui/material';
import  {
  Add,
  Remove,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gstandardTypeActions from "../../store/gstandardType";
import * as gstandardActions from "../../store/gstandard";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  no: "",
  name: "",
  code: "",
  comments: "",
  guideLink: "",
  gstandardTypeId: null,
  durabilityYN: false,
};

const columnsLength = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
];

const GStandardDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  queryPaging,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [classificationComponentsColumnsLength, setClassificationComponentsColumnsLength] = useState(1);
  const [classificationComponents, setClassificationComponents] = useState([]);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setClassificationComponentsColumnsLength(1);
    setClassificationComponents([[{ "type0": "" }]]);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  const gstandardTypes = useSelector((state) => state.gstandardType.gstandardTypes);

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllGStandardTypes = () => dispatch(gstandardTypeActions.selectAll())
  const addGStandard = ({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }) => dispatch(gstandardActions.create({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }))
  const modifyGStandard = ({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }) => dispatch(gstandardActions.modify({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN }))

  const onSubmit = ({ id, no, name, code, comments, guideLink, gstandardTypeId, durabilityYN }) => {
    setErrors([]);
    
    // console.log({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN });
    // return;
    let func;
    if (crudMode === 'C') {
      func = addGStandard;
    } else if ((crudMode === 'U')) {
      func = modifyGStandard;
    }
    func({ id, no, name, code, classificationComponents, comments, guideLink, gstandardTypeId, durabilityYN })
      .then (res => {
        handleDialogClose();
        refresh(queryPaging);
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }
  
  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
        const newClassificationComponents = selectedRow["classificationComponents"];
        if (newClassificationComponents) {
          setClassificationComponentsColumnsLength(newClassificationComponents.length);
          setClassificationComponents(newClassificationComponents);
        }
      }
    }, [selectedRow]
  );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [crudMode]
  );

  // TODO : 아래 조회를 매번 다이얼로그를 열때마다 할지(1) 목록 화면에서 한번만 할지는 고민중(2)
  // (1)은 항상 뉴 데이터, (2)는 매번 조회하는 수고를 덜 수 있음. 이렇게 보면 (1)을 선택하는 것이 옳다고 생각하나 데이터가 자주 변하지 않으므로 고민중...
  useEffect(
    () => {
      selectAllGStandardTypes();
    }, [open]
  )
  
  const handleChangeTypeClassificationComponents = (e) => {
    const length = e.target.value;
    setClassificationComponentsColumnsLength(length);
    // console.log(classificationComponents)

    const newClassificationComponents = [].concat(classificationComponents);
    const toAddLength = length - classificationComponents.length;
    if (toAddLength > 0) {
      // const toAddArr = ((new Array(1)).fill("")).map((item, idx) => ({ [`type${idx+toAddLength}`]: "" }));
      for(let i=0; i<toAddLength; i++) {
        const toAddArr = ((new Array(1)).fill({ [`type${i}`] : "" }));
        // console.log(toAddArr);
        newClassificationComponents.push(toAddArr);
      }
      // console.log(newClassificationComponents)
      setClassificationComponents(newClassificationComponents);
    } else {
      setClassificationComponents(classificationComponents.slice(0, length));
    }
  }

  const handleChangeClassificationComponentsTypeValue = (e, col, row) => {
    const { value } = e.target;
    const newClassificationComponents = classificationComponents.slice(); // 복사

    newClassificationComponents[col].splice(row, 1, { [`type${col}`]: value });

    setClassificationComponents(newClassificationComponents);
  }

  const addClassificationComponents = (col) => {
    const newClassificationComponents = classificationComponents.slice(); // 복사
    newClassificationComponents[col].push({
      [`type${col}`]: "",
    });

    setClassificationComponents(newClassificationComponents);
  }

  const deleteClassificationComponents = (col, row) => {
    const newClassificationComponents = classificationComponents.slice(); // 복사
    // console.log(JSON.stringify(newClassificationComponents))

    newClassificationComponents[col].splice(row, 1);

    // console.log(JSON.stringify(newClassificationComponents))

    setClassificationComponents(newClassificationComponents);
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      // maxWidth="sm"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'C' && "자재 표준 등록"}
        { crudMode === 'R' && "자재 표준 상세"}
        { crudMode === 'U' && "자재 표준 수정"}
      </DialogTitleClose>
      <DialogContent>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"no"}
              control={control}
              label={"표준번호"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"표준명(한글)"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="end">
            <FormInputDropdown
              name={"gstandardTypeId"}
              control={control}
              label={"표준 종류"}
              // onChangeSelectValue={handleChangeGStandard}
              options={
                [{ label: '없음', value: "" }].concat(gstandardTypes.map(gstandardType => {
                  return {
                    label: gstandardType.name,
                    value: gstandardType.id,
                  }
                }))
              }
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{ bgcolor: "#eeeeee", color: "#888888" }}
                titleTypographyProps={{ variant: 'subtitle1' }}
                // title={"인증서 규격"}
                title={"종류·등급·호칭 또는 모델"}
                action={
                  <FormInputDropdown
                    // name={"typeCertification"}
                    name={"typeClassificationComponent"}
                    control={control}
                    // label={"타입"}
                    options={columnsLength}
                    // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
                    onChange={handleChangeTypeClassificationComponents}
                    value={classificationComponentsColumnsLength}
                    inputProps={
                      { readOnly: crudMode === 'R'}
                    }
                  />
                }
              />
              <Grid container spacing={1} sx={{ p: 2 }}>
                {
                  classificationComponents.map((classification, i) => {
                    return (
                      <Grid item xs={12/classificationComponentsColumnsLength}>
                        <Grid container spacing={1}>
                        {
                          classification.map((item, j) => {
                            return (
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={9}>
                                    <FormInputText
                                      name={`classification_${i}_${j}`}
                                      control={control}
                                      label=""
                                      variant="outlined"
                                      value={item[`type${i}`]}
                                      onChange={(e) => handleChangeClassificationComponentsTypeValue(e, i, j)}
                                      inputProps={
                                        { readOnly: crudMode === 'R' }
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={3}/* sx={{ display: crudMode === 'R' ? "none" : ""}}*/>
                                    {
                                      j === 0 ? (
                                        <Fab
                                          color="primary"
                                          size="small"
                                          onClick={() => addClassificationComponents(i)}
                                          disabled={crudMode === 'R'}
                                        >
                                          <Add />
                                        </Fab>
                                      ) : (
                                        <Fab
                                          color="secondary"
                                          size="small"
                                          onClick={() => deleteClassificationComponents(i, j)}
                                          disabled={crudMode === 'R'}
                                        >
                                          <Remove />
                                        </Fab>
                                      )
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                      </Grid>
                    )
                  })
                }
                <Grid item xs={12}>
                  <Box sx={{ mt: 1, p: 1, border: '1px solid #E0E0E0', borderRadius: 1, width: '100%' }}>
                    <Stack direction="row" alignItems="center" sx={{ ml: 1 }}>
                      {"내구성 등급 있음"}
                      <FormInputCheckbox
                        name={"durabilityYN"}
                        control={control}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"guideLink"}
              control={control}
              label={"링크"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && (
            <>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>
          )
        }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GStandardDialog;
