import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Grid,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import {
  FormInputText,
} from "../form";
import { StyledTooltip } from "../tooltip";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import {
  dateFormat,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as gclientActions from "../../store/gclient";
import * as g04docuGTestActions from "../../store/g04docuGTest";
import G04docuFileDialogGeneral from "../G04docuFile/G04docuFileDialogGeneral";
import G04docuFileDialogSupplyRecord from "../G04docuFile/G04docuFileDialogSupplyRecord";
import G04docuFileDialogEtc from "../G04docuFile/G04docuFileDialogEtc";
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};


const ExpiredDocuments = ({ expiredDocuments }) => {

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openSupplyRecord, setOpenSupplyRecord] = useState(false);
  const [openFileEtc, setOpenFileEtc] = useState(false);  
  const [selectedRowGeneral, setSelectedRowGeneral] = useState(null); 
  const [selectedRowEtc, setSelectedRowEtc] = useState(null);
  const [selectedGClientId, setSelectedGClientId] = useState(null);
  const [selectedGClient, setSelectedGClient] = useState(null);
  const [selectedDocuFileType, setSelectedDocuFileType] = useState(null);
  const [currentUrl, setCurrentUrl] = useState("");
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [crudModeGeneral, setCrudModeGeneral] = useState('create');
  const [open, setOpen] = useState(false);

  const sessionUser = useSelector((state) => state.session.sessionUser);

  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        //const res = await dispatch(g04docuGTestActions.select('07a7c891-4196-4c00-9efe-58b07930c5a8'));
        //console.log(res);

        const response = await gclientActions.selectGClientDocumentsDetail(sessionUser.id, null, 'DEFAULT');
        const docs = response.filter(doc => !doc.validYN);
        // 문서 타입별로 그룹화하고 각각 1부터 번호 매기기
        const docsWithNumbers = docs.reduce((acc, doc) => {
          const typeGroup = acc.filter(d => d.docType === doc.docType);
          return [...acc, {
            ...doc,
            id: `${doc.docType}-${typeGroup.length + 1}`,
            no: typeGroup.length + 1
          }];
        }, []);
        
        setDocuments(docsWithNumbers);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
      setLoading(false);
    };

    if (expiredDocuments?.total > 0) {
      fetchDocuments();
    }

    // 문서 첨부팝업 초기화
    setSelectedGClientId(sessionUser.id);
    setSelectedGClient(sessionUser.gclient);
    setSelectedRowGeneral(null);
    setSelectedRowEtc(null);

  }, [sessionUser, expiredDocuments]);

  
  // 문서 미리보기
  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    // window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    // Drawer가 닫혀있을 때만 열기
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  // 일반서류 새로고침
  const refreshG04GeneralDocus = () => {
    console.log("refreshG04GeneralDocus");
  }

  // 문서첨부
  const handleAddDocument = (document) => {
    console.log(document);
    setOpenGeneral(true);
  };


  const columns = [
    { field: 'no', headerName: 'No', width: 70, align: 'center', headerAlign: 'center' },
    { field: 'docName', headerName: '문서명', flex: 1 },
    { 
      field: 'endDate', 
      headerName: '만료일', 
      width: 110,
      valueFormatter: (params) => dateFormat(params.value, 'yyyy-MM-dd')
    },
    {
      field: 'documentPath',
      headerName: '서류',
      width: 100,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        // console.log(params)
        const { validYN, valid, documentPath } = params.row;
        if (params.value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(documentPath)}
                sx={{ color: (validYN || valid) ? "#2EA2DE" : "#DB4E2E" }}
              >
                <Visibility />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'actions',
      headerName: '기능',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          onClick={() => handleAddDocument(params.row)}
          size="small"
          variant="outlined"
          sx={{ fontSize: '0.75rem', py: 0 }}
        >
          문서첨부
        </Button>
      ),
    },
  ];
  

  const DocumentTypeGrid = ({ title, count, docType }) => {
    if (!count) return null;
    
    // 기본 컬럼에서 actions 제외
    const baseColumns = columns.filter(col => col.field !== 'actions');
    
    // 성적서일 때만 사용할 컬럼 정의
    const testColumns = docType === "TEST" ? [
      ...baseColumns,
      {
        field: 'gtestComparisonDocumentPath',
        headerName: '시험성과대비표',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          if (params.value) {
            const { validYN, valid, gtestComparisonDocumentPath } = params.row;
            return (
              <StyledTooltip
                title={<Stack direction="row" display="flex" alignItems="center">
                  <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                    {`미리보기`}
                  </Box>
                  <Typography variant="subtitle2">{gtestComparisonDocumentPath?.split("/")[gtestComparisonDocumentPath?.split("/")?.length-1]}</Typography>
                </Stack>}
                placement="right"
                sx={tooltipTop}
              >
                <IconButton
                  color="primary"
                  aria-label="file"
                  onClick={() => handleClickViewEachDoc(gtestComparisonDocumentPath)}
                  sx={{ color: (validYN || valid) ? "#2EA2DE" : "#DB4E2E" }}
                >
                  <Visibility />
                </IconButton>
              </StyledTooltip>
            );
          }
        }
      },
      // actions 컬럼을 마지막에 추가
      {
        field: 'actions',
        headerName: '기능',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <Button
            onClick={() => handleAddDocument(params.row)}
            size="small"
            variant="outlined"
            sx={{ fontSize: '0.75rem', py: 0 }}
          >
            문서첨부
          </Button>
        ),
      }
    ] : columns;

    return (
      <Grid item xs={12} md={6}>
      <Paper sx={{ p: 1, height: '100%' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
              <Grid container>
                <Grid item xs={12} display="flex" alignItems="center">
                  {title} ({count}건)
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ 
                cursor: 'pointer', 
                fontSize: '0.85em',
                height: 290,
                '& .MuiDataGrid-virtualScroller': {
                  overflow: 'auto'
                }
              }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={loading}
              rows={documents.filter(doc => doc.docType === docType)}
              columns={testColumns}
              hideFooter={true}
              disableColumnMenu
              disableMultipleRowSelection={true}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    );
  };

  return (
    <>
    <Grid sx={{ p: 0 }}>
      <Grid container spacing={2}>
        {(expiredDocuments.ltaxCnt > 0 || expiredDocuments.ntaxCnt > 0) && (
          <DocumentTypeGrid 
            title="일반서류" 
            count={expiredDocuments.ltaxCnt + expiredDocuments.ntaxCnt} 
            docType="GEN" 
          />
        )}
        {expiredDocuments.certCnt > 0 && (
          <DocumentTypeGrid 
            title="인증서" 
            count={expiredDocuments.certCnt} 
            docType="CERT" 
          />
        )}
        {expiredDocuments.testCnt > 0 && (
          <DocumentTypeGrid 
            title="성적서" 
            count={expiredDocuments.testCnt} 
            docType="TEST" 
          />
        )}
      </Grid>
    </Grid>
      {/* 일반서류 */}
      
      {/* 기타 */}

      <DocumentViewerDrawer 
          open={documentViewerDrawerOpen}
          onClose={() => setDocumentViewerDrawerOpen(false)}
          url={currentUrl}
        />
    </>
  );
};


export default ExpiredDocuments;