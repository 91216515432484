import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm, /*useFieldArray*/ } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
// import {
//   Add,
// } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuidv4 } from 'uuid';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff'; // TODO : 추후 Json diff와도 비교 검토 필요
import {
  objectEmptyCheck,
  hideWatermark,
} from "../../utils";
import usePrevious from "../hook/usePrevious";
import {
  FormInputDate,
  FormInputSwitch,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import { dateFormat } from "../../utils";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { NavigationGuard } from "../Navigation";
import * as constructionWorkTypeActions from "../../store/constructionWorkType";
import * as gprojectActions from "../../store/gproject";
import * as gclientActions from "../../store/gclient";
import * as gglassActions from "../../store/gglass";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gdomesticAuthActions from "../../store/gdomesticAuth";
import * as gglassPerformanceActions from "../../store/gglassPerformance";
import * as gperfDataActions from "../../store/gperfData";
import GProjectG04Step from "./GProjectG04Step";

const theme = createTheme();

const today = new Date();
let endDate = new Date();
endDate.setFullYear(today.getFullYear() + 2);

let selectSiteInput = null;
let selectConstructionCompanyNameInput = null;
let selectConstructionWorkTypeInput = null;

const GProjectG04Dialog = ({
  crudMode,
  setCrudMode,
  modify,
  setModify,
  open,
  setOpen,
  selectedRow,
  // gtypeDetailsWithGComponent,
  gtypes,
  refresh,
  setSearchValue,
}) => {
  const dependentGcomponentMap = new Map();

  const [errors, setErrors] = useState([]);
  const [checkedDoneYN, setCheckedDoneYN] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [basicExpanded, setBasicExpanded] = useState(true);
  const [mode, setMode] = useState('all');
  const [saveMode, setSaveMode] = useState('');
  const [gclientDetails, setGclientDetails] = useState([]); // TODO : 추후 react hook form의 useFieldArray 방식을 쓸 수 있는지 검토
  // const [loadingSave, setLoadingSave] = useState(false);
  // const [loadingSaveNClose, setLoadingSaveNClose] = useState(false);
  const [editing, setEditing] = useState(false); // 데이터 수정중 일 경우 페이지 이동시 안내
  const [initData, setInitData] = useState({}); // 등록 다이얼로그 상태일 때 초기데이터
  const [drawerState, setDrawerState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    // right: false,
  });
  const [selectedGGlass, setSelectedGGlass] = useState(undefined);
  const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [selectedSubMaterialProcessItems, setSelectedSubMaterialProcessItems] = useState([]);
  const [selectedSubMaterialBuildItems, setSelectedSubMaterialBuildItems] = useState([]);
  const [selectedProcessGClients, setSelectedProcessGClients] = useState([]);
  const prevDrawerState = usePrevious(drawerState);
  const [show, setShow] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [gclientG04GeneralDocuMaps, setGclientG04GeneralDocuMaps] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [selectedGlasses, setSelectedGlasses] = useState([]);
  // const [stepCompleted, setStepCompleted] = useState(false);
  const [openBackdrop, setOpenBackdrop]= useState(false);
  const [modifyGlass, setModifyGlass] = useState(false);

  // 미리 선언해야 사용할 수 있으므로 위치 변경
  const g04docuGCertificationsSubMaterialProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsSubMaterialProcess);
  const g04docuGCertificationsSubMaterialBuild = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsSubMaterialBuild);
  const g04docuGCertificationsProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsProcess);

  // redux form의 useForm을 통해 setValue를 호출하여 바로 form에 적용하려면 defaultValues가 설정되어 있어야 하는데, 여기서는 form이 동적으로 변하므로 미리 defaultValues를 선언해놓을 수 없다.
  // 따라서 아래와 같이 동적으로 변하는 부분을 미리 로딩해서 defaultValuesForSubMaterials에 설정하고 static한 부분과 결합하여 defalutValues를 구성한다. (GGlassCompenet 참조)
  const defaultValuesForSubMaterials = {};
  g04docuGCertificationsSubMaterialProcess.forEach((material, index) => {
    // defaultValuesForSubMaterials[material.id] = "";
    // defaultValuesForSubMaterials[`${material.id}_usable`] = true;\
    // console.log(material)
    // if (material.gcomponentItems) {
    //   material.gcomponentItems.forEach(item => {
    //     defaultValuesForSubMaterials[`${item.id}_0`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_1`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_2`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_3`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_4`] = "";

    //   })
    // }
    // defaultValuesForSubMaterials[`${material.id}_0`] = "";
    // defaultValuesForSubMaterials[`${material.id}_1`] = "";
    // defaultValuesForSubMaterials[`${material.id}_2`] = "";
    // defaultValuesForSubMaterials[`${material.id}_3`] = "";
    // defaultValuesForSubMaterials[`${material.id}_4`] = "";
    // defaultValuesForSubMaterials[`${material.id}_5`] = "";
  });

  g04docuGCertificationsSubMaterialBuild.forEach((material, index) => {
    // defaultValuesForSubMaterials[material.id] = "";
  });

  // 아래 form components의 name과 연계
  const defaultValues = Object.assign({
    id: "",
    site: "",
    siteAddress: "",
    constructionCompanyName: "",
    startDate: today.getDateWithStartHours(),
    endDate: endDate.getDateWithEndHours(),
    completionDate: endDate.getDateWithEndHours(),
    comments: "",
    doneYN: false,
    constructionWorkTypeId: "",

    no: "",
    gglassId: "",

    gclientId: "",
    gclientName: "",
    gclientsProcess: [],
    ownerId: "",
  }, defaultValuesForSubMaterials);

  // console.log(defaultValues)

  const handleDialogClose = () => {
    // TODO : 수정한 것이 있다면 닫기 전 confirm 창 띄울 것
    setOpen(false);
    setMode('all');

    setCrudMode('');

    // if (saveMode === "save") { // 저장(저장 후 닫기 아님) 후 닫기 시 목록 refresh 필요
    //   const { pathname } = location;
    //   if (pathname === "/projects") {
    //     refresh(false);
    //   } else if (pathname === "/g04docuGenerateFile" ||
    //     pathname === "/g04docuProjects" ||
    //     pathname === "/gsupplyConfirmRequest" ||
    //     pathname === "/gsupplyConfirmRequestList" ||
    //     pathname === "/gsupplyConfirmSupply" ||
    //     pathname === "/gsupplyConfirmSupplyList"
    //   ) {
    //     refresh(true);
    //   }
    // }

    // if (loadingSave) {
    //   setLoadingSave(false)
    // }

    // if (loadingSaveNClose) {
    //   setLoadingSaveNClose(false)
    // }

    initDialog();
  };

  const handleChangeDoneYN = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요 => FormInputDropdown1과 FormInputDate1은 set~함수를 만들고 외부에서 호출하도록 구현(검토 필요)
    setValue("doneYN", !checkedDoneYN)
    setCheckedDoneYN(!checkedDoneYN);
    checkEdit({ newRight: null, newGclientDetails: null });
  }

  const handleDialogMinMax = () => {
    setFullScreen(!fullScreen);

    // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
    setLoaded(false);
    setShowGrid(false);

    setTimeout(
      async () => {
        await hideWatermark();
        setTimeout(() => setShowGrid(true), 300);
        setTimeout(() => setLoaded(true), 500);
      }, 300
    )
  }
  
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // TODO : 현재 react hook form의 경우 배열에서 해당 인덱스의 값 삭제시 컴포넌트의 value 값 문제로 사용하고 있지 않음
  // const { fields, append, replace, remove } = useFieldArray({ control: control, name: "gclientIds" });
  // const dialogRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();

  const sessionUser = useSelector(state => state.session.sessionUser);
  const constructionWorkTypes = useSelector(state => state.constructionWorkType.constructionWorkTypes);
  const gtypeDetailsWithGComponent = useSelector((state) => state.gtypeDetail.gtypeDetailsWithGComponent);
  const gglasses = useSelector((state) => state.gglass.gglasses);
  // defaultValues에서 사용하기 위해 위로 위치 이동
  // const g04docuGCertificationsSubMaterialProcess = useSelector((state) => state.g04docu.g04docuGCertificationsSubMaterialProcess);
  // const g04docuGCertificationsSubMaterialBuild = useSelector((state) => state.g04docu.g04docuGCertificationsSubMaterialBuild);
  
  // 데이터 관리
  
  // no dispatch
  const addGProjectWithResult = ({
    id,
    site,
    siteAddress,
    startDate,
    endDate,
    completionDate,
    gclientDetails,
    constructionCompanyName,
    comments,
    doneYN,
    constructionWorkTypeId,
    selectedGlasses,
    selectedSubMaterialProcessItems,
    selectedSubMaterialBuildItems,
    docu04,
    gdomesticAuths,
  }) => {
    return gprojectActions.createWithResult({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      completionDate,
      gclientDetails,
      constructionCompanyName,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
      gdomesticAuths,
    });
  }

  const modifyGProjectWithResult = ({
    id,
    site,
    siteAddress,
    startDate,
    endDate,
    completionDate,
    gclientDetails,
    constructionCompanyName,
    comments,
    doneYN,
    constructionWorkTypeId,
    selectedGlasses,
    selectedSubMaterialProcessItems,
    selectedSubMaterialBuildItems,
    docu04,
    gdomesticAuths,
    ownerId,
  }) => {
    return gprojectActions.modifyWithResult({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      completionDate,
      gclientDetails,
      constructionCompanyName,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
      gdomesticAuths,
      ownerId,
    });
  }

  const selectAllGClientG04GeneralDocuByGClientDirect = (gclientId) => gclientG04GeneralDocuMapActions.selectAllByGClientDirect(gclientId)
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)

  // dispatch
  const selectAllGClients = () => dispatch(gclientActions.selectAll())
  const selectGlasses = () => dispatch(gglassActions.selectAllByQuery())
  const initGTypeDetailsWithGComponent = () => dispatch(gtypeDetailActions.initWithComponent())
  const selectAllConstructionWorks = () => dispatch(constructionWorkTypeActions.selectAll())
  const selectGDomesticsDirect = (conditions) => gdomesticAuthActions.selectDirect(conditions)
  const selectAllGGlassPerformance = (code) => dispatch(gglassPerformanceActions.selectAll())
  const selectGTrans = () => dispatch(gperfDataActions.selectGTrans())
  
  const validateForm = ({
    id,
    site,
    siteAddress,
    gclientDetails,
    constructionCompanyName,
    startDate,
    endDate,
    completionDate,
    comments,
    doneYN,
    constructionWorkTypeId,
    // selectedGlasses: rows,
    selectedGlasses,
    selectedSubMaterialProcessItems,
    selectedSubMaterialBuildItems,
    docu04,
    mode,
  }) => {
    if (!site) {
      selectSiteInput.focus();

      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            {/* d32f2f 1976d2*/}
            <span style={{ color: "#1976d2" }}>{"현장명"}</span>{`을 입력해주세요.`}
          </div>
        ),
        open: true,
      });

      return false;
    }

    console.log(constructionCompanyName)
    if (!constructionCompanyName) {
      selectConstructionCompanyNameInput.focus();

      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            {/* d32f2f 1976d2*/}
            <span style={{ color: "#1976d2" }}>{"건설사명(제출처)"}</span>{`을 입력해주세요.`}
          </div>
        ),
        open: true,
      });

      return false;
    }

    if (!constructionWorkTypeId) { // constructionWorkTypeId 없을 시 서버오류(DB fk 오류)발생하므로 
      selectConstructionWorkTypeInput.focus();

      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            {/* d32f2f 1976d2*/}
            <span style={{ color: "#1976d2" }}>{"공종명"}</span>{`을 선택해주세요.`}
          </div>
        ),
        open: true,
      });

      return false;
    }

    // TODO : 그외 유효성 체크는 여기서...
    const dates = [
      { value: startDate, name: "시작일" },
      { value: endDate, name: "마감일" },
      { value: completionDate, name: "준공일" }
    ];

    for (const date of dates) {
      if (date.value && !(date.value instanceof Date && !isNaN(date.value))) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: (<div>
            <span style={{ color: "#1976d2" }}>{date.name}</span>
            {`이 올바른 날짜 형식이 아닙니다.`}
          </div>),
          open: true,
        });
        return false;
      }
    }

    return true;
  }

  const onSubmit = async ({ id, site, siteAddress, constructionCompanyName, startDate, endDate, completionDate, comments, doneYN, constructionWorkTypeId, ownerId }, mode) => {
    // TODO : validation 처리 필요. 특히 날짜 등...
    setErrors([]);

    console.log(selectedGlasses);
    // return;
    // if (mode === 'save') {
    //   setLoadingSave(true);
    // } else if (mode === 'saveAndClose') {
    //   setLoadingSaveNClose(true);
    // }

    // const aaa = g04docuGCertificationsProcess.map(item => {
    //   // console.log(getValues(item.id));
    //   return {
    //     id: item.id,
    //     name: item.name,
    //     value: getValues(item.id),
    //   }
    // });
    // console.log(aaa)
    
    // return;
    console.log(selectedGlasses)
    
    // 유효성 체크
    const isValid = validateForm({
      // id,
      site,
      // siteAddress,
      // gclientDetails,
      constructionCompanyName,
      startDate,
      endDate,
      completionDate,
      // comments,
      // doneYN,
      constructionWorkTypeId,
      // selectedGlasses: rows,
      // selectedGlasses: newSelectedGlasses,
      // selectedSubMaterialProcessItems,
      // selectedSubMaterialBuildItems,
      // docu04: true,
      // mode,
    });
    
    if (!isValid) return;

    let noSelectedManufacturer = false;
    let noSelectedProcessGClients = false;
    let noGlassNo = false;

    // 국내가공유리제품 사전 인증 조건 생성 및 제조사/가공업체 미선택 유효성 체크 => TODO : 추후 validateForm(유효성체크 함수)와 통합
    const conditions = [];
    const newSelectedGlasses = selectedGlasses.map(glass => {
      const types = [];
      const { gglassId, gtypeCode, no, selectedGcomponentItems, selectedProcessGClients } = glass;
      
      if (!no) {
        noGlassNo = true;
      }

      selectedGcomponentItems.forEach((gtypeDetails, i) => {
        gtypeDetails.forEach((item, j) => {
          const items = [];
          // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
          if (j === 0) {
            return; // continue 기능
          }
          
          if (item.code === 'GLASS PRODUCT NAME') { // 유리 제품일 경우
            if (!item.value.selectedGClientId) { // 원판제조사가 선택되지 않은 경우 체크
              noSelectedManufacturer = true;
            }

            let madeByText = "국내산"; // default
            const { value } = item;
            if (value && value?.madeBy) {
              madeByText = value.madeBy;
            }
            types.push(madeByText);
          } else if (item.code === 'FILM THICKNESS') { // 유리 두께일 경우
            types.push('접합'); // 국내산 인증정보 조회하기 위함
          }
        });
      });
      
      // 가공업체가 선택되지 않았는지 체크
      if (gtypeCode !== 'SINGLE_PANE') { // 유리템플릿이 '단판유리'일 때는 가공업체 선택 유효성 체크를 하지 않는다.
        console.log({ glass })
        if (!selectedProcessGClients || selectedProcessGClients.length === 0) {
          noSelectedProcessGClients = true;
        } else {
          const noSelected = selectedProcessGClients.filter(process => !process.selectedGClients || process.selectedGClients.length === 0);
          if (noSelected.length > 0) {
            noSelectedProcessGClients = true;
          }
        }
      }

      const stringTypes = types.join(","); // 국내가공유리제품 사전 인증 조건
      conditions.push({ gglassId, types: stringTypes })

      return {
        ...glass,
        types: stringTypes,
      }
    });

    if (noGlassNo) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (<div>
          <span style={{ color: "#1976d2" }}>{"품번"}</span>{`을 입력해주세요.`}
        </div>),
        open: true,
      });

      return;
    }

    let manufacturer = "";
    if (noSelectedManufacturer) {
      manufacturer = "원판제조사";
    }

    if (noSelectedProcessGClients) {
      manufacturer = manufacturer ?  manufacturer + ", 가공업체" : "가공업체";
    }

    if (noSelectedManufacturer || noSelectedProcessGClients) {
      let message;
      if (modifyGlass) {
        message = (<>
          <span style={{ color: "#1976d2" }}>{"유리사양이 변경"}</span>{"되어 "}
          <span style={{ color: "#1976d2" }}>{"가공업체 정보"}</span>{"를 "}<span style={{ color: "#1976d2" }}>{"다시 설정"}</span>{"해야 합니다."}<br /><br />
        </>);
      }

      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (<div>
          {message}
          <span style={{ color: "#1976d2" }}>{manufacturer}</span>{`를 선택해주세요.`}
        </div>),
        open: true,
      });

      return;
    }

    setOpenBackdrop(true);
    setProgress(".");

    // 국내산 인증 정보 조회 맵 생성
    const gdomesticAuths = await selectGDomesticsDirect(conditions);
    
    // console.log(rows);
    console.log({
      id,
      site,
      siteAddress,
      gclientDetails,
      constructionCompanyName,
      startDate,
      endDate,
      completionDate,
      comments,
      doneYN,
      constructionWorkTypeId,
      // selectedGlasses: rows,
      selectedGlasses: newSelectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04: true,
      mode,
      gdomesticAuths,
      conditions,
    })

    // console.log(typeof endDate);
    // console.log(startDate.toUTCString());
    // console.log(endDate.toUTCString());
    // console.log(modify)
    // return;

    showProgress();
    
    let func;
    if (modify) {
      func = modifyGProjectWithResult;
    } else {
      func = addGProjectWithResult;
    }
    
    func({
      id,
      site,
      siteAddress,
      startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
      endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
      completionDate: completionDate ? dateFormat(completionDate.getDateWithEndHours()) : null,
      gclientDetails,
      constructionCompanyName,
      comments,
      doneYN,
      constructionWorkTypeId,
      // selectedGlasses: rows,
      selectedGlasses: newSelectedGlasses.map(glass => { // 서버에 저장할 필요가 없는 속성은 제거한다.
        delete glass.gtypeCode;
        delete glass.gtypeDetailsWithGComponent;

        return glass;
      }),
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04: true,
      gdomesticAuths,
      ownerId: modify && sessionUser.type === 'ADMIN' ? ownerId : null,
    }).then (async res => {
      if (mode === 'saveAndClose') {
        setTimeout(() => {
          // setLoadingSaveNClose(false);
          handleDialogClose();
          setOpenBackdrop(false);
        }, 1000);
      } else { // mode === 'save'
        setTimeout(() => {
          // setLoadingSave(false);
          setOpenBackdrop(false);
        }, 1000);
        
        setModify(true);
        setValue("id", res.gproject.id);
        // TODO : 초기화(페이지 나갈때 변경여부 묻지 않도록) 필요하고, 닫기시 목록의 refresh 필요함
      }
      
      if (refresh && setSearchValue) {
        setSearchValue("searchName", "");
        setSearchValue("searchSite", "");
        refresh();
      }
    }).catch (async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });

    // setSaveMode(mode);

    setModifyGlass(false);
  }

  useEffect(() => {
    const initializeDialog = async () => {
      if (open) {
        await hideWatermark();
        // await selectAllGClients(); // GProjectManagement에서 조회하여 사용
        await selectAllGGlassPerformance();
      }
    };
    initializeDialog();
  }, [open, dispatch]);

  useEffect(
    async () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === "startDate") {
            setValue("startDate", (new Date(selectedRow["startDate"])).getDateWithStartHours() || value);
          } else if (item === "endDate") {
            setValue("endDate", (new Date(selectedRow["endDate"])).getDateWithEndHours() || value);
          } else if (item === "completionDate") {
            setValue("completionDate", (new Date(selectedRow["completionDate"])).getDateWithEndHours() || value);

          } else {
            setValue(item, selectedRow[item] || value);
          }
        }
    
        // 그외 초기화할 것들은 여기서 초기화
        setCheckedDoneYN(selectedRow["doneYN"] || defaultValues.doneYN);

        if (crudMode === 'U') {
          setBasicExpanded(false);
        }
  
        if (!selectedRow.owner) {
          setValue("gclientId", sessionUser.id);
          setValue("gclientName", sessionUser.name);
        } else {
          setValue("gclientId", selectedRow.owner.id);
          setValue("gclientName", selectedRow.owner.name);
        }
      }
    }, [selectedRow]
  );

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setCheckedDoneYN(false);
    setBasicExpanded(true);

    initGTypeDetailsWithGComponent();
    setSelectedGGlass(undefined);
    
    setRows([]);
    setSelectedSubMaterialProcessItems(g04docuGCertificationsSubMaterialProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
    setSelectedSubMaterialBuildItems(g04docuGCertificationsSubMaterialBuild.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));

    setLoaded(false);
    setShowGrid(false);

    setSelectedGlasses([]);
  }

  useEffect(
    () => {
      console.log(uuidv4())
      if (crudMode === 'C') {
        setValue("id", uuidv4());
        setValue("gclientId", sessionUser.id);
        setValue("gclientName", sessionUser.name);
      }
    }, [crudMode]
  );

  useEffect(
    async () => {
      if (open) {
        const gclientId = sessionUser.id;
        const maps = await selectAllGClientG04GeneralDocuByGClientDirect(gclientId);
        setGclientG04GeneralDocuMaps(maps);

        selectGlasses();
        selectAllConstructionWorks();

        setLoaded(false);
        setShowGrid(false);
        
        setTimeout(
          async () => {
            await hideWatermark();
            setTimeout(() => setShowGrid(true), 300);
            setTimeout(() => setLoaded(true), 500);
          }, 300
        )
        selectGTrans();
      }
    }, [open]
  )

  useEffect(
    () => {
      // 현재 시스템은 유리공사를 기본으로 설정
      const selConstructionWorkTypes = constructionWorkTypes.filter(type => type.code === 'GLASS');
      if (selConstructionWorkTypes.length === 1) {
        setValue("constructionWorkTypeId", selConstructionWorkTypes[0].id);
      }
    }, [constructionWorkTypes]
  )

  

  // TODO : 기존 데이터와 수정된 데이터를 전체 비교. 전체 비교하여 이 기능을 사용할지 말지는 선택하게 하는 방법 검토
  const checkEdit = (param) => {
    const { newRight, newGclientDetails } = param ? param : { newRight: null, newGclientDetails: null };
    console.log(">>>>>>>>>>>>>>>>>>>>>> previous data")
    const prevData = selectedRow ? selectedRow : initData;
    console.log(prevData)
    console.log(">>>>>>>>>>>>>>>>>>>>>> present data")
    const newData = {
      id: getValues('id'),
      site: getValues('site'),
      siteAddress: getValues('siteAddress'),
      startDate: getValues('startDate'),
      endDate: getValues('endDate'),
      completionDate: getValues('completionDate'),
      comments: getValues('comments'), 
      doneYN: getValues('doneYN'),
      gclientDetails: newGclientDetails ? newGclientDetails : gclientDetails,
      constructionCompanyName: getValues('constructionCompanyName'),
      // selectedGlasses: newRight ? newRight : right,
      order: prevData.order,
      orderDetail: prevData.orderDetail,
      createdAt: prevData.createdAt,
      updatedAt: prevData.updatedAt,
    };
    console.log(newData)
    // const difference = diff(prevData, newData);
    // console.log(">>>>>>>>>>>>>>>>>>>>>> difference")
    // console.log(difference)
    // TODO : 객체 비교시 비교대상이 아닌 속성은 검토해서 최종 변경 여부 판단
    const detailedDifference = detailedDiff(prevData, newData) // returns an object with the added, deleted and update
    console.log(">>>>>>>>>>>>>>>>>>>>>> detailedDifference")
    console.log(detailedDifference)

    // TODO : 객체의 모든 속성(하위 객체 포함)의 값까지 100% 점검하지는 않으므로 혹시 그렇게 하려면 고도화 필요
    const { added, deleted, updated } = detailedDifference;
    if (objectEmptyCheck(added) && objectEmptyCheck(deleted) && objectEmptyCheck(updated)) {
      setEditing(false);
    } else {
      setEditing(true);
      // let editedSelectedGlasses = true;
      // let editedgclientDetails = true;
      // let editedEtc = true;

      // let editedAdd = !objectEmptyCheck(added);
      // let editedDelete = !objectEmptyCheck(deleted);
      // // gclientDetails에는 다이얼로그가 나타날때 그 안의 index값이 변하므로 실제로 내용을 수정하지 않아도 다르게 나타남
      // // 따라서 index만 변했다면 수정이 되지 않았다고 판단
      // const { gclientDetails } = updated;
      // if (gclientDetails) {
      //   const updatedGclientDetails = [];
      //   for (const [key, value] of Object.entries(gclientDetails)) {
      //     const gclientDetail = value;
      //     for (const [key, value] of Object.entries(gclientDetail)) {
      //       const gclients = value;
      //       for (const [key, value] of Object.entries(gclients)) {
      //         const gclient = value;
      //         const keys = Object.keys(gclient);
      //         if (!(keys.length ===1 && keys[0] === "index")) {
      //           // console.log(gclient)
      //           updatedGclientDetails.push("updated");
      //         }
      //       }
      //     }
      //   }
        
      //   if (updatedGclientDetails.length <= 0) {
      //     editedgclientDetails = false;
      //   }

      //   // console.log(Object.keys(updated))
      //   // console.log(Object.entries(defaultValues).map(item => item[0]));
      //   const a = Object.keys(updated);
      //   const b = Object.entries(defaultValues).map(item => item[0]);

      //   const result = a.filter((value) => b.map(item => item).indexOf(value) !== -1);
      //   if (result.length <= 0) {
      //     editedEtc = false;
      //   }

      // }

      // console.log(`editedSelectedGlasses: ${editedSelectedGlasses}, editedgclientDetails: ${editedgclientDetails}, editedEtc: ${editedEtc}`);
      // setEditing(editedAdd || editedDelete || /*editedSelectedGlasses || */editedgclientDetails || editedEtc);
    }
  }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    return true;
  }
  
  const setSelectSiteInputRef = element => {
    selectSiteInput = element;
  };

  const setSelectConstructionCompanyNameInput = element => {
    selectConstructionCompanyNameInput = element;
  };

  const setSelectConstructionWorkTypeInputRef = element => {
    selectConstructionWorkTypeInput = element;
  };

  const startInterval = (ms, callback) => {
    callback();
    return setInterval(callback, ms);
  };

  
  let loadingText = ".";

  const printLoading = () => {
    
    if (loadingText === "." || loadingText.length > 1) {
      loadingText = loadingText + ".";
    } else if (loadingText.length === 5) {
      loadingText = ".";
    }
    return loadingText;
  }

  const [progress, setProgress] = useState(".");
  let timer = React.useRef(undefined);

  // useEffect(() => {
  //   if (!timer.current) {
  //     timer.current = setInterval(() => {
  //       setProgress((prev) => {
  //         if (prev.length < 7) {
  //           return prev = prev + ".";
  //         } else if (prev.length === 7) {
  //           return prev = ".";
  //         }
          
  //         return prev;
  //       });
  //     }, 1000);
  //   }
  // }, []);
  const showProgress = () => {
    timer.current = setInterval(() => {
      setProgress((prev) => {
        if (prev.length < 7) {
          return prev = prev + ".";
        } else if (prev.length === 7) {
          return prev = ".";
        }
        
        return prev;
      });
    }, 1500);
  }

  useEffect(
    () => {
      if (!openBackdrop) {
        if (timer.current) {
          clearInterval(timer.current);
        }
      }
    }, [openBackdrop]
  )

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => /*theme.zIndex.drawer + 1*/Math.max.apply(Math, Object.values(theme.zIndex)) + 1, }} // Backdrop over Dialog
        open={openBackdrop}
      >
        <Stack direction="column" alignItems="center">
          <CircularProgress color="inherit" sx={{ mb: 2 }} />
          {"정보를 저장하고 있습니다."}<br />
          {"잠시만 기다려주세요 "}{progress}
        </Stack>
      </Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={!fullScreen && PaperComponent} // fullScreen일때는 드래그 허용하지 않음
        aria-labelledby="draggable-dialog-title"
        maxWidth="xl"
        scroll="body"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          // onMinMax={handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: fullScreen ? '' : 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          <div id="dialog-position" /*ref={dialogRef}*/>
          {/* <div
            id="dialog-position"
            ref={el => {
              if (el) {
                dialogTitleRect = el.getBoundingClientRect();
                // setDialogTitleBoundingClientRect()
              }
            }}
          > */}
            { crudMode === 'C' && "프로젝트 생성"}
            { crudMode === 'R' && "프로젝트 상세"}
            { crudMode === 'U' && "프로젝트 수정"}
          </div>
        </DialogTitleClose>
        <DialogContent>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <NavigationGuard when={editing} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Accordion expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}>
                <AccordionSummary
                  aria-controls="basic-content"
                  id="basic-header"
                  sx={{
                    minHeight: '38px', // 헤더의 최소 높이 설정
                    '& .MuiAccordionSummary-content': {
                      margin: '4px 0', // 내용의 상하 마진 조정
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Typography variant="h6" component="div" sx={{ ml: 1}}>{"기본정보"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'none' }}>
                      <FormInputText
                        name={"id"}
                        control={control}
                        label={"아이디"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        <FormInputText
                          name={"site"}
                          control={control}
                          label={"현장명"}
                          onEdit={checkEdit}
                          inputProps={{
                            ref: setSelectSiteInputRef,
                          }}
                          sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                        />
                        <FormInputText
                          name={"siteAddress"}
                          control={control}
                          label={"현장주소"}
                          onEdit={checkEdit}
                          sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                        />
                      </Stack>
                    </Grid>
                    {/* gclient 정보는 project 정보가 이미 저장되어 있다면 owner 정보, 그렇지 않다면  로그인 정보의 gclient 정보를 활용 */}
                    <Grid item xs={12} sx={{ display: 'none' }}>
                      <FormInputText
                        name={"gclientId"}
                        control={control}
                        label={"업체아이디"}
                        // onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        <FormInputText
                          name={"gclientName"}
                          control={control}
                          label={"업체명"}
                          InputProps={{ readOnly: true }}
                          // onEdit={checkEdit}
                          sx={{ width: { /*xs: 330, */sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                        />
                        <FormInputText
                          name={"constructionCompanyName"}
                          control={control}
                          label={"건설사명(제출처)"}
                          onEdit={checkEdit}
                          inputProps={{
                            ref: setSelectConstructionCompanyNameInput,
                          }}
                          sx={{ width: { /*xs: 330, */sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                        />
                        <FormInputText
                          select
                          name={"constructionWorkTypeId"}
                          control={control}
                          label={"공종명"}
                          options={constructionWorkTypes.map(constructionWorkType => {
                            return {
                              label: constructionWorkType.name,
                              value: constructionWorkType.id,
                            }
                          })}
                          onEdit={checkEdit}
                          inputProps={{
                            ref: setSelectConstructionWorkTypeInputRef,
                          }}
                          sx={{ width: { /*xs: 330, */sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                          disabled={true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        <FormInputDate
                          name="startDate"
                          control={control}
                          label={"시작"}
                          onChangePeriodValue={handleChangePeriod}
                          value={getValues("startDate")}
                          setValue={setValue}
                          onEdit={checkEdit}
                          inputHeight={38}
                          fontSize={16}
                          customWidth={250}
                          // iconSize={"small"}
                        />
                        <FormInputDate
                          name="endDate"
                          control={control}
                          label={"마감"}
                          onChangePeriodValue={handleChangePeriod}
                          value={getValues("endDate")}
                          setValue={setValue}
                          onEdit={checkEdit}
                          inputHeight={38}
                          fontSize={16}
                          customWidth={250}
                          // iconSize={"small"}
                        />
                        <FormInputDate
                          name="completionDate"
                          control={control}
                          label={"준공일"}
                          onChangePeriodValue={handleChangePeriod}
                          value={getValues("completionDate")}
                          setValue={setValue}
                          onEdit={checkEdit}
                          inputHeight={38}
                          fontSize={16}
                          customWidth={250}
                          // iconSize={"small"}
                        />
                        <div style={{ width: '150px', paddingLeft: '20px', backgroundColor: checkedDoneYN ? "#5dc061" : "#ED6C02", color: 'white', borderRadius: '5px' }}>
                          <FormInputSwitch
                            name={"doneYN"}
                            checked={checkedDoneYN}
                            onChange={handleChangeDoneYN}
                            control={control}
                            label={checkedDoneYN ? "마감" : "마감전"}
                            color="success"
                          />
                        </div>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"comments"}
                        control={control}
                        label={"설명"}
                        multiline
                        maxRows={5}
                        onEdit={checkEdit}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <GProjectG04Step
                selectedRow={selectedRow}
                selectedGlasses={selectedGlasses}
                setSelectedGlasses={setSelectedGlasses}
                setValue={setValue}
                getValues={getValues}
                control={control}
                selectedSubMaterialProcessItems={selectedSubMaterialProcessItems}
                setSelectedSubMaterialProcessItems={setSelectedSubMaterialProcessItems}
                selectedSubMaterialBuildItems={selectedSubMaterialBuildItems}
                setSelectedSubMaterialBuildItems={setSelectedSubMaterialBuildItems}
                modifyGlass={modifyGlass}
                setModifyGlass={setModifyGlass}
                // setStepCompleted={setStepCompleted}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="small"
            onClick={() => handleSubmit(onSubmit)('save')}
            // loading={loadingSave}
            // disabled={loadingSaveNClose}
          >
            {"저장"}
          </LoadingButton>
          <LoadingButton
            size="small"
            onClick={() => handleSubmit(onSubmit)('saveAndClose')}
            // loading={loadingSaveNClose}
            // disabled={loadingSave}
          >
            {"저장 후 닫기"}
          </LoadingButton>
          {/* <Button
            onClick={() => handleSubmit(onSubmit)('saveAndClose')}
            // disabled={!stepCompleted}
          >
            {"저장 후 닫기"}
          </Button> */}
          <Button onClick={() => {
            reset();
            // setPeriodValue(null, null); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
          }}>{"초기화"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GProjectG04Dialog;
