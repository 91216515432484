import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Badge,
  Chip,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import  {
  Add,
  Attachment,
  Clear,
  Search,
  Delete,
  Edit,
  FilePresent,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  PictureAsPdf,
  Image,
  Download,
  Downloading,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputAutoComplete,
  FormInputText
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  StringAvatar,
} from "../avatar";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  differenceInDays,
  hideWatermark,
} from "../../utils";
import * as gclientActions from "../../store/gclient";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as g04docuGTestActions from "../../store/g04docuGTest";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as G04docuGTestSubstituteActions from "../../store/g04docuGTestSubstitute";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import * as g04docuSearchActions from "../../store/g04docuSearch";
import * as gsendG04docuActions from "../../store/gsendG04docu";
import * as gsendBasketActions from "../../store/gsendBasket";
import GSendG04docuDialog from "./GSendG04docuDialog";

const theme = createTheme();

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 1,
    top: 13,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: "white",
    backgroundColor: "#9e9e9e",
  },
}));

const defaultValues = {
  // gclientId: "",
  // division: '',
  type: '',
  searchWord: "",
  name: "",
  classification: "",
};

// const ITEM_HEIGHT = 48;

const GSendG04docuDownloadPage = ({
  title,
  from,
}) => {
  const [searchParams] = useSearchParams();
  
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  
  const [checked, setChecked] = useState(false);
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  
  const [selectedRow, setSelectedRow] = useState([]);
  

  const [attachFiles, setAttachFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleFileDialogClose = () => {
    setOpenFile(false);
  }

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const selectedGSendG04docu = useSelector((state) => state.gsendG04docu.gsendG04docu);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  const select = (id) => dispatch(gsendG04docuActions.select(id))
  
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const downloadG04docuFileAll = (id, documentPaths) => g04docuFileActions.downloadAllDirect(id, documentPaths)

  useEffect(
    async () => {
      const id = searchParams.get('downloadKey');
      select(id);
    }, [dispatch]
  );

  useEffect(
    () => {
      console.log(selectedGSendG04docu)
      if (selectedGSendG04docu && selectedGSendG04docu.attachments && Array.isArray(selectedGSendG04docu.attachments)) {
        setAttachFiles(selectedGSendG04docu?.attachments?.map(file => ({ ...file, downloading: false })))
      } else {
        setAttachFiles([]);
      }
    }, [selectedGSendG04docu]
  )

  const handleClickDownloadAttachFile = async (e, documentPath, idx) => {
    e.stopPropagation();
    
    const newAttachFiles = attachFiles.map((file, i) => (i === idx ? { ...file, downloading: true } : file));

    setAttachFiles(newAttachFiles);
    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
    
    setTimeout(() => {
      setAttachFiles(newAttachFiles.map((file, i) => (i === idx ? { ...file, downloading: false } : file)));
    }, 1000);
  }

  const handleClickDownloadAll = async () => {
    setLoading(true);

    const id = searchParams.get('downloadKey');
    const res = await downloadG04docuFileAll(id, attachFiles.map(file => file.path))

    console.log(res)
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;

    // const fileName = `${id}.zip`;
    const fileName = "04docu_download.zip";
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    setLoading(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <Box sx={{ mt: 10 }}>
          <Typography variant="h6" sx={{ p: 2, borderRadius: 2, mb: 2, bgcolor: '#e0e0e0' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8} md={10}display="flex" alignItems="center">
                {"첨부파일 다운로드"}
              </Grid>
              <Grid item xs={12} sm={4} md={2} display="flex" justifyContent={"flex-end"} alignItems="center">
                <LoadingButton
                  variant={"contained"}
                  loading={loading}
                  onClick={handleClickDownloadAll}
                  fullWidth
                >
                  <Download />
                  {"전체받기"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Typography>
          <List>
          {
            attachFiles.map((file, idx) => {
              const { filename, path, name } = file;
              const pos = filename.lastIndexOf(".");
              let ext = "";
              if (pos > -1) {
                ext = filename.substring(pos+1);
              }

              // 전송바구니에서 보낸 경우 실제 파일명이 파일을 대표하는 이름이 아닐 경우가 있으므로 논리적 파일명이 있다면 붙여준다.
              // 여기서 논리적 파일명이란 일반서류의 예를 들면 사업자등록증 같은 이름이다. 실제 사업자등록증 등록시 파일명이 aaa.pdf라면 그대로 등록할 수 있기 때문이다.
              let logicalFileName = "";
              if (name && name.name) {
                logicalFileName = name.name;
              }

              return (
                <ListItem disablePadding>
                  {/* <Tooltip title={"다운로드"}> */}
                    <ListItemButton>
                      <ListItemIcon>
                        { ext.toLowerCase() === 'pdf' ? <PictureAsPdf /> : <Image /> }
                      </ListItemIcon>
                      {
                        logicalFileName ? (<>
                          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#555', p: 1, fontSize: '0.8em', mr: 1 }}>{logicalFileName}</Box>
                          <ListItemText primary={filename} />
                        </>) : (<ListItemText primary={filename} />)
                      }
                      <IconButton onClick={(e) => handleClickDownloadAttachFile(e, path, idx)}>
                        { file.downloading ? <Downloading /> : <Download /> }
                      </IconButton>
                    </ListItemButton>
                  {/* </Tooltip> */}
                </ListItem>
              )
            })
          }
          </List>
        </Box>
      </Container>
      <Dialog
        open={openFile}
        onClose={handleFileDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        // onConfirm={sendOrder}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleFileDialogClose}
          style={{ cursor: 'move' }}
        >
          {"첨부파일"}
        </DialogTitleClose>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List>
                             
                {/* <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PictureAsPdf />
                    </ListItemIcon>
                    <ListItemText primary="Drafts" />
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default GSendG04docuDownloadPage;
