import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Delete,
  OpenInNew,
  FilePresent,
  ErrorOutline,
  Send,
} from '@mui/icons-material';
import qs from 'query-string';
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as g04docuMaterialApprovalActions from "../../store/g04docuMaterialApproval";
import * as dialogActions from "../../store/components/dialog";
import * as confirmDialogActions from "../../store/components/confirmDialog";
import * as materialApprovalProgressActions from "../../store/materialApprovalProgress";

import G04docuMaterialApprovalDialog from "./G04docuMaterialApprovalDialog";
import G04docuMaterialApprovalEmailDialog from "./G04docuMaterialApprovalEmailDialog";
import G04docuMaterialApprovalDeleteConfirmDialog from "./G04docuMaterialApprovalDeleteConfirmDialog";
import { getGridHeight } from "../../constants/gridHeights";

const theme = createTheme();

// const ITEM_HEIGHT = 48;
// let listTimerId = null; // 보관함 타이머
// let makeTimerId = null; // 발급 타이머
const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', -23); // 0px 추가 여백

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04docuMaterialApprovalManagement = ({
  title,
  from,
}) => {
  // const apiRef = React.useRef();

  // const [anchorEl, setAnchorEl] = useState(null);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [params, setParams] = useState({});
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  const [columns, setColumns] = useState([]);
  // const [rowCount, setRowCount] = useState(100);
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id, row } = params;
    
    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "delete") {
      dispatch(confirmDialogActions.setOptions({ open: true, params, action: remove }));
      // setRemoveObject({ id, documentPath: params.row.documentPath });
      // setParams(params);
      // setConfirmOpen(true);
    } else if (type === "sendEmail") {
      handleClickSendEmail({ id, documentPath: row.documentPath, recipient: row.generateOptions?.phrases?.recipient, site: row.generateOptions?.phrases?.site });
    }

    setOpenBackdrop(false);
  }

  const location = useLocation();

  const getDocType = () => {
    const { pathname } = location;
    if (pathname === '/g04docuGenerateFile') {
      return 'MATERIAL_APPROVAL';
    } else if (pathname === '/g04docuMaterialApproval') {
      return 'MATERIAL_APPROVAL';
    } else if (pathname === '/gsupplyConfirmGenerateFile') {
      return 'GSUPPLY_CONFIRM';
    } else if (pathname === '/gsupplyConfirmGenerateFileList') {
      return 'GSUPPLY_CONFIRM';
    } 
  }

  const remove = async (removeObject) => {
    const { id, row } = removeObject;
    
    removeWithFile({ id, documentPath: row.documentPath, docType: getDocType() })
      .then(res => {
        const deleteOption = true;
        selectPaging(deleteOption);
        
        if (options?.open) {
          showMaterialApprovalProgress({ open: false });
        }
        
        if (from && from.params && from.params.closeDrawer) {
          from.params.closeDrawer();
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
        
        if (options?.open) {
          showMaterialApprovalProgress({ open: false });
        }
        
        if (from && from.params && from.params.closeDrawer) {
          from.params.closeDrawer();
        }
      });
  }

  const generateActions = (params) => {
    // console.log(params)
    // let arrActions = [
    //   <GridActionsCellItem
    //     icon={<OpenInNew />}
    //     label={"상세"}
    //     onClick={() => handleSelect({ type: 'detail', params })}
    //     showInMenu
    //   />,
    //   <GridActionsCellItem
    //     icon={<Delete />}
    //     label={"삭제"}
    //     onClick={() => handleSelect({ type: 'delete', params })}
    //     showInMenu
    //   />,
    //   <GridActionsCellItem
    //     icon={<Send />}
    //     label={"메일전송"}
    //     onClick={() => handleSelect({ type: 'sendEmail', params })}
    //     showInMenu
    //   />,
    // ];

    // if (params.row.results?.status === 'PROCESSING') {
    //   arrActions.pop();
    // }

    // return arrActions;
    return params.row.results?.status === 'PROCESSING' ? [] : [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Send />}
        label={"메일전송"}
        onClick={() => handleSelect({ type: 'sendEmail', params })}
        showInMenu
      />,
    ]
  }

  const getDocumentHeaderName = () => {
    const docType = getDocType();
    return docType === 'MATERIAL_APPROVAL' ? '자재승인서' : '납품확인서';
  }

  const COLUMN_CONFIGS = [
    {
      field: 'gclient',
      headerName: '거래선명',
      width: 200,
      valueGetter: (params) => {
        return params.value.name
      },
    },
    {
      field: 'gproject',
      headerName: '사이트',
      width: 300,
      valueGetter: (params) => {
        return params.value.site
      }
    },
    {
      field: 'results',
      headerName: '상태',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        let value = "생성중";
        if (params.value?.status === 'SUCCESS') {
          value = "완료";
        } else if (params.value?.status === 'FAIL') {
          value = "생성중 오류발생";
        }
        return value;
      },
    },
    {
      field: 'documentPath',
      headerName: getDocumentHeaderName(),
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.value) {
          if (params.row?.results?.status === 'SUCCESS') {
            const { documentPath } = params.row;
            return (
              <StyledTooltip
                title={<Stack direction="row" display="flex" alignItems="center">
                  <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                    {`다운로드`}
                  </Box>
                  <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
                </Stack>}
                placement="right"
                sx={tooltipTop}
              >
                <a href={`${documentPath.replace(uploadFilePath, fileServerUrl)}`} download style={{ color: '#1976d2' }}>
                  <Box display="flex" alignItems="center">
                    <FilePresent />
                  </Box>
                </a>
              </StyledTooltip>
            );
          } else if (params.row?.results?.status === 'PROCESSING') {
            return (<CircularProgress size={13} />)
          } else if (params.row?.results?.status === 'FAIL') {
            return (
              <ErrorOutline size={13} sx={{ color: '#ef5350' }} />
            )
          }
        }
      }
    },
    {
      field: 'generateOptions',
      headerName: '문서처리 선택사항',
      width: 500,
      renderCell: (params) => {
        if (params.value) {
          let signLabel = "";
          /*if (params.value.sign === 'CERTIFIED_TRUE_COPY') {
            signLabel = "원본대조필";
          } else */if (params.value.sign === 'CERTIFIED_TRUE_COPY_AND_SIGN') {
            // signLabel = "원본대조필 + 날인";
            signLabel = "날인";
          } else/* if (params.value.sign === 'CERTIFIED_TRUE_COPY_AND_SIGN')*/ {
            signLabel = "원본대조필 없음";
          }

          const options = [];
          for (const [item, value] of Object.entries(params.value)) {
            if (value === 'Y') {
              options.push(item);
            }
          }

          console.log(options)
          const otherOptions = options.map(option => {
            let optionLabel = "";
            if (option === "docAttachedNValidDate") {
              optionLabel = "유효문서만";
            } else if (option === "docValidDate") {
              optionLabel = "만료일 내 문서만";
            } else if (option === "docInvalidDate") {
              optionLabel = "만료일 경과 문서 포함";
            } else if (option === "docAttached") {
              optionLabel = "첨부된 문서만";
            } else if (option === "docUnattached") {
              optionLabel = "첨부되지 않은 문서 포함";
            } else if (option === "includeGTestComparisons") {
              optionLabel = "시험성과대비표 포함";
            } else if (option === "myCertified") {
              optionLabel = "제공사 인감 부재시 내 인감 날인";
            }

            return <Chip label={optionLabel} size="small" sx={{ ml: 1 }}></Chip>
          })

          return (
            <>
              <Chip label={signLabel} size="small"></Chip> 
              {otherOptions}
            </>
          )
        }
      }
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const sessionUser = useSelector(state => state.session.sessionUser);
  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const rows = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApprovals);
  const rowsGenerate = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApprovalsGenerate);  
  // const rowCount = useSelector((state) => state.gunit.count);
  const selectedRow = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApproval);
  const options = useSelector((state) => state.materialApprovalProgress.options);

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAll = (queryOptions, docType) => dispatch(g04docuMaterialApprovalActions.selectPaging(queryOptions, docType))
  const selectByGClient = (gclientId, docType) => dispatch(g04docuMaterialApprovalActions.selectByGClient(gclientId, docType))
  // const selectByGProject = (gprojectId, docType) => dispatch(g04docuMaterialApprovalActions.selectByGProject(gprojectId, docType))
  const selectG04docuMaterialApprovalById = (id, docType, initialLoad) => dispatch(g04docuMaterialApprovalActions.selectById(id, docType, initialLoad))
  const select = (id) => dispatch(g04docuMaterialApprovalActions.select(id))
  const handleClickSendEmail = (value) => dispatch(dialogActions.setOptions({ open: true, crudMode: 'C', value }))
  const removeWithFile = ({ id, documentPath, docType }) => dispatch(g04docuMaterialApprovalActions.removeWithFile({ id, documentPath, docType }))
  const showMaterialApprovalProgress = (options) => dispatch(materialApprovalProgressActions.showMaterialApprovalProgress(options))

  const getColumnsByRoute = () => sessionUser.type === 'ADMIN' ? [...COLUMN_CONFIGS] : [...COLUMN_CONFIGS.slice(1)]

  useEffect(() => {
    setColumns(getColumnsByRoute());
  }, [location.pathname, sessionUser.type]);

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  useEffect(() => {
    selectPaging();
  }, [window.location.pathname, queryPaging]);

  const startInterval = (ms, callback) => {
    callback();
    return setInterval(callback, ms);
  };

  const selectPaging = async (deleteOption) => {
    // console.log({ title, from });
    // setLoaded(false)

    let func = null;
    let search = null;
    let interval;

    const { id: gclientId, type } = sessionUser;

    // if (from?.params?.g04docuMaterialApprovalId) {
    //   interval = 5000; // 자재승인서 발급 화면에서 발급 버튼 누르면 생성 여부를 기다리므로 결과 확인 주기를 짧게 했다.
    //   // search = from.params.gprojectId;
    //   // func = selectByGProject;
    //   search = from.params.g04docuMaterialApprovalId;
    //   func = selectG04docuMaterialApprovalById;

    //   alert(`interval: ${interval}`)
    //   makeTimerId = startInterval(interval, () => func(search));
    //   alert(`start makeTimerId: ${makeTimerId}`)
    //   showMaterialApprovalProgress({ open: true, values: { id: from.params.g04docuMaterialApprovalId } });
    // } else {
    //   interval = 15000; // 자재승인서류 보관함 메뉴로 들어오면 생성 후 목록을 확인하는 경우가 대부분일 것이므로 결과 확인 주기를 길게 했다.
    //   if (type === 'ADMIN') {
    //     search = queryPaging;
    //     func = selectAll;
    //   } else if (type === 'GCLIENT') {
    //     search = gclientId;
    //     func = selectByGClient;
    //   }

    //   if (func) {
    //     listTimerId = startInterval(interval, () => func(search));
    //     alert(`start listTimerId: ${listTimerId}`)
    //   }
    // }
    if (from?.params?.g04docuMaterialApprovalId) {
      // 먼저 진행바를 표시
      if (!deleteOption) {
        showMaterialApprovalProgress({
          open: true,
          values: {
            id: from.params.g04docuMaterialApprovalId,
            docType: from.params.docType,
          },
        });
      }
      
      // 약간의 지연 후 데이터 조회
      await new Promise(resolve => setTimeout(resolve, 100));
      await selectG04docuMaterialApprovalById(from.params.g04docuMaterialApprovalId, from.params.docType, true);
    } else {
      if (type === 'ADMIN') {
        search = queryPaging;
        func = selectAll;
      } else if (type === 'GCLIENT') {
        search = gclientId;
        func = selectByGClient;
      }

      if (func) {
        try {
          setLoaded(false);
          await func(search, getDocType());
        } catch (error) {
          console.error('데이터 로딩 중 오류 발생:', error);
        } finally {
          setTimeout(() => setLoaded(true), 300);
        }
      }
    }
  }
  
  // useEffect(
  //   () => {
  //     // console.log(rows)
  //     const rowsProcessing = rows.filter(row => row.results.status === 'PROCESSING' ? true : false);
  //     console.log(rowsProcessing)
  //     if (rowsProcessing.length === 0) {
  //       if (listTimerId) {
  //         clearTimeout(listTimerId);
  //         // setLoaded(true);
  //         alert(`clear listTimerId: ${listTimerId}`)
  //       }

  //       if (makeTimerId) {
  //         clearTimeout(makeTimerId);
  //         // setLoaded(true);
  //         alert(`clear makeTimerId: ${makeTimerId}`)
  //       }
  //     }
  //   }, [rows]
  // )
  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const refresh = () => {
    selectPaging();
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <G04docuMaterialApprovalDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          queryPaging={queryPaging}
          refresh={selectAll}
        />
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} sx={{ mb: 2 }}>
              {
                title && (
                  <Typography variant="h5">
                    {title}
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              {/* <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Refresh />}
                onClick={refresh}
              >
                {"다시 검색하기"}
              </Button> */}
              <Button
                variant="contained"
                sx={{ mb: 2, display: from ? 'none' : "" }}
                // startIcon={<Refresh />}
                onClick={refresh}
              >
                {"검색하기"}
              </Button>
              {/* 그리드가 다 로드 된 후에는 아래처럼 동적으로 컬럼 제어 가능 */}
              {/* <Button
                onClick={() => {
                  apiRef.current.updateColumn({
                    field: 'gclient',
                    headerName: '거래선명',
                    width: 200,
                    hide: false,
                    // editable: true,
                    valueGetter: (params) => {
                      return params.value.name
                    },
                  });
                }}
              >
                {"test"}
              </Button> */}
            </Grid>
          </Grid>
          <div style={{ height: from?.params?.g04docuMaterialApprovalId ? 300 : GRID_HEIGHT.FULL, width: '100%' }}>
            <DataGridPro
              // apiRef={apiRef}
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              autoHeight={from?.params?.g04docuMaterialApprovalId ? true : false}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={from?.params?.g04docuMaterialApprovalId ? rowsGenerate : rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              // rowCount={rowCount}
              // pagination
              pagination={from?.params?.g04docuMaterialApprovalId ? false : true}
              hideFooter={from?.params?.g04docuMaterialApprovalId ? true : false}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
            />
          </div>
        </Box>
      </Container>
      {/* <ConfirmDialog
        removeId={removeObject}
        title={"삭제"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={remove}
        onCancel={() => {}}
      >
        {`선택한 자재승인서류를 삭제하시겠습니까?`}
      </ConfirmDialog> */}
      <G04docuMaterialApprovalEmailDialog />
      <G04docuMaterialApprovalDeleteConfirmDialog /> {/* 삭제 예/아니오 다이얼로그 */}
    </ThemeProvider>
  );
};

export default G04docuMaterialApprovalManagement;
