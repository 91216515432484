import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { FilePond } from 'react-filepond';
import * as pdfjsLib from 'pdfjs-dist';

import { gcoreServerUrl, mode, fileServerUrl, uploadFilePath } from '../../config';
import uploadLabels from '../../FildPondLabel';
import {
  FormInputCheckbox,
  FormInputDate,
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import { dateFormat } from "../../utils";
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as g04docuFileActions from "../../store/g04docuFile";
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";
// import G04docuFileAlertDialog from "./G04docuFileAlertDialog";

const today = new Date();

// 아래 form components의 name과 연계
const defaultValues = {
  startDate: today.getDateWithStartHours(),
  endDate: null,
  validYN: false,
};

let pond;
// let revertButton;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const G04docuFileDialogGeneral = ({
  openGeneral,
  setOpenGeneral,
  selectedRow,
  selectedGClientId,
  selectedGClient,
  selectedDocuFileType,
  refresh,
}) => {
  const processingFile = useRef(false);

  const { g04GeneralDocuId, title, documentType, documentPath/*, startDate, endDate*/ } = selectedRow;
  
  const [alertInfo, setAlertInfo] = useState({});
  const [files, setFiles] = useState([]);
  const [uploadedDocumentPath, setUploadedDocumentPath] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [endDate, setEndDate] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [ocrEndDate, setOcrEndDate] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(null);

  const handleDialogClose = async () => {
    if (previewFile && !isConfirmed) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            <span>{"현재 서류가 등록되지 않은 상태입니다."}</span>
            <br/>
            <span>{"등록하지 않고 그대로 닫으시겠습니까?"}</span>
          </div>
        ),
        type: 'confirm',
        open: true,
        confirmText: "예",
        cancelText: "아니오",
        handleConfirm: async () => {
          await removeG04docuFile(previewFile);
          
          setPreviewFile(null);
          setOcrEndDate(null);
          setIsConfirmed(false);
          setValue("endDate", null);
          setEndDate(null);
          setDisabled(true);
          pond.removeFile();
          
          setOpenGeneral(false);
          initDialog();
        }
      });
      return;
    }
  
    setOpenGeneral(false);
    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setFiles([]);
    setDisabled(true);
    setIsConfirmed(false);
    setPreviewFile(null);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const createGClientG04GeneralDocuMap = ({ gclientId, g04GeneralDocuId, gclient, documentPath, documentType, validYN, startDate, endDate }) => dispatch(gclientG04GeneralDocuMapActions.create({ gclientId, g04GeneralDocuId, gclient, documentPath, documentType, validYN, startDate, endDate }))
  const removeGClientG04GeneralDocuMap = ({ gclientId, documentPath }) => dispatch(gclientG04GeneralDocuMapActions.remove({ gclientId, documentPath }))
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const updateGClientG04GeneralDocuMap = ({ gclientId, g04GeneralDocuId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04GeneralDocuMapActions.modifyDate({ gclientId, g04GeneralDocuId, documentPath, validYN, startDate, endDate }))
  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)

  useEffect(
    () => {
    }, [dispatch]
  )

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === "startDate" || item === "endDate") {
            const newValue = selectedRow[item] ? new Date(selectedRow[item]) : value;
            setValue(item, newValue);
            if (item === "endDate") {
              setEndDate(newValue);
            }
          } else {
            setValue(item, selectedRow[item] || value);
          }
        }

        // 그외 설정할 것들은 여기서 한다.
        selectedRow["endDate"] && documentPath && setDisabled(false);
      }
    }, [selectedRow]
  );

  useEffect(
    async () => {
      if (openGeneral && documentPath) {
        const arr = documentPath.split("/");
        const fileName = arr[arr.length - 1];

        const res = await downloadG04docuFile(documentPath);
        const blob = await res.blob();
        
        setFiles(
          [{
            // source: blob,
            source: JSON.stringify({ file: documentPath }),
            options: {
              type: 'limbo',
              file: {
                name: fileName,
                size: blob.size,
                type: blob.type,
              },
            }
          }]
        )
      }

      // selectedStartDate && setValue('startDate', selectedStartDate);
      // selectedEndDate && setValue('endDate', selectedEndDate);
    }, [openGeneral]
  )

  // useEffect(
  //   () => {
  //     const btn = document.getElementsByClassName("filepond--file-action-button filepond--action-revert-item-processing")[0];
  //     if (btn) {
  //       alert("111")
  //       revertButton = btn;
  //       revertButton.addEventListener('click', handleClickRevert);
  //     }
  //   }, [files]
  // )

  // const handleClickRevert = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    console.log(name, value)
    console.log({ files, documentPath, uploadedDocumentPath });
    if ((documentPath || uploadedDocumentPath) && (name === "endDate" ? value : getValues("endDate"))) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setValue(name, value);
    setValue("validYN", false);
    if (name === "endDate") {
      setEndDate(value);
    }
    return true;
  }

  const updateDate = async () => {
    // setLoading(true);

    // 날짜를 변경하지 않고 처음 조회해 온 상태는 getValues("endDate")는 string이므로 아래 코드에서 getDateWithEndHours 함수 호출시 오류 발생
    // 따라서 string일 경우 date로 변경
    let endDate = getValues("endDate");
    if (endDate && typeof endDate === 'string') {
      endDate = new Date(endDate)
    } else {
      const valid = getValues("validYN");
      if (!valid && !endDate) {
        // TODO : alertDialogActions을 사용하여 AlertDialog를 띄우면 Profile에서 배경이 너무 어둡게 나와 setAlertInfo를 사용하여 이 컴포넌트에서 직접 AlertDialog를 띄움.
        // 아마도 Dialog depth가 다른 것 같음
        // const messageAlert = (<div>
        //   <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
        //   <span>{"이 유효하지 않습니다."}</span>
        // </div>);
        // dispatch(alertDialogActions.setOptions({ alertInfo: { open: true, titleAlert: "안내", messageAlert } }));
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: (
            <div>
              <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
              <span>{"이 유효하지 않습니다."}</span>
            </div>
          ),
          open: true,
        });

        return false;
      }
    }

    const startDate = getValues("startDate");

    await updateGClientG04GeneralDocuMap({
      gclientId: selectedGClientId,
      g04GeneralDocuId,
      documentPath: documentPath || uploadedDocumentPath,
      validYN: getValues("validYN"),
      startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
      endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
    });
    
    // setTimeout(() => setLoading(false), 1000);

    refresh(selectedGClientId);

    return true;
  }
  
  const handleChangeCheckButton = (event, checked, name) => {
    console.log({ event, checked, name });
    if (checked) {
      setValue("endDate", null);
      setEndDate(null);
    }
    setValue("validYN", checked); // 체크박스 상태 업데이트 추가
  }

  const save = (mode) => {
    if (files.length === 0) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            <span>{"서류를 첨부해주세요."}</span>
          </div>
        ),
        open: true,
      });

      return;
    }

    // if (!isConfirmed && previewFile) {
    //   setAlertInfo({
    //     titleAlert: "안내",
    //     messageAlert: (
    //       <div>
    //         <span>{"OCR로 인식된 만료일을 확인해주세요."}</span>
    //         <br/>
    //         <span>{`인식된 만료일: ${ocrEndDate ? dateFormat(ocrEndDate) : '없음'}`}</span>
    //       </div>
    //     ),
    //     open: true,
    //   });
    //   return;
    // }

    // server option의 process 시작됨. 단, 상태에 따라 process 실행여부를 자동으로 판단하므로 파일이 첨부되어 있는 경우 server option의 process 실행안함
    pond.processFiles().then(async (res) => {
      let result = true;
      if (res.length === 0) { // 이미 파일이 첨부되어 있는 상태에서는 만료일 관련 정보만 업데이트 한다.
        result = await updateDate();
      }
  
      if (mode === 'close' && result) {
        setTimeout(() => handleDialogClose(), 300);
      }
    });
  }
  
  const checkPDFContent = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const page = await pdf.getPage(1);
      
      // 텍스트 콘텐츠 확인
      const textContent = await page.getTextContent();
      const hasText = textContent.items.length > 0;
      
      // 이미지 콘텐츠 확인
      const operatorList = await page.getOperatorList();
      const hasImage = operatorList.fnArray.includes(pdfjsLib.OPS.paintImageXObject);
  
      return {
        hasText,
        hasImage,
        isScanned: !hasText && hasImage // 스캔된 문서일 가능성이 높음
      };
    } catch (error) {
      console.error('PDF 콘텐츠 확인 중 오류:', error);
      return null;
    }
  };

  const extractDateFromPDF = async (file, documentType) => {
    console.log(documentType);
    try {
      // PDF 파일을 읽어서 텍스트 추출 - 공통 부분
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const page = await pdf.getPage(1);
      const textContent = await page.getTextContent();
      const text = textContent.items.map(item => item.str).join(' ');
      console.log(text);
      
      // 문서 타입 검증
      const isValidDocument = (() => {
        if (documentType === 'NATIONAL_TAX') {
          // 납   세   증   명   서
          return /납[\s\u3000]{3}세[\s\u3000]{3}증[\s\u3000]{3}명[\s\u3000]{3}서/.test(text);
        } else if (documentType === 'LOCAL_TAX') {
          // 지 방 세   납 세 증 명 ( 신 청 ) 서
          return /지[\s\u3000]{1}방[\s\u3000]{1}세[\s\u3000]{3}납[\s\u3000]{1}세[\s\u3000]{1}증[\s\u3000]{1}명[\s\u3000]{1}\([\s\u3000]{1}신[\s\u3000]{1}청[\s\u3000]{1}\)[\s\u3000]{1}서/.test(text);
        }
        return false;
      })();
      console.log(isValidDocument, documentType);
      if (!isValidDocument) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: (<span>
            {documentType === 'NATIONAL_TAX' 
              ? <>올바른 국세 납세증명서가 아닙니다.<br/>올바른 문서를 첨부해주세요.</>
              : documentType === 'LOCAL_TAX'
                ? <>올바른 지방세 납세증명(신청)서가 아닙니다.<br/>올바른 문서를 첨부해주세요.</>
                : '올바른 문서 형식이 아닙니다.'}
          </span>),
          open: true
        });
        
        // FilePond 인스턴스에서 파일 제거
        if (pond) {
          pond.removeFile();
        }
        
        // 문서 검증 실패 시 Promise reject
        throw new Error('invalid_document_type');
      }

      let matches;
      
      // 문서 타입별 패턴 매칭
      if (documentType === 'NATIONAL_TAX') {
        // (예) 유 효 기 간   2 0 2 4   년   4   월   1 1   일
        const validityPattern = /유\s*효\s*기\s*간\s*[:\s]*(\d\s*\d\s*\d\s*\d)\s*[년\.]\s*(\d\s*\d?)\s*[월\.]\s*(\d\s*\d?)\s*[일\.]?/g;
        matches = [...text.matchAll(validityPattern)];
        
        // 테스트 케이스 주석들...
        
      } else if (documentType === 'LOCAL_TAX') {
        // (예) 2 0 2 2   0 5   0 3 => 이런 글자가 여러개 나오는데 여기서 가장 큰 날짜
        const validityPattern = /(\d\s\d\s\d\s\d)\s{3}(\d\s\d)\s{3}(\d\s\d)/g;
        matches = [...text.matchAll(validityPattern)];
        console.log(matches);
      }
  
      // 날짜 처리 - 공통 부분
      if (matches?.length > 0) {
        const dates = matches.map(match => {
          const [_, yearStr, monthStr, dayStr] = match;
          const year = yearStr.replace(/\s/g, '');
          const month = monthStr.replace(/\s/g, '');
          const day = dayStr.replace(/\s/g, '');
          return new Date(year, month - 1, day);
        });
        
        return new Date(Math.max(...dates));
      }
      
    } catch (error) {
      if (error.message !== 'invalid_document_type') {
        console.error('PDF 파일 읽기 오류:', error);
      }
      // 에러 발생 시 Promise reject
      throw error;
    }
  };

  // 확인 버튼 추가
  const handleConfirm = () => {
    setIsConfirmed(true);
    createGClientG04GeneralDocuMap({
      gclientId: selectedGClientId,
      g04GeneralDocuId,
      gclient: selectedGClient,
      documentPath: previewFile,
      documentType,
      validYN: getValues("validYN"),
      startDate: getValues("startDate") ? dateFormat(getValues("startDate").getDateWithStartHours()) : null,
      endDate: getValues("endDate") ? dateFormat(getValues("endDate").getDateWithEndHours()) : null,
    }).then(response => {
      setIsConfirmed(true); // 성공적으로 처리된 후에 isConfirmed를 true로 설정
      refresh(selectedGClientId);
      setUploadedDocumentPath(previewFile);
      setOpenGeneral(false); // handleDialogClose() 대신 직접 dialog를 닫음
      initDialog();
    });
  };

  // 취소 버튼 핸들러 추가
  const handleCancel = async () => {
    handleDialogClose();
  };

  return (
    <>
      <Dialog
        open={openGeneral}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {`${title} 첨부`}
        </DialogTitleClose>
        <DialogContent>
          {/* <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {/* <Grid item xs={4}>
                  <FormInputDate
                    name="startDate"
                    control={control}
                    label={"유효기간 시작"}
                    onChangePeriodValue={(value) => handleChangePeriod(value, "startDate")}
                    value={getValues("startDate")}
                    setValue={setValue}
                    // onEdit={checkEdit}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <FormInputDate
                    name="endDate"
                    control={control}
                    label={"만료일"}
                    onChangePeriodValue={handleChangePeriod}
                    value={endDate}
                    setValue={setValue}
                    getValues={getValues}
                    // onEdit={checkEdit}
                    inputHeight={38}
                  />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"flex-start"} alignItems={"center"} sx={{ display: selectedRow?.documentType === 'NATIONAL_TAX' || selectedRow?.documentType === 'LOCAL_TAX' ? 'none' : 'flex' }}>
                  {/* <LoadingButton
                    variant={"contained"}
                    onClick={handleClickUpdateDate}
                    loading={loading}
                    // TODO : 파일첨부, 날짜수정 등 동작이나 값 변경 useEffect등을 통해 disabled 조작하도록 할 것
                    // disabled={files?.length === 0}
                    // disabled={!(documentPath || uploadedDocumentPath) || files?.length === 0}
                    // disabled={(documentPath || uploadedDocumentPath) && files?.length > 0 && getValues("endDate") ? false : true}
                    // disabled={(documentPath || uploadedDocumentPath)  ? false : true}
                    disabled={disabled}
                    sx={{ mr: 1 }}
                  >
                    {"만료일 변경"}
                  </LoadingButton> */}
                  <FormInputCheckbox
                    name="validYN"
                    control={control}
                    setValue={setValue}
                    onChangeCheckValue={handleChangeCheckButton}
                  />
                  <Typography>{"만료일 없음"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FilePond
                  ref={ref => pond = ref}
                  files={files}
                  credits={false}
                  // allowDownloadByUrl={false} // by default downloading by URL disabled
                  allowMultiple={false}
                  allowReplace={false}
                  allowProcess={false}
                  allowRevert={false}
                  instantUpload={false}
                  acceptedFileTypes={['image/*', 'application/pdf']}
                  onupdatefiles={setFiles} // onupdatefiles(files)
                  // onupdatefiles={async (fileItems) => {
                  //   setFiles(fileItems);
                  //   const list = ['NATIONAL_TAX', 'LOCAL_TAX'];
                    
                  //   // 파일이 JSON 형태로 들어온 경우(기존 첨부파일) PDF 분석을 하지 않음
                  //   if (fileItems.length > 0 && fileItems[0].source && typeof fileItems[0].source === 'string') {
                  //     try {
                  //       JSON.parse(fileItems[0].source);
                  //       return; // 기존 파일인 경우 여기서 종료
                  //     } catch (e) {
                  //       // JSON 파싱 실패시 새로운 파일로 간주하고 계속 진행
                  //     }
                  //   }

                  //   if (fileItems.length > 0 && list.includes(documentType) && !processingFile.current) {
                  //     const file = fileItems[0].file;
                      
                  //     if (file.type !== 'application/pdf') {
                  //       return;
                  //     }
                  
                  //     try {
                  //       processingFile.current = true;
                  
                  //       const contentInfo = await checkPDFContent(file);
                  //       if (contentInfo?.isScanned) {
                  //         setAlertInfo({
                  //           titleAlert: "안내",
                  //           messageAlert: (<span>
                  //             스캔된 PDF 파일은 텍스트 추출이 어려울 수 있습니다.<br />
                  //             {!endDate && "만료일을 직접 입력해주세요."}
                  //           </span>),
                  //           open: true
                  //         });
                  //         return;
                  //       }
                  
                  //       const expiryDate = await extractDateFromPDF(file, documentType);
                  //       if (!expiryDate) {
                  //         setAlertInfo({
                  //           titleAlert: "안내",
                  //           messageAlert: (<span>
                  //             {documentType === 'NATIONAL_TAX' 
                  //               ? <>올바른 형식의 국세 납세증명서가 아니거나<br/>만료일을 찾을 수 없습니다.<br/>만료일을 직접 입력해주세요.</>
                  //               : documentType === 'LOCAL_TAX'
                  //                 ? <>올바른 형식의 지방세 납세증명서가 아니거나<br/>만료일을 찾을 수 없습니다.<br/>만료일을 직접 입력해주세요.</>
                  //                 : '만료일을 찾을 수 없습니다.'}
                  //           </span>),
                  //           open: true
                  //         });
                  //         // 파일은 유지하되 만료일 관련 필드는 초기화
                  //         setValue('endDate', null);
                  //         setEndDate(null);
                  //         setDisabled(true);
                  //         setValue('validYN', false);
                  //         return;
                  //       }
                        
                  //       setValue('endDate', expiryDate);
                  //       setEndDate(expiryDate);
                  //       setDisabled(false);
                  //       setValue('validYN', false);
                  
                  //       setAlertInfo({
                  //         titleAlert: "안내",
                  //         messageAlert: (<span>
                  //           PDF에서 추출한 만료일을 자동으로 설정합니다.
                  //         </span>),
                  //         open: true
                  //       });
                  //     } catch (error) {
                  //       if (error.message !== 'invalid_document_type') {
                  //         setAlertInfo({
                  //           titleAlert: "안내",
                  //           messageAlert: (<span>
                  //             PDF에서 만료일을 추출할 수 없습니다.<br />
                  //             {!endDate && "만료일을 직접 입력해주세요."}
                  //           </span>),
                  //           open: true
                  //         });
                  //       }
                  //     } finally {
                  //       processingFile.current = false;
                  //     }
                  //   }
                  // }}
                  labelIdle={uploadLabels.labelIdle}
                  labelInvalidField={uploadLabels.labelInvalidField}
                  labelFileWaitingForSize={uploadLabels.labelFileWaitingForSize}
                  labelFileSizeNotAvailable={uploadLabels.labelFileSizeNotAvailable}
                  labelFileLoading={uploadLabels.labelFileLoading}
                  labelFileLoadError={uploadLabels.labelFileLoadError}
                  labelFileProcessing={uploadLabels.labelFileProcessing}
                  labelFileProcessingComplete={uploadLabels.labelFileProcessingComplete}
                  labelFileProcessingAborted={uploadLabels.labelFileProcessingAborted}
                  labelFileProcessingError={uploadLabels.labelFileProcessingError}
                  labelFileProcessingRevertError={uploadLabels.labelFileProcessingRevertError}
                  labelFileRemoveError={uploadLabels.labelFileRemoveError}
                  labelTapToCancel={uploadLabels.labelTapToCancel}
                  labelTapToRetry={uploadLabels.labelTapToRetry}
                  labelTapToUndo={uploadLabels.labelTapToUndo}
                  labelButtonRemoveItem={uploadLabels.labelButtonRemoveItem}
                  labelButtonAbortItemLoad={uploadLabels.labelButtonAbortItemLoad}
                  labelButtonRetryItemLoad={uploadLabels.labelButtonRetryItemLoad}
                  labelButtonAbortItemProcessing={uploadLabels.labelButtonAbortItemProcessing}
                  labelButtonUndoItemProcessing={uploadLabels.labelButtonUndoItemProcessing}
                  labelButtonRetryItemProcessing={uploadLabels.labelButtonRetryItemProcessing}
                  labelButtonProcessItem={uploadLabels.labelButtonProcessItem}
                  server={{
                    url: gcoreServerUrl,
                    timeout: 700000,
                    process: {
                        url: `/api/g04docuFiles/uploadFile`,
                        headers: {
                          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                        },
                        ondata: (formData) => {
                          // console.log("server:process:ondata");
                          // TODO : 이렇게 body로 데이터를 넘길지 header를 사용할지 추후 검토. 우선은 body 사용
                          formData.append('gclientId', selectedGClientId);
                          formData.append('gclientName', selectedGClient?.name);
                          formData.append('docuFileType', selectedDocuFileType);
                          // 주의 : build하면 files가 []임. 원인파악 안됨. 확인결과 pond.getFiles()는 사용가능하여 이 코드로 대체
                          // formData.append('fileName', files[0].filename); // TODO : 한글 파일명인 경우 filepond를 그대로 쓸 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함
                          formData.append('fileName', (pond.getFiles())[0].filename); // TODO : 한글 파일명인 경우 filepond를 그대로 쓸 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함
                          formData.append('validYN', documentType === 'NATIONAL_TAX' || documentType === 'LOCAL_TAX' ? true : getValues('validYN'));
                          // formData.append('endDate', getValues('endDate')); // 이렇게 보내면 null이 string으로 전달됨
                          let newEndDate = getValues('endDate');
                          newEndDate = newEndDate ? dateFormat(newEndDate.getDateWithEndHours()) : null;
                          if (newEndDate) {
                            formData.append('endDate', newEndDate);
                          }
                          formData.append('docuCode', documentType);
                          // console.log(formData);
                          return formData;
                        },
                        onerror: (res) => {
                          console.log(res);
                          if (typeof res === 'string') {
                            let needAlert = false;
                            const jsonRes = JSON.parse(res);
                            if (mode.indexOf('java') > 0) {
                              console.log(jsonRes)
                              if (jsonRes.code === 43001 || jsonRes.code === '43001') {
                                needAlert = true;
                              }
                            } else {
                              if (jsonRes.msg === 'invalid endDate') {
                                needAlert = true;
                              }
                            }

                            if (needAlert) {
                              setAlertInfo({
                                titleAlert: "안내",
                                messageAlert: (
                                  <div>
                                    <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
                                    <span>{"이 유효하지 않습니다."}</span>
                                  </div>
                                ),
                                open: true,
                              });
                            }
                          }
                        },
                        onload: (res) => { // 서버에서 api 동작 후 리턴되는 callback
                          console.log(res)
                          const response = JSON.parse(res);
                          if (documentType === 'NATIONAL_TAX' || documentType === 'LOCAL_TAX') {
                            setPreviewFile(response.file);

                            const randNumber = Math.floor(Math.random()*99); // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
                            const encodedPath = encodeURIComponent(response.file.replace(uploadFilePath, ''));
                            const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
                            
                            setCurrentUrl(path);

                            setDocumentViewerDrawerOpen(true);
                            
                            if (response.endDate) { // OCR 판독 성공
                              const parsedDate = new Date(response.endDate);
                              setOcrEndDate(parsedDate);
                              setValue('endDate', parsedDate);
                              setEndDate(parsedDate);
                              setDisabled(false);
                            } else { // OCR 판독 실패
                              // setAlertInfo({
                              //   titleAlert: "안내",
                              //   messageAlert: "만료일을 자동으로 판독하지 못했습니다. 직접 만료일을 입력해주세요.",
                              //   open: true,
                              // });
                              alert("만료일을 자동으로 판독하지 못했습니다.\n직접 만료일을 입력해주세요.");

                              setValue('endDate', null);
                              setEndDate(null);
                              setDisabled(true);
                            }
                          } else {
                            const startDate = getValues("startDate");
                            const endDate = getValues("endDate");

                            createGClientG04GeneralDocuMap({
                              gclientId: selectedGClientId,
                              g04GeneralDocuId,
                              gclient: selectedGClient,
                              documentPath: response.file,
                              documentType,
                              validYN: getValues("validYN"),
                              startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
                              endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
                            }).then(() => {
                              refresh(selectedGClientId);
                              setUploadedDocumentPath(response.file);
                            });
                          }
                          
                          return res;
                        },
                    },
                    // custom
                    // process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    //   const formData = new FormData();
                    //   formData.append(fieldName, file, file.name);
                    //   formData.append('gclientId', selectedGClientId);
                    //   formData.append('docuFileType', docuFileType);

                    //   // store 사용
                    //   window.fetch(`${gcoreServerUrl}/api/g04docuFiles/uploadFile`, {
                    //     method: 'POST',
                    //     body: formData,
                    //   })
                    // },
                    revert: {
                      url: `/api/g04docuFiles/deleteFile`,
                      method: 'DELETE',
                      // 중요: 아래 헤더가 없으면 backend에서 req.body를 얻지 못함
                      headers: {
                        "Content-Type": "application/json"
                      },
                      onload: /*async*/ (res) => { // 서버에서 api 동작 후 리턴되는 callback
                        // alert("server:revert:ondata1");
                        console.log("server:revert:ondata1");
                        console.log(res);
                        console.log(JSON.parse(res).file);
                        
                        removeGClientG04GeneralDocuMap({ gclientId: selectedGClientId, documentPath: JSON.parse(res).file })
                          .then(response => {
                            console.log(response);
                            
                            setValue("endDate", null);
                            setUploadedDocumentPath("");
                            setDisabled(true);
                            
                            refresh(selectedGClientId);
                          });
                        
                        // alert("server:revert:ondata2");
                        console.log("server:revert:ondata2");

                        return res;
                      },
                    },
                  }}
                >
                </FilePond>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {previewFile && !isConfirmed ? (
            <>
              <Tooltip title="서류 등록을 완료합니다." arrow placement="left">
                <Button 
                  onClick={handleConfirm}
                  disabled={!getValues("endDate")} // 만료일이 없으면 확인 버튼 비활성화
                >
                  {"확인"}
                </Button>
              </Tooltip>
              <Button onClick={handleCancel}>{"취소"}</Button>
            </>
          ) : (
            <>
              {documentType === 'NATIONAL_TAX' || documentType === 'LOCAL_TAX' ? (
                <Tooltip title="만료일(유효기간)을 자동으로 판독합니다." arrow placement="left">
                  <Button onClick={save}>
                    {"첨부"}
                  </Button>
                </Tooltip>
              ) : (
                <Button onClick={save}>
                  {"저장"}
                </Button>
              )}
              {
                documentType !== 'NATIONAL_TAX' && documentType !== 'LOCAL_TAX' && (
                  <Button onClick={() => save('close')}>{"저장 후 닫기"}</Button>
                )
              }
              <Button onClick={handleDialogClose}>{"닫기"}</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* <G04docuFileAlertDialog /> */}
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      <DocumentViewerDrawer 
        open={documentViewerDrawerOpen}
        onClose={() => setDocumentViewerDrawerOpen(false)}
        url={currentUrl}
      />
    </>
  );
};

export default G04docuFileDialogGeneral;
