import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as g04GeneralDocuActions from "../../store/g04GeneralDocu";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  comments: "",
};

const G04GeneralDocuDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  queryPaging,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const addG04GeneralDocu = ({ id, name, code, comments }) => dispatch(g04GeneralDocuActions.create({ id, name, code, comments }))
  const modifyG04GeneralDocu = ({ id, name, code, comments }) => dispatch(g04GeneralDocuActions.modify({ id, name, code, comments }))

  const onSubmit = ({ id, name, code, comments }) => {
    setErrors([]);
    
    // console.log({ id, name, code, comments });
    // return;
    let func;
    if (crudMode === 'C') {
      func = addG04GeneralDocu;
    } else if ((crudMode === 'U')) {
      func = modifyG04GeneralDocu;
    }
    func({ id, name, code, comments })
      .then (res => {
        handleDialogClose();
        refresh(queryPaging);
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      // console.log(selectedRow);
      // ["id", "name", "code", "comments"].forEach(item => setValue(item, selectedRow && selectedRow[item] || ""))
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
      }
    }
    , [selectedRow]
  );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [crudMode]
  );

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'C' && "일반서류 규격 등록"}
        { crudMode === 'R' && "일반서류 규격 상세"}
        { crudMode === 'U' && "일반서류 규격 수정"}
      </DialogTitleClose>
      <DialogContent>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && (
            <>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>
          )
        }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default G04GeneralDocuDialog;
