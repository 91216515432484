import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  LinearProgress,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { Add } from "@mui/icons-material";

import {
  FormInputText,
  FormInputDate,
} from "../form";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
  changeArrayOrder,
} from "../../utils";
import { format } from 'date-fns'; 
import { AlertDialog } from "../dialog";
import * as gclientPaymentsActions from "../../store/gclientPayments";
import GClientServiceDialogPop from "./GClientServiceDialogPop";
import { SERVICE_AMOUNTS } from '../../constants/serviceAmounts';
import { PARTNERSHIP_TYPE, MEMBERSHIP_TYPE, MEMBERSHIP_TYPE_LABELS } from '../../constants/gclientTypes';

const useStyles = makeStyles({
  card: { width: 850 },
  cardHeader: { backgroundColor: "#eaeaea", padding: '8px 16px' },
  table: { border: '1px solid #e0e0e0' },
  tableCell: { padding: '4px', border: '1px solid #e0e0e0' },
  tableRow: { color: '#e0e0e0' }, // 텍스트 색상을 #e0e0e0로 설정
});

const defaultValues = {
  amount: "",
  partnership: "",
  membership: MEMBERSHIP_TYPE.OPTION01, // 초기값 설정
  startDate: new Date(), // 초기값 설정
  endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // 초기값 설정
  comment: "",
};

const GClientServiceDialog = ({
  open,
  setOpen,
  params,
}) => {

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const classes = useStyles();
  const [pageSize, setPageSize] = useState(100);
  const [loaded, setLoaded] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ open: false, titleAlert: '오류', messageAlert: '' });

  const [gclientId, setGclientId] = useState(null); // gclientId를 상태로 관리
  const [expanded, setExpanded] = useState(true);
  const [popOpen, setPopOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();

  const rows = useSelector((state) => state.gclientPayments.gclientPayments);
  const rowCount = useSelector((state) => state.gclientPayments.count);
  
  const handleDialogClose = () => {
    setOpen(false);    
  };

  const initDialog = () => {
    
    if (params && params.row) {
      
      console.log('initDialog => ', params);
      setGclientId(params.row.id);

      let amount = 0;
      if (params.row.partnership === PARTNERSHIP_TYPE.비회원사) {
        amount = SERVICE_AMOUNTS.NON_MEMBER_BASIC;
      } else {
        amount = SERVICE_AMOUNTS.MEMBER_BASIC;
      }
      setValue('amount', amount.toLocaleString());
    }
  }

  const selectAllPayments = useCallback(() => {
    if (!gclientId) return; // gclientId가 없으면 실행하지 않음

    setLoaded(false);
    dispatch(gclientPaymentsActions.selectAll(gclientId))
    .then((response) => {
      console.log(response);
    })
    .catch(error => {
      console.error('목록 조회 실패:', error);
      setLoaded(true);
    })
    .finally(() => {
      setLoaded(true);
    });
  }, [gclientId, dispatch]); // gclientId가 변경될 때마다 함수 재생성
    
  useEffect(() => {
    if (open) {
      initDialog();      
    }
  }, [open, params]);

  // gclientId가 설정되면 selectAllPayments 실행
  useEffect(() => {
    if (gclientId) {
      console.log('gclientId changed => ', gclientId);
      selectAllPayments();
    }
  }, [gclientId, selectAllPayments]);
  
  
  const onSubmit = async (data) => {
    console.log('onSubmit => ', data, gclientId);

    const confirmed = window.confirm('등록하시겠습니까?');
    if (!confirmed) {
      return;
    }

    // 콤마 제거
    const amount = data.amount.replace(/,/g, '');

    // 날짜 형식 변환
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'yyyy-MM-dd') + ' 00:00:00';
    };

    const startDate = formatDate(data.startDate);
    const endDate = formatDate(data.endDate);

    const formattedData = {
      ...data,
      gclientId,
      amount,
      startDate,
      endDate,
    };

    console.log('onSubmit formattedData => ', formattedData);

    setLoaded(false);
    try {
      const response = await dispatch(gclientPaymentsActions.create(formattedData));
      console.log(response);

      selectAllPayments();
    } catch (error) {
      console.error('등록 실패:', error);
      const message = error.serverResponse?.message || '서비스 저장에 실패했습니다.';
      setAlertInfo({
        open: true,
        titleAlert: '등록 오류',
        messageAlert: message
      });
    } finally {
      setLoaded(true);
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeStartDate = (value) => {
    setValue('startDate', value);
    setStartDate(value);

    //console.log('handleChangeStartDate => ', value);
    // value에 365일을 추가
    const endDate = new Date(value);
    endDate.setFullYear(endDate.getFullYear() + 1);
    //endDate.setDate(endDate.getDate() - 1);
    
    handleChangeEndDate(endDate);
    
  }

  const handleChangeEndDate = (value) => {
    setValue('endDate', value);
    setEndDate(value);
  }

  const handleMembershipChange = (event) => {
    const selectedValue = event.target.value;
    //setMembership(selectedValue);

    console.log("selectedValue", selectedValue);
    let amount = 0;
    if (params.row.partnership === PARTNERSHIP_TYPE.비회원사) {
      if (selectedValue === MEMBERSHIP_TYPE.DEFAULT) {
        amount = SERVICE_AMOUNTS.NON_MEMBER_BASIC;
      } else {
        amount = SERVICE_AMOUNTS.NON_MEMBER_OPTION01;
      }
    } else {
      if (selectedValue === MEMBERSHIP_TYPE.DEFAULT) {
        amount = SERVICE_AMOUNTS.MEMBER_BASIC;
      } else {
        amount = SERVICE_AMOUNTS.MEMBER_OPTION01;
      }
    }

    setValue('amount', amount.toLocaleString());
  };

  const handleEdit = (row) => {
    console.log('Edit payment with row:', row);
    // 수정 팝업 추가
    setSelectedRow(row);
    setPopOpen(true);
  };

  const handleEditSuccess = () => {
    setPopOpen(false);
    setSelectedRow(null);
    selectAllPayments();
  }

  const handleDelete = (seq) => {
    console.log('Delete payment with seq:', seq);
    // 삭제 로직 추가
    const confirmed = window.confirm('삭제 하시겠습니까?');
    if (!confirmed) {
      return;
    }

    setLoaded(false);
    dispatch(gclientPaymentsActions.remove({seq}))
    .then((response) => {
      console.log(response);
      selectAllPayments();
    })
    .catch(error => {
      console.error('삭제 실패:', error);
      const message = error.serverResponse?.message || '서비스 삭제에 실패했습니다.';
      setAlertInfo({
        open: true,
        titleAlert: '삭제 오류',
        messageAlert: message
      });
    })
    .finally(() => {
      setLoaded(true);
    });
  };

  const columns = [
    { field: 'seq', headerName: '번호', width: 40, 
      renderCell: (params) => {
        //console.log('params => ', params);
        try {
          const rows = params.api.getRowModels();
          if (!rows) return '';
    
          const rowsArray = Array.from(rows.values());
          const index = rowsArray.findIndex(row => row.id === params.id);
          
          // 인덱스가 유효한 경우에만 번호 반환
          return index !== -1 ? String(rowsArray.length - index) : '';
        } catch (error) {
          console.warn('Row number calculation failed:', error);
          return '';
        }
      },
      align: 'center',
    },
    { field: 'membership', headerName: '서비스명', width: 180, 
      valueGetter: (params) => MEMBERSHIP_TYPE_LABELS[params.value] || params.value},
    { field: 'payDate', headerName: 'CMS 결제일자', width: 100, },
    { field: 'startDate', headerName: '과금 시작일', width: 100, 
      valueGetter: (params) => {
        if (!params.value) return '';
        try {
            return format(new Date(params.value), 'yyyy-MM-dd');
        } catch (error) {
            console.error('Date parsing error:', error);
            return params.value;
        }
      }
    },
    { field: 'endDate', headerName: '과금 종료일', width: 100, 
      valueGetter: (params) => {
        if (!params.value) return '';
        try {
            return format(new Date(params.value), 'yyyy-MM-dd');
        } catch (error) {
            console.error('Date parsing error:', error);
            return params.value;
        }
      }
    },
    { field: 'amount', headerName: 'CMS 과금액', width: 100, 
      valueGetter: (params) => {
        if (!params.value) return '0';
        return params.value.toLocaleString() ;
      },
      //align: 'right',
      //headerAlign: 'right'
    },
    { field: 'comment', headerName: '비고', width: 220, },
    {
      field: 'actions',
      headerName: '기능',
      width: 80,
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
            <IconButton
                size="small"
                onClick={() => handleEdit(params.row)}
                color="primary"
                title="수정"
            >
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
                size="small"
                onClick={() => handleDelete(params.id)}
                color="error"
                title="삭제"
                style={{ marginLeft: 4 }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </div>
      ),
    },
  ]; 

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose} 
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        scroll="body"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {"서비스 관리"}
        </DialogTitleClose>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title="거래선 정보"
                  action={
                    <IconButton onClick={handleExpandClick}>
                      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  }
                  className={classes.cardHeader}
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    {params && params.row ? (
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableBody>
                            <TableRow className={classes.tableRow}>
                              <TableCell variant="head" className={classes.tableCell}>거래선정보</TableCell>
                              <TableCell className={classes.tableCell}>{params.row.name} , {'대표 : '} {params.row.ceo}</TableCell>
                              <TableCell variant="head" className={classes.tableCell}>파트너쉽</TableCell>
                              <TableCell className={classes.tableCell}>{params.row.partnership}</TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRow}>
                              <TableCell variant="head" className={classes.tableCell}>사업자번호</TableCell>
                              <TableCell className={classes.tableCell}>{params.row.bizRegNumber}</TableCell>
                              <TableCell variant="head" className={classes.tableCell}>전화번호</TableCell>
                              <TableCell className={classes.tableCell}>{params.row.phone}</TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRow}>
                              <TableCell variant="head" className={classes.tableCell}>주소</TableCell>
                              <TableCell colSpan={3} className={classes.tableCell}>{params.row.address}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {"데이터를 불러오는 중입니다..."}
                      </Typography>
                    )}
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title="서비스 관리"
                  className={classes.cardHeader}
                />
                <CardContent>
                  <Grid conatiner>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <FormControl component="fieldset">
                            <Controller 
                              control={control}
                              name="membership"
                              onCustomChange={handleMembershipChange}
                              render={({ field }) => (
                                <RadioGroup {...field} row>
                                  <FormControlLabel
                                    value={MEMBERSHIP_TYPE.DEFAULT}
                                    control={<Radio />}
                                    label="자재승인서"
                                  />
                                  <FormControlLabel
                                    value={MEMBERSHIP_TYPE.OPTION01}
                                    control={<Radio />}
                                    label="자재승인서+납품확인서"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <FormInputDate
                              name="startDate"
                              control={control}
                              label={"과금 시작일"}
                              onChangePeriodValue={handleChangeStartDate}
                              getValues={getValues}
                              setValue={setValue}
                              inputHeight={37}
                              fontSize={14}
                              clearable={true}
                              customWidth={175} 
                              iconSize={"small"}
                            />
                            <FormInputDate
                              name="endDate"
                              control={control}
                              value={endDate}
                              label={"과금 종료일"}
                              onChangePeriodValue={handleChangeEndDate}
                              getValues={getValues}
                              setValue={setValue}
                              inputHeight={37}
                              fontSize={14}
                              clearable={true}
                              customWidth={175} 
                              iconSize={"small"}
                            />
                            <FormInputText
                              name="amount"
                              control={control}
                              label={"과금액"}
                              onCustomChange={e=>setValue('amount', e.target.value)}
                              sx={{
                                width: 90,
                                '& .MuiFormLabel-root' : {
                                  fontSize: '0.85rem',
                                },
                                input: { fontSize: '0.85rem' }
                              }}
                              
                            />
                            <FormInputText
                              name="comment"
                              control={control}
                              label={"비고"}
                              sx={{
                                width: 200,
                                '& .MuiFormLabel-root' : {
                                  fontSize: '0.85rem',
                                },
                                input: { fontSize: '0.85rem' }
                              }}
                            />
                            <Button
                              variant="contained"
                              startIcon={<Add />}
                              onClick={handleSubmit(onSubmit)}
                            >
                              {"등록하기"}
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <div style={{ width: '100%', height: 250}}>
                            <DataGridPro
                              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                              initialState={{ pinnedColumns: { right: ['actions'] } }}
                              slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                                loadingOverlay: LinearProgress,
                              }}
                              columnHeaderHeight={38}
                              rowHeight={34}
                              getRowId={(row) => row.id || row.seq}
                              rows={rows}
                              rowCount={rowCount}
                              loading={!loaded}
                              columns={columns}
                              pageSize={pageSize}
                              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                              rowsPerPageOptions={[10, 20, 50, 100]}
                              pagination
                            />
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid> 
                </CardContent>
              </Card>
            </Grid>
          </Grid>
         
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <GClientServiceDialogPop 
        popOpen={popOpen}
        setPopOpen={setPopOpen}
        selectedRow={selectedRow}
        params={params}
        onSuccess={handleEditSuccess}
      />
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />

    </>
  );
};

export default GClientServiceDialog;
