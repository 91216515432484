import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  FormInputDate,
  FormInputCheckbox,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import { dateFormat } from "../../utils";
import * as dialogActions from "../../store/components/dialog";
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as gclientG04docuGCertificationMapActions from "../../store/gclientG04docuGCertificationMap";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";

// 아래 form components의 name과 연계
const defaultValues = {
  endDate: null,
  validYN: false,
};

const G04docuModifyDateDialog = ({ }) => {

  const initDialog = () => {
   
  }
  const handleDialogClose = () => {
    dispatch(dialogActions.setOptions({ open: false, name: "G04docuModifyDateDialog", crudMode: '' }));

    initDialog();
  }
  
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const [alertInfo, setAlertInfo] = useState({});
  const [selectedRowEndDate, setSelectedRowEndDate] = useState(new Date());

  // 데이터 관리
  const dispatch = useDispatch();

  const options = useSelector((state) => state.dialog.options);

  const updateGClientG04GeneralDocuMap = ({ gclientId, g04GeneralDocuId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04GeneralDocuMapActions.modifyDate({ gclientId, g04GeneralDocuId, documentPath, validYN, startDate, endDate }))
  const updateGClientG04docuGCertificationMap = ({ gclientId, g04docuGCertificationId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04docuGCertificationMapActions.modifyDate({ gclientId, g04docuGCertificationId, documentPath, validYN, startDate, endDate }))
  const updateGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04docuGTestMapActions.modifyDate({ gclientId, g04docuGTestId, documentPath, validYN, startDate, endDate }))
  const updateGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04docuEtcMapActions.modifyDate({ gclientId, g04docuEtcId, documentPath, validYN, startDate, endDate }))

  const onSubmit = async ({ }) => {

    const docuType = options?.values?.params?.row?.docuType;
    const gclientId = options?.values?.params?.row?.gclientId;
    const g04docuId = options?.values?.params?.row?.id;
    const documentPath = options?.values?.params?.row?.documentPath;

    // console.log({ docuType, gclientId, g04docuId, endDate, validYN, documentPath });

    try {
      if (docuType && gclientId && g04docuId && documentPath) {
        const validYN = getValues("validYN");
        if (!validYN && !selectedRowEndDate) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: (
              <div>
                <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
                <span>{"이 유효하지 않습니다."}</span>
              </div>
            ),
            open: true,
          });

          return false;
        }
        
        // console.log({ endDate: selectedRowEndDate.getDateWithEndHours(), validYN: getValues("validYN") })

        if (docuType === 'GENERAL') {
          await updateGClientG04GeneralDocuMap({
            gclientId,
            g04GeneralDocuId: g04docuId,
            documentPath,
            validYN: getValues("validYN"),
            // endDate: selectedRowEndDate instanceof Date ? dateFormat(selectedRowEndDate.getDateWithEndHours()) : null,
            endDate: selectedRowEndDate ? dateFormat(selectedRowEndDate.getDateWithEndHours()) : null,
          });
        } else if (docuType === 'CERTIFICATION') {
          await updateGClientG04docuGCertificationMap({
            gclientId,
            g04docuGCertificationId: g04docuId,
            documentPath,
            validYN: getValues("validYN"),
            endDate: selectedRowEndDate ? dateFormat(selectedRowEndDate.getDateWithEndHours()) : null,
          });
        } else if (docuType === 'TEST') {
          await updateGClientG04docuGTestMap({
            gclientId,
            g04docuGTestId: g04docuId,
            documentPath,
            validYN: getValues("validYN"),
            endDate: selectedRowEndDate ? dateFormat(selectedRowEndDate.getDateWithEndHours()) : null,
          });
        } else if (docuType === 'ETC') {
          await updateGClientG04docuEtcMap({
            gclientId,
            g04docuEtcId: g04docuId,
            documentPath,
            validYN: getValues("validYN"),
            endDate: selectedRowEndDate ? dateFormat(selectedRowEndDate.getDateWithEndHours()) : null,
          });
        }
        
        options?.action && options?.action({ gclientId, types: options?.values.types, searchName: options?.values.searchName });

        // TODO : 오류발생시 처리 필요
        handleDialogClose();
      }
    } catch (e) {
      // TODO : 오류발생시 처리 필요
    }
  }

  useEffect(
    () => {
      if (options) {
        // if (options.values && options.values.alarms && options.values.alarms.length > 0) {
          
        // }

        if (options?.crudMode === 'U') {
          let newEndDate = null;
          const endDate = options?.values?.params?.row?.endDate;
          if (endDate) {
            if (endDate instanceof Date) {
              newEndDate = endDate;
            } else if (typeof endDate === 'string') {
              newEndDate = new Date(endDate);
            }

            setSelectedRowEndDate(newEndDate);
            setValue("endDate", newEndDate);
          }
        }
      }
    }
    , [options]
  );

  const handleChangeCheckButton = (event, checked, name) => {
    console.log({ event, checked, name });
    if (checked) {
      setValue("endDate", null);
      setSelectedRowEndDate(null);
    }
  }

  const handleChangePeriod = (value, name) => {
    console.log({ type: typeof value, "Date object": value instanceof Date, value })
    // value는 Date 객체임
    setValue(name, value);
    setValue("validYN", false);
    setSelectedRowEndDate(value);
    return true;
  }

  return (
    <>
      <Dialog
        open={options?.open && options?.name === "G04docuModifyDateDialog" || false}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="xs"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {"만료일 변경"}
        </DialogTitleClose>
        <DialogContent>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormInputDate
                name="endDate"
                control={control}
                label={"만료일"}
                onChangePeriodValue={handleChangePeriod}
                // value={getValues("endDate")}
                value={selectedRowEndDate}
                setValue={setValue}
                getValues={getValues}
                // onEdit={checkEdit}
              />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent={"flex-start"} alignItems={"center"} sx={{ display: 'none' }}>
              <FormInputCheckbox
                name="validYN"
                control={control}
                setValue={setValue}
                onChangeCheckValue={handleChangeCheckButton}
              />
              <Typography>{"만료일 없음"}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>{"변경"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default G04docuModifyDateDialog;
