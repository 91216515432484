import {
    PictureAsPdf,
    Search
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress
} from '@mui/material';
import {
    randomArrayItem,
    randomCreatedDate,
    randomId,
    randomTraderName,
} from '@mui/x-data-grid-generator';
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, koKR, useGridApiRef } from '@mui/x-data-grid-pro';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import * as pdfjsLib from 'pdfjs-dist';
import React, { useCallback, useEffect, useState } from "react";
import { FilePond, registerPlugin } from 'react-filepond';
import { useForm } from "react-hook-form";
import { RiFileExcel2Line } from 'react-icons/ri'; // Excel 아이콘
import { CustomNoRowsOverlay, } from "../datagrid";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/ko'; // dayjs 한글 로케일 import
import debounce from 'lodash/debounce';
import * as XLSX from 'xlsx'; // 엑셀 처리를 위한 라이브러리 추가
import { fileServerUrl, uploadFilePath } from '../../config';
import { getGridHeight } from '../../constants/gridHeights';
import { AlertDialog, DialogTitleClose, PaperComponent } from "../dialog";
import { FormInputDate, FormInputText } from "../form";

import * as g04docuFileActions from "../../store/g04docuFile";
import * as g04docuFileSupplyActions from "../../store/g04docuFileSupply";


registerPlugin(FilePondPluginFileValidateType);

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};
const initialRows = [
  {
    id: randomId(),
    checked: true,
    startDate: randomCreatedDate(),
    endDate: randomCreatedDate(),
    gclientName: randomTraderName(),
    site: randomTraderName(),
    supplyName: randomRole(),
    supplySize: 0,
    supplyUnit: 0,
    comments: 'test0',
  },
  {
    id: randomId(),
    checked: false,
    startDate: randomCreatedDate(),
    endDate: randomCreatedDate(),
    gclientName: randomTraderName(),
    site: randomTraderName(),
    supplyName: randomRole(),
    supplySize: 0,
    supplyUnit: 0,
    comments: 'test1',
  },
  
];

const processExcelFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { 
          header: 1,
          raw: false,
          dateNF: 'yyyy-mm'
        });

        // 헤더 제거 (첫 번째 행)
        const [headers, ...rows] = jsonData;

        // 데이터 매핑 및 변환
        const mappedData = rows.map((row, index) => ({
          id: randomId(),
          checked: true,
          startDate: row[0] ? new Date(row[0]) : null,
          endDate: row[1] ? new Date(row[1]) : null,
          gclientName: row[2] || '',
          site: row[3] || '',
          supplyName: row[4] || '',
          supplySize: Number(row[5]) || 0,
          // supplyUnit: Number(row[6]) || 0,
          supplyUnit: row[6] || 0,
          comments: row[7] || '',
          isNew: true
        }));

        resolve({ data: mappedData });
      } catch (error) {
        reject(new Error('엑셀 파일 처리 중 오류가 발생했습니다.'));
      }
    };

    reader.onerror = () => {
      reject(new Error('파일 읽기 오류가 발생했습니다.'));
    };

    reader.readAsArrayBuffer(file.file);
  });
};



// 아래 form components의 name과 연계
const defaultValues = {
  startDate: null,
  endDate: null,
  checked: true,
  gclientName: '',
  site: '',
  supplyName: '',
  supplySize: 0,
  // supplyUnit: 0,
  supplyUnit: '',
  comments: '',
};

// 현재 화면에 맞는 Grid 높이 계산
const GRID_HEIGHT = getGridHeight('TWO_LINE_BUTTON_LAYER', 0); 

// let revertButton;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const G04docuFileDialogSupplyRecord = ({
  openSupplyRecord,
  setOpenSupplyRecord,
  selectedRow,
  selectedGClientId,
  selectedGClient,
  refresh,
}) => {
  const { g04GeneralDocuId, title, documentType, documentPath/*, startDate, endDate*/ } = selectedRow;
  
  const [loaded, setLoaded] = useState(true);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentPath, setUploadedDocumentPath] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [rows, setRows] = useState([]/*initialRows*/);
  const [rowCount, setRowCount] = useState(0);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});
  const [editRowData, setEditRowData] = useState({});
  const [editedRows, setEditedRows] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();

  const handleDialogClose = () => {    
    // rowModesModel 초기화
    setRowModesModel({});

    // 신규 행 제거
    setRows(prevRows => prevRows.filter(row => !row.isNew));

    setOpenSupplyRecord(false);
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    fetchSupplyList();
  }

  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)

  useEffect(
    () => {
      if (openSupplyRecord) {
        initDialog();
      }
    }, [openSupplyRecord]
  );

  // debounce 검색 함수
  const debouncedSearch = useCallback(
    debounce(() => {
      handleClickSearch();
    }, 500),  // 500ms 딜레이
    [] // 컴포넌트 마운트 시 한 번만 생성
  );

  // 납품실적 목록 조회 함수
  const fetchSupplyList = async () => {
    try {
      // 검색 파라미터 설정
      const params = {};

      // API 호출
      const result = await g04docuFileSupplyActions.selectListDirect(selectedGClientId, params);
      console.log('result:', result);
      // 결과 데이터가 있는 경우
      if (result && result.list) {
        // 데이터 형식 변환 및 rows 상태 업데이트
        const formattedRows = result.list.map(item => {
          // 문자열이면 콤마 제거 후 숫자로 변환, 숫자면 그대로 사용
          const supplySize = typeof item.supplySize === 'string' 
          ? Number(item.supplySize.replace(/,/g, '')) 
          : Number(item.supplySize || 0);

          // const supplyUnit = typeof item.supplyUnit === 'string'
          // ? Number(item.supplyUnit.replace(/,/g, ''))
          // : Number(item.supplyUnit || 0);
          
          return {
            ...item,
            startDate: item.startDate ? new Date(item.startDate) : null,
            endDate: item.endDate ? new Date(item.endDate) : null,
            supplySize: supplySize,
            // supplyUnit: supplyUnit,
            isNew: false
          };
        }) ;
        
        setRows(formattedRows);
        setRowCount(result.count || 0);
        console.log('fetchSupplyList:', formattedRows, rows.length);
      } else {
        setRows([]);
        setRowCount(0);
      }

    } catch (error) {
      console.error('납품실적 목록 조회 실패:', error);
      setAlertInfo({
        open: true,
        titleAlert: '조회 실패',
        messageAlert: '납품실적 목록 조회 중 오류가 발생했습니다.',
      });
      
    }
  };

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = useCallback((value, name) => {
    console.log(name, value)

    if (!value) {
      setValue(name, null);
      return;
    }

    try {
      // dayjs 객체로 변환
      const date = dayjs(value);
      
      if (date.isValid()) {
        // 날짜를 그대로 유지하면서 Date 객체로 변환
        setValue(name, date.toDate());
        
        // 디버깅을 위한 로그
        console.log('저장된 날짜:', {
          name,
          inputValue: value,
          dateObject: date.toDate(),
          formatted: date.format('YYYY-MM')
        });
      } else {
        setValue(name, null);
        console.warn('Invalid date:', value);
      }
    } catch (error) {
      console.error('Date parsing error:', error);
      setValue(name, null);
    }
  
    debouncedSearch();
  }, [setValue, debouncedSearch]);

  // 텍스트 검색 필드 변경 핸들러
  const handleSearchChange = useCallback((name, value) => {
    setValue(name, value);
    debouncedSearch();
  }, [setValue, debouncedSearch]);

  // 컴포넌트 언마운트 시 debounce 취소
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // 검색 버튼 클릭 핸들러
  const handleClickSearch = async () => {
    try {
      // 현재 검색 조건 가져오기
      const searchParams = {
        startDate: getValues("startDate")  
          ? dayjs(getValues("startDate") ).startOf('month').format('YYYY-MM-DD HH:mm:ss')
          : null,
        endDate: getValues("endDate") 
          ? dayjs(getValues("endDate")).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          : null,
        searchGClient: getValues("searchGClient") || null,
        searchSite: getValues("searchSite") || null,
        searchSupplyName: getValues("searchSupplyName") || null
      };

      console.log('Search params:', searchParams);

      // API 호출
      const response = await g04docuFileSupplyActions.selectListDirect(selectedGClientId, searchParams);
      
      if (response && response.list) {
        // 데이터 형식 변환
        const formattedRows = response.list.map(item => {
          // 문자열이면 콤마 제거 후 숫자로 변환, 숫자면 그대로 사용
          const supplySize = typeof item.supplySize === 'string' 
            ? Number(item.supplySize.replace(/,/g, '')) 
            : Number(item.supplySize || 0);

          // const supplyUnit = typeof item.supplyUnit === 'string'
          //   ? Number(item.supplyUnit.replace(/,/g, ''))
          //   : Number(item.supplyUnit || 0);
            
          return {
            id: item.seq,
            seq: item.seq,
            checked: item.checked,
            startDate: item.startDate ? new Date(item.startDate) : null,
            endDate: item.endDate ? new Date(item.endDate) : null,
            gclientName: item.gclientName || '',
            site: item.site || '',
            supplyName: item.supplyName || '',
            supplySize: supplySize,
            // supplyUnit: supplyUnit,
            supplyUnit: item.supplyUnit || '',
            comments: item.comments || '',
            isNew: false
          };
        });
        
        console.log('Formatted search results:', formattedRows);
        setRows(formattedRows);
//        setRowCount(response.count || 0);
      } else {
        setRows([]);
//        setRowCount(0);
      }

    } catch (error) {
      console.error('Search error:', error);
      setAlertInfo({
        open: true,
        titleAlert: '검색 실패',
        messageAlert: '납품실적 검색 중 오류가 발생했습니다.',
        type: 'error'
      });
    }
  };

  // 납품실적 PDF 파일 생성
  const handleGeneratePdf = async () => {
    setLoading(true);
    
    try {
      
      // rows 유효성 검사
      if (!rows) {
        throw new Error('납품실적 데이터가 없습니다.');
      }
      
      // API 호출
      const response = await g04docuFileSupplyActions.generatePdf(selectedGClientId);
      console.log('response:', response);
      if (response?.documentPath) {
        // 파일 경로 변환
        const path = response.documentPath.replace(uploadFilePath, fileServerUrl);
        
        // 캐시 방지를 위한 랜덤 쿼리 파라미터 추가
        const randNumber = Math.floor(Math.random() * 99);
        
        // 새 창에서 PDF 열기
        window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');

        // 목록 새로고침
        if (refresh) {
          console.log('Refreshing list...'); // 디버깅용
          refresh(selectedGClientId);
        }
      } else {
        throw new Error('PDF 생성에 실패했습니다.');
      }
  
    } catch (error) {
      console.error('PDF generation error:', error);
      console.log('error:', error.serverResponse.code);
      // 에러 코드가 10013인 경우 특별 처리
      if (error?.serverResponse?.code === 10013) {
        setAlertInfo({
          open: true,
          titleAlert: '인감 미등록',
          messageAlert: (
            <span>
              (<a href="/profile" style={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>여기를</a>) 클릭하면 내정보 화면으로 이동됩니다.<br/>
              내정보 &gt; <span style={{ fontWeight: 'bold' }}>날인정보</span>로 이동하여 인감을 먼저 등록하세요.
            </span>
          ),
          type: 'warning',
          handleConfirm: () => {
            window.location.href = '/profile';
          }
        });
      } else {
        setAlertInfo({
          open: true,
          titleAlert: 'PDF 생성 실패',
          messageAlert: 'PDF 생성 중 오류가 발생했습니다.',
          type: 'error'
        });
      }      
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // 필드 검증 함수 추가
  const validateRow = (row) => {
    const errors = [];

    if (!row.gclientName?.trim()) {
      errors.push('거래처명은 필수 입력 항목입니다.\n');
    }

    if (!row.site?.trim()) {
      errors.push('현장은 필수 입력 항목입니다.\n');
    }

    if (!row.supplyName?.trim()) {
      errors.push('내역은 필수 입력 항목입니다.\n');
    }

    if (!row.startDate) {
      errors.push('시작월은 필수 입력 항목입니다.\n');
    }

    return errors;
  };

  // 납품명세서 개별 등록
  const handleRowSaveAction = (row) => async () => {
    console.log('handleRowSaveAction - :', row);
    try {
      // 필드 검증
      const validationErrors = validateRow(row);
      if (validationErrors.length > 0) {
        setAlertInfo({
          open: true,
          titleAlert: "입력항목 오류",
          messageAlert: <div style={{ whiteSpace: 'pre-line' }}>{validationErrors.join('')}</div>,
          type: 'error'
        });
        return; // 검증 실패 시 저장 중단
      }

      // API 호출을 위한 데이터 준비
      const params = {
        startDate: row.startDate 
          ? dayjs(row.startDate).startOf('month').format('YYYY-MM-DD HH:mm:ss')
          : null,
        endDate: row.endDate 
          ? dayjs(row.endDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          : null,
        gclientName: row.gclientName || selectedGClient?.gclientName || '',
        site: row.site || '',
        supplyName: row.supplyName || '',
        supplySize: Number(row.supplySize || 0),
        // supplyUnit: Number(row.supplyUnit || 0),
        supplyUnit: row.supplyUnit || '',
        comments: row.comments || '',
        checked: row.checked ?? true
      };

      console.log('API params:', params);

      // isNew 플래그로 신규/수정 구분
      if (row.isNew) {
        // 신규
        const response = await g04docuFileSupplyActions.createDirect(selectedGClientId, params);
        console.log('data:', response.gsupplyResult);
        const data = response.gsupplyResult;
        // 새로운 행 데이터로 업데이트
        const updatedRow = {
          ...row,
          id: data.seq,
          seq: data.seq,
          isNew: false,
          startDate: dayjs(data.startDate).toDate(),
          endDate: dayjs(data.endDate).toDate(),
          gclientName: data.gclientName,
          site: data.site,
          supplyName: data.supplyName,
          supplySize: data.supplySize,
          supplyUnit: data.supplyUnit,
          comments: data.comments,
          checked: data.checked
        };

        // 행 모드를 먼저 업데이트
        setRowModesModel(prevModel => {
          const { [row.id]: _, ...rest } = prevModel;
          return {
            ...rest,
            [data.seq]: { mode: GridRowModes.View }
          };
        });

        // 그 다음 행 데이터 업데이트
        setRows(prevRows => prevRows.map(prevRow => 
          prevRow.id === row.id ? updatedRow : prevRow
        ));

      } else {
        // 수정
        await g04docuFileSupplyActions.updateDirect(row.seq, params);
      }
  
      setRowModesModel(prevModel => ({
        ...prevModel,
        [row.id]: { mode: GridRowModes.View }
      }));

      // 성공 메시지 표시 (선택)
      // setAlertInfo({
      //   open: true,
      //   titleAlert: '저장 완료',
      //   messageAlert: `납품실적이 성공적으로 ${row.isNew ? '저장' : '수정'}되었습니다.`,
      //   type: 'success'
      // });
    } catch (error) {
      console.error('납품실적 저장 실패:', error);
      setAlertInfo({
        open: true,
        titleAlert: "안내", 
        messageAlert: "납품실적 저장 중 오류가 발생했습니다.",
      });
    }
  };

  // 셀 편집 시작 시 호출되는 핸들러
  const handleCellEditStart = (params) => {
    console.log('Cell edit start:', params);
  };

  // DataGrid 이벤트 핸들러 추가
  const handleCellEditCommit = (params) => {
    console.log('Cell edit committed:', params);
    const updatedRows = rows.map(row => {
      if (row.id === params.id) {
        return { ...row, [params.field]: params.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  // 셀 값이 변경될 때 호출되는 핸들러
  const handleCellEditChange = (params) => {
    console.log('Cell edit change:', params);
    setEditedRows(prev => ({
      ...prev,
      [params.id]: {
        ...(prev[params.id] || {}),
        [params.field]: params.value
      }
    }));
  };

  // apiRef 생성
  const apiRef = useGridApiRef();
  // 행 편집이 중지될 때 호출되는 핸들러
  const handleRowEditStop = (params, event) => {
    console.log('Row edit stop:', params);
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;

      // focus out된 행의 ID
      const { id } = params;
      
      // apiRef를 통해 행 데이터 가져오기
      const row = apiRef.current.getRow(id);
      console.log('Current row data:', row);

      // editedRows 상태 업데이트
      setEditedRows(prev => ({
        ...prev,
        [id]: row
      }));
    }
  };

  // 행 삭제 핸들러
  const handleDeleteAction = (id) => async () => {
    try {
      // 삭제할 행 찾기
      const rowToDelete = rows.find(row => row.id === id);
      
      if (!rowToDelete) {
        console.warn('Row not found:', id);
        return;
      }
  
      // 신규 행인 경우 바로 제거
      if (rowToDelete.isNew) {
        setRows(rows.filter(row => row.id !== id));
        return;
      }
  
      // 기존 데이터인 경우 확인 다이얼로그 표시
      setAlertInfo({
        open: true,
        titleAlert: '삭제 확인',
        messageAlert: '선택한 납품실적을 삭제하시겠습니까?',
        type: 'confirm',
        handleConfirm: async () => {
          try {
            // API 호출하여 삭제
            await g04docuFileSupplyActions.removeDirect(rowToDelete.seq);
  
            // 성공 시 화면에서 제거
            setRows(rows.filter(row => row.id !== id));
            
            setAlertInfo({
              open: true,
              titleAlert: '삭제 완료',
              messageAlert: '납품실적이 삭제되었습니다.',
              type: 'success'
            });
  
            // 필요한 경우 목록 새로고침
            if (refresh) {
              refresh(selectedGClientId);
            }
  
          } catch (error) {
            console.error('Delete error:', error);
            setAlertInfo({
              open: true,
              titleAlert: '삭제 실패',
              messageAlert: '납품실적 삭제 중 오류가 발생했습니다.',
              type: 'error'
            });
          }
        }
      });
    } catch (error) {
      console.error('Delete action error:', error);
      setAlertInfo({
        open: true,
        titleAlert: '오류',
        messageAlert: '작업 처리 중 오류가 발생했습니다.',
        type: 'error'
      });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  // 행 업데이트 처리 핸들러
  const processRowUpdate = async (newRow) => {
    console.log('processRowUpdate - newRow:', newRow);
    
    try {
      // API 호출을 위한 데이터 준비
      const params = {
        startDate: newRow.startDate 
          ? dayjs(newRow.startDate).startOf('month').format('YYYY-MM-DD HH:mm:ss')
          : null,
        endDate: newRow.endDate 
          ? dayjs(newRow.endDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          : null,
        gclientName: newRow.gclientName || '',
        site: newRow.site || '',
        supplyName: newRow.supplyName || '',
        supplySize: Number(newRow.supplySize || 0),
        supplyUnit: newRow.supplyUnit || '',
        comments: newRow.comments || '',
        checked: newRow.checked ?? true
      };
  
      let updatedRow;
      if (newRow.isNew) {
        // 신규 행인 경우 create API 호출
        const response = await g04docuFileSupplyActions.createDirect(selectedGClientId, params);
        const data = response.gsupplyResult;
        
        // API 응답으로 받은 데이터로 행 업데이트
        updatedRow = {
          ...newRow,
          id: data.seq,
          seq: data.seq,
          isNew: false,
          startDate: dayjs(data.startDate).toDate(),
          endDate: dayjs(data.endDate).toDate(),
          gclientName: data.gclientName,
          site: data.site,
          supplyName: data.supplyName,
          supplySize: data.supplySize,
          supplyUnit: data.supplyUnit,
          comments: data.comments,
          checked: data.checked
        };
      } else {
        // 기존 행인 경우 update API 호출
        await g04docuFileSupplyActions.updateDirect(newRow.seq, params);
        updatedRow = { ...newRow, isNew: false };
      }
      
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      setEditedRows(prev => ({
        ...prev,
        [newRow.id]: updatedRow
      }));
      
      return updatedRow;
    } catch (error) {
      console.error('Row update error:', error);
      throw new Error('납품실적 저장 중 오류가 발생했습니다.');
    }
    
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    console.log('Row modes model change:', newRowModesModel);
    setRowModesModel(newRowModesModel);
  };
  
  const MonthYearPicker = (props) => {
    //console.log(props);
    const currentValue = props.row.endDate 
    ? dayjs(props.row.endDate)
    : null;

    //console.log(currentValue);    
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <DatePicker
          views={['year', 'month']}
          openTo="month" 
          defaultView="month"
          value={currentValue}
          defaultValue={currentValue}
          onChange={(newValue) => {
            props.api.setEditCellValue({
              id: props.id,
              field: props.field,
              value: newValue ? newValue.toDate() : null
            });
          }}
          format="YYYY-MM"
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              sx: { 
                '& .MuiInputBase-input': { 
                  padding: '4px 8px',
                  fontSize: '0.875rem'
                },
                '& .MuiOutlinedInput-root': {
                  height: '30px',
                  '& fieldset': {
                    border: 'none'
                  }
                },
                '& .MuiInputAdornment-root': {
                  '& .MuiSvgIcon-root': {
                    fontSize: '1rem'
                  }
                }
              }
            }
          }}
        />
      </LocalizationProvider>
    );
  };

  // 그리드 상단 메뉴 버튼 처리
  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [previewData, setPreviewData] = useState(null);  // 미리보기 데이터 상태 추가
    const [isExcelUploaded, setIsExcelUploaded] = useState(false);  // 상태 추가
  
    // 납품실적 개별 등록
    const handleAddRow = () => {
      const id = randomId();
      const newRow = {
        id,
        checked: true,
        startDate: new Date(), // 현재 날짜로 초기화
        endDate: new Date(),   // 현재 날짜로 초기화
        gclientName: '', 
        site: '',
        supplyName: '',
        supplySize: 0,
        // supplyUnit: 0,
        supplyUnit: '',
        comments: '',
        isNew: true,          // 신규 행 표시
      };

      console.log('newRow id => ', id);

      setRows((oldRows) => [...oldRows, newRow]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'startDate' },
      }));
    };
  
    // 엑셀 다운로드 로직
    const handleExcelDownload = () => {
      try {
        // 그리드 데이터 체크
        if (!rows || rows.length === 0) {
          setAlertInfo({
            open: true,
            titleAlert: '안내',
            messageAlert: '다운로드할 납품실적 데이터가 없습니다.',
          });
          return;
        }
        
        // 워크북 생성
        const wb = XLSX.utils.book_new();
    
        // 현재 그리드의 데이터를 엑셀 형식으로 변환
        const excelData = rows.map(row => ({
          '시작월': row.startDate ? dayjs(row.startDate).format('YYYY-MM') : '',
          '종료월': row.endDate ? dayjs(row.endDate).format('YYYY-MM') : '',
          '거래선': row.gclientName || '',
          '현장명': row.site || '',
          '내역': row.supplyName || '',
          '수량': row.supplySize || 0,
          // '단위': row.supplyUnit || 0,
          '단위': row.supplyUnit || '',
          '비고': row.comments || ''
        }));
    
        // 워크시트 생성
        const ws = XLSX.utils.json_to_sheet(excelData);
    
        // 열 너비 설정
        const colWidths = [
          { wch: 12 },  // 시작월
          { wch: 12 },  // 종료월
          { wch: 20 },  // 거래선
          { wch: 20 },  // 현장명
          { wch: 15 },  // 내역
          { wch: 10 },  // 수량
          { wch: 12 },  // 단위
          { wch: 25 },  // 비고
        ];
        ws['!cols'] = colWidths;
    
        // 워크북에 워크시트 추가
        XLSX.utils.book_append_sheet(wb, ws, '납품실적');
    
        // 현재 날짜를 파일명에 추가
        const today = dayjs().format('YYYYMMDD');
        const fileName = `납품실적_${today}.xlsx`;
    
        // 파일 다운로드
        XLSX.writeFile(wb, fileName);
      } catch (error) {
        console.error('Excel download error:', error);
        setAlertInfo({
          open: true,
          titleAlert: "엑셀 오류", 
          messageAlert: "엑셀 파일 다운로드 중 오류가 발생했습니다.",
        });
      }
    };
  
    // 엑셀서식 다운로드
    const handleTemplateDownload = () => {
      // 엑셀 워크북 생성
      const wb = XLSX.utils.book_new();
      
      // 헤더 정의
      const headers = [
        '시작월',
        '종료월',
        '거래선',
        '현장명',
        '내역',
        '수량',
        '단위',
        '비고'
      ];
  
      // 샘플 데이터 생성 (선택사항)
      const sampleData = [
        ['2024-01', '2024-12', '샘플 거래선명', '샘플 현장명', '샘플 내역', '100', '10000', '샘플 비고']
      ];
  
      // 워크시트 데이터 생성 (헤더 + 샘플 데이터)
      const wsData = [headers, ...sampleData];
  
      // 워크시트 생성
      const ws = XLSX.utils.aoa_to_sheet(wsData);
  
      // 열 너비 설정
      const colWidths = [
        { wch: 12 },  // 시작월
        { wch: 12 },  // 종료월
        { wch: 20 },  // 거래선
        { wch: 20 },  // 현장명
        { wch: 15 },  // 내역
        { wch: 10 },  // 수량
        { wch: 12 },  // 단위
        { wch: 25 },  // 비고
      ];
      ws['!cols'] = colWidths;
  
      // 워크북에 워크시트 추가
      XLSX.utils.book_append_sheet(wb, ws, '납품실적');
  
      // 파일 다운로드
      XLSX.writeFile(wb, '납품실적_서식.xlsx');
      
    };
  
    // 엑셀 업로드 로직
    const handleExcelUpload = () => {
      setUploadedFiles([]); // 기존 파일 초기화
      setOpenUploadDialog(true);
      //handleExcelMenuClose();
    };
  
    const handleUploadClose = () => {
      setOpenUploadDialog(false);
      setUploadedFiles([]); // 다이얼로그 닫을 때도 초기화
      setPreviewData(null);  // 미리보기 데이터 초기화
    };
  
    const handleProcessFile = async (error, file) => {
      if (error) {
        console.error('File upload error:', error);
        setIsExcelUploaded(false);  // 실패 시 비활성화
        return;
      }
    
      try {
        const result = await processExcelFile(file);
        setRows((prevRows) => [...prevRows, ...result.data]);
        handleUploadClose();
        setIsExcelUploaded(true);  // 성공 시 활성화
        // 성공 메시지 표시
        //alert('엑셀 파일이 성공적으로 처리되었습니다.');
      } catch (err) {
        console.error('Excel processing error:', err);
        setIsExcelUploaded(false);  // 실패 시 비활성화
        // 에러 메시지 표시
        alert(err.message);
      }
    };

    // 납품실적 엑셀 일괄 저장
    const handleCreateBulkDirect = async () => {
      // 미리보기 데이터가 없는 경우 체크
      if (!previewData || previewData.length === 0) {
        setAlertInfo({
          open: true,
          titleAlert: "저장 오류", 
          messageAlert: "저장할 데이터가 없습니다.",
        });
        return;
      }
    
      // 사용자 확인을 AlertDialog로 변경
      setAlertInfo({
        open: true,
        titleAlert: "저장 확인",
        messageAlert: "납품실적을 저장하시겠습니까?",
        type: 'confirm',
        handleConfirm: async () => {
          try {
            // setLoading(true);

            // 데이터 형식 변환
            const formattedData = previewData.map(row => ({
              startDate: row.startDate 
                ? dayjs(row.startDate).startOf('month').format('YYYY-MM-DD HH:mm:ss')
                : null,
              endDate: row.endDate 
                ? dayjs(row.endDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
                : null,
              gclientName: row.gclientName,
              site: row.site,
              supplyName: row.supplyName,
              supplySize: row.supplySize,
              supplyUnit: row.supplyUnit,
              comments: row.comments,
              checked: row.checked || true
            }));

            console.log('API params:', formattedData);

            // API 호출
            const response = await g04docuFileSupplyActions.createBulkDirect(
              selectedGClientId, 
              formattedData
            );

            // 성공 처리
            // setAlertInfo({
            //   open: true,
            //   titleAlert: "저장 완료", 
            //   messageAlert: "납품실적이 성공적으로 저장되었습니다.",
            // });

            // 다이얼로그 닫기
            handleUploadClose();

            // 다이얼로그 내 그리드 데이터 갱신
            await fetchSupplyList();

          } catch (error) {
            console.error('납품실적 저장 실패:', error);
            setAlertInfo({
              open: true,
              titleAlert: '저장 실패',
              messageAlert: '납품실적 저장 중 오류가 발생했습니다.',
            });
          } finally {
            // setLoading(false);
          }
        }
      });
      
    };
  
    return (
      <>
        <GridToolbarContainer>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleAddRow}>
            납품실적 개별추가
          </Button>
          <Button 
            color="primary" 
            startIcon={<RiFileExcel2Line />} 
            onClick={handleExcelUpload}
          >
            납품실적 엑셀추가
          </Button>
          <div style={{ marginLeft: 'auto', display: 'flex', gap: '8px' }}>
            <Button 
              color="primary" 
              startIcon={<DownloadIcon />} 
              onClick={handleExcelDownload}
            >
              엑셀 내보내기
            </Button>
          </div>
        </GridToolbarContainer>
  
        {/* 엑셀 업로드 다이얼로그 */}
        <Dialog
          open={openUploadDialog}
          onClose={handleUploadClose}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            엑셀 가져오기
            <Button
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={handleTemplateDownload}
              sx={{ position: 'absolute', right: 16 }}
            >
              엑셀서식 다운로드
            </Button>
          </DialogTitle>
          <DialogContent> 
            <Box sx={{ mt: 2 }}>
            {!previewData ? (
              <FilePond
                files={uploadedFiles}
                onupdatefiles={setUploadedFiles}
                allowMultiple={false}
                maxFiles={1}
                acceptedFileTypes={[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel'
                ]}
                labelIdle='<div style="text-align: left;">1. 엑셀서식을 다운로드하여 엑셀파일에 납품실적을 작성 하세요.</div><div style="text-align: left;">2. 작성이 완료된 엑셀파일을 여기에 놓거나 <span class="filepond--label-action">파일찾기</span>를 클릭하여 엑셀파일을 선택하세요.</div>'
                //onprocessfile={handleProcessFile}
                server={{
                  process: (fieldName, file, metadata, load, error, progress, abort) => {
                    // 파일을 직접 처리
                    processExcelFile({ file: file })
                      .then(result => {
                        //setRows((prevRows) => [...prevRows, ...result.data]);
                        setPreviewData(result.data);
                        load(file.name);
                        setIsExcelUploaded(true);  // 성공 시 활성화
                        //handleUploadClose();
                        //alert('엑셀 파일이 성공적으로 처리되었습니다.');
                      })
                      .catch(err => {
                        error(err.message);
                        alert(err.message);
                        setIsExcelUploaded(false);  // 실패 시 비활성화
                      });
  
                    // 중단 함수 반환
                    return {
                      abort: () => {
                        abort();
                      }
                    };
                  }
                }}
                credits={false}
              />
            ) : (
              <Box sx={{ height: 400 }}>
                <DataGridPro
                  rows={previewData}
                  columns={excelColumns}
                  density="compact"
                  disableSelectionOnClick
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                />
              </Box>
            )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleCreateBulkDirect}
              disabled={!isExcelUploaded}  // 조건부 비활성화
            >
              납품실적 저장
            </Button>
            <Button onClick={handleUploadClose}>닫기</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } // end of EditToolbar

  // 체크박스 상태 업데이트 핸들러
  const handleCheckboxChange = async (params, event) => {
    try {
      const newChecked = event.target.checked;
      
      console.log('seq:', params.row.seq, 'newChecked:', newChecked);

      // API 호출
      await g04docuFileSupplyActions.updateCheckedDirect(params.row.seq, newChecked);
      
      // rows 상태 업데이트
      setRows(prevRows => 
        prevRows.map(row => 
          row.id === params.id ? { ...row, checked: newChecked } : row
        )
      );

      // UI 업데이트 (선택사항)
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: newChecked
      }, event);

    } catch (error) {
      console.error('Checkbox update error:', error);
      
      // 에러 메시지
      setAlertInfo({
        open: true,
        titleAlert: '저장 실패',
        messageAlert: '선택 상태 저장 중 오류가 발생했습니다.',
        type: 'error'
      });
      
      // 체크박스 상태를 이전 상태로 되돌림
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: !event.target.checked
      }, event);
    }
  };
  
  // 체크박스 컬럼 정의
  const checkedColumn = { 
    field: 'checked', 
    headerName: '납품실적 포함', 
    width: 100, 
    type: 'boolean', 
    editable: true, 
    renderCell: (params) => (
      <Checkbox
        checked={params.value || false}
        onChange={(event) => handleCheckboxChange(params, event)}
        size="small"
      />
    )
  };

  // 기본 데이터 컬럼 정의
  const excelColumns = [
    { 
      field: 'startDate', 
      headerName: '시작일', 
      type: 'date',
      width: 120,
      editable: true,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM') : '';
      },
      renderEditCell: (params) => <MonthYearPicker {...params} />
    },
    { 
      field: 'endDate', 
      headerName: '종료일', 
      type: 'date',
      width: 120,
      editable: true,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM') : '';
      },
      renderEditCell: (params) => <MonthYearPicker {...params} />
    },
    { field: 'gclientName', headerName: '거래선', width: 200, editable: true },
    { field: 'site', headerName: '현장명', width: 200, editable: true },
    { field: 'supplyName', headerName: '내역', width: 150, editable: true }, 
    { field: 'supplySize', headerName: '수량', width: 120, type: 'number', editable: true,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return params.value.toLocaleString('ko-KR');
      },
      renderEditCell: (params) => {
        return (
          <input
            type="text"
            value={params.value ? params.value.toLocaleString('ko-KR') : ''}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d,]/g, '');
              const numValue = Number(value.replace(/,/g, ''));
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: numValue
              });
            }}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              padding: '0 8px',
              textAlign: 'right',
              fontSize: '0.875rem'
            }}
          />
        );
      }
     },
    { 
      field: 'supplyUnit', 
      headerName: '단위', 
      width: 120, 
      // type: 'number',
      editable: true,
      // valueFormatter: (params) => {
      //   if (params.value == null) return '';
      //   return params.value.toLocaleString('ko-KR');
      // },
      // renderEditCell: (params) => {
      //   return (
      //     <input
      //       type="text"
      //       value={params.value ? params.value.toLocaleString('ko-KR') : ''}
      //       onChange={(e) => {
      //         const value = e.target.value.replace(/[^\d,]/g, '');
      //         const numValue = Number(value.replace(/,/g, ''));
      //         params.api.setEditCellValue({
      //           id: params.id,
      //           field: params.field,
      //           value: numValue
      //         });
      //       }}
      //       style={{
      //         width: '100%',
      //         height: '100%',
      //         border: 'none',
      //         padding: '0 8px',
      //         textAlign: 'right',
      //         fontSize: '0.875rem'
      //       }}
      //     />
      //   );
      // }
    },
    { field: 'comments', headerName: '비고', width: 220, editable: true }
  ];

  // actions 컬럼 정의
  const actionsColumn = {
    field: 'actions',
    type: 'actions',
    headerName: '기능',
    width: 80,
    cellClassName: 'actions',
    getActions: ({ id, row }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => {
              const updatedRow = apiRef.current.getRowWithUpdatedValues(id);
              //console.log('Updated row with edited values:', updatedRow);
              handleRowSaveAction(updatedRow)();
            }}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleDeleteAction(id)}
          color="inherit"
        />,
      ];
    }
  };

  const columns = [checkedColumn, ...excelColumns, actionsColumn];

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={openSupplyRecord}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {`${title} 첨부`}
        </DialogTitleClose>
        <DialogContent>
          {/* <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={1.5}>
                  <FormInputDate
                    name="startDate"
                    control={control}
                    label={"시작월"}
                    onChangePeriodValue={(value) => handleChangePeriod(value, "startDate")}
                    value={getValues("startDate")}  
                    setValue={setValue}
                    inputHeight={38}
                    views={['year', 'month']}  
                    format="yyyy-MM"          
                    openTo="month"            
                    defaultView="month" 
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <FormInputDate
                    name="endDate"
                    control={control}
                    label={"종료월"}
                    onChangePeriodValue={(value) => handleChangePeriod(value, "endDate")}  
                    value={getValues("endDate")} 
                    setValue={setValue}
                    inputHeight={38}
                    views={['year', 'month']}  
                    format="yyyy-MM"          
                    openTo="month"            
                    defaultView="month"      
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <FormInputText
                    name="searchGClient"
                    control={control}
                    setValue={setValue}
                    label={"거래선"}
                    onChange={(e) => handleSearchChange('searchGClient', e.target.value)}
                    inputProps={{             
                      autoComplete: 'off'     // 자동완성 비활성화
                    }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <FormInputText
                    name="searchSite"
                    control={control}
                    setValue={setValue}
                    label={"현장명"}
                    onChange={(e) => handleSearchChange('searchSite', e.target.value)}
                    inputProps={{             
                      autoComplete: 'off'     // 자동완성 비활성화
                    }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <FormInputText
                    name="searchSupplyName"
                    control={control}
                    setValue={setValue}
                    label={"내역"}
                    onChange={(e) => handleSearchChange('searchSupplyName', e.target.value)}
                    inputProps={{             
                      autoComplete: 'off'     // 자동완성 비활성화
                    }}
                  />
                </Grid>
                <Grid item xs={4.5} container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    startIcon={<Search />}
                    onClick={handleClickSearch}
                    sx={{ mr: 1 }}
                  >
                    {"검색"}
                  </Button>
                 
                  <LoadingButton
                    variant="contained"
                    startIcon={<PictureAsPdf />}
                    loading={loading}
                    loadingPosition="start"
                    onClick={handleGeneratePdf}
                    disabled={rows.filter(row => row.checked).length === 0}
                    sx={{ mr: 1 }}
                  >
                    {"납품실적 생성하기"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <div style={{ height: GRID_HEIGHT.FULL, width: '100%', marginTop: '20px' }}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                initialState={{ pinnedColumns: { right: ['actions'] } }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                  toolbar: EditToolbar 
                }}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                columnHeaderHeight={38}
                rowHeight={34}
                getRowId={(row) => row.id || row.seq}
                apiRef={apiRef}
                rows={rows}
                rowCount={rowCount}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                onCellEditStart={handleCellEditStart}
                onCellEditChange={handleCellEditChange}
                onCellEditCommit={handleCellEditCommit}
                processRowUpdate={processRowUpdate}
                loading={!loaded}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
              />
            </div>
          </Box>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </LocalizationProvider>
    </>
  );
};

export default G04docuFileDialogSupplyRecord;
