import React, { useState, useEffect } from "react";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import * as userActions from "../../store/user";
import * as mailActions from "../../store/mail";

const GClientMailTestUserDialog = ({
  open,
  setOpen,
  selectedRow,
}) => {
  const [loadedUser, setLoadedUser] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [users, setUsers] = useState([]);
  const [pageSizeUser, setPageSizeUser] = useState(100);

  const handleDialogClose = () => {
    setOpen(false);
    
    initDialog();
  };

  const initDialog = () => {
  
  }
  
  // 데이터 관리
  const selectAllByGClientDirect = (gclientId) => userActions.selectAllByGClientDirect(gclientId)
  const sendMailTestDrect = ({ mailSettings }) => mailActions.sendMailTestDrect({ mailSettings })

  useEffect(
    async () => {
      if (selectedRow) {
        setLoadedUser(false);
        const incharges = await selectAllByGClientDirect(selectedRow.id);
        setUsers(incharges);

        setTimeout(async () => {
          setTimeout(() => setLoadedUser(true), 300);
        }, 1000);
      }
    }, [selectedRow]
  );
  
  const handleClickMailTest = async ({ mailSettings }) => {
    try {
      const res = await sendMailTestDrect({ mailSettings });
      if (res?.info) {
        alert("메일이 정상으로 동작합니다.");
      } else {
        alert("메일 설정을 확인하세요.");
      }
    } catch (error) {
      alert(error.message);
    }
  }

  const columnsUser = [
    {
      field: 'userId',
      headerName: '이메일 아이디',
      width: 250,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 120,
    },
    {
      field: 'mobile',
      headerName: '모바일',
      width: 150,
    },
    {
      field: 'role',
      headerName: '사용자 유형',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const { value } = params;
        if (value === 'ADMIN_ROLE') {
          return "관리자";
        } else if (value === 'USER_ROLE') {
          return "일반사용자";
        } else {
          return "일반사용자"; // TODO : 다른 role이 생기면 그때 정의
        }
      },
    },
    {
      field: 'status',
      headerName: '상태',
      width: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mailTest',
      headerName: '메일테스트',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          disabled={!params.row.mailSettings}
          onClick={(e) => {
            e.stopPropagation();
            handleClickMailTest({ mailSettings: params.row.mailSettings });
          }}
        >
          {params.row.mailSettings ? '테스트' : '미설정'}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="xl"
        scroll="body"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          담당자
        </DialogTitleClose>
        <DialogContent>
          <div style={{ height: 200, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loadedUser}
              rows={users}
              columns={columnsUser}
              pageSize={pageSizeUser}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowSelectionModelChange={async (ids) => {
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GClientMailTestUserDialog;
