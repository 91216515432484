import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { SketchPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputText,
  FormInputSwitch,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gclientTypeActions from "../../store/gclientType";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  displayType: "",
  projectYN: true,
  comments: "",
};

// const ITEM_HEIGHT = 48;

const GClientTypeDialog = ({
  // modify,
  crudMode,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [hexColor, setHexColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [checkedProjectYN, setCheckedProjectYN] = useState(false);

  const handleChangeProjectYN = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요
    setValue("projectYN", !checkedProjectYN)
    setCheckedProjectYN(!checkedProjectYN);
  }

  const handleDialogClose = () => {
    setOpen(false);
    refresh();
  };

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();
  
  // 데이터 관리
  const selectAll = () => dispatch(gclientTypeActions.selectAll())
  const addGClientType = ({ id, name, code, projectYN, displayType, comments, color }) => dispatch(gclientTypeActions.create({ id, name, code, projectYN, displayType, comments, color }))
  const modifyGClientType = ({ id, name, code, projectYN, displayType, comments,color }) => dispatch(gclientTypeActions.modify({ id, name, code, projectYN, displayType, comments, color }))
  const reorder = ({ type, id }) => gclientTypeActions.reorder({ type, id })

  const onSubmit = ({ id, name, code, projectYN, displayType, comments }) => {
    setErrors([]);
    
    let func;
    if (crudMode === 'U') {
      func = modifyGClientType;
    } else if (crudMode === 'C') {
      func = addGClientType
    }
    func({ id, name, code, projectYN, displayType, comments, color: hexColor })
      .then (res => {
        handleDialogClose();
        selectAll();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      // selectedRow의 null 여부에 따라(수정 또는 등록) 값을 보여줄지 초기화될지 결정됨
      ["id", "name", "code", "projectYN", "displayType", "comments"].forEach(item => {
        if (item === "id") {
          setValue(item, selectedRow && selectedRow[item] || uuidv4());
        } else if (item === "projectYN") {
          // value 값이 있는 form control의 경우 (react hook form) setValue("name", value)로 값이 설정되나
          // value 값이 없는 경우 setValue로 form control의 설정상태가 바뀌지 않으므로 아래와 같이 처리
          // TODO : 작성한 form control(src/components/form) 안에서 setValue 시 적용된 값을 설정하는 방법 연구 필요
          setCheckedProjectYN(selectedRow && selectedRow[item] || false);
          // setValue(item, selectedRow && selectedRow[item] || ""); // 값 자체가 false 인데 ||로 인해 ""로 바뀜
          // setValue(selectedRow && selectedRow[item]) // 실수로 이렇게 했었는데 path.split is not a function 발생 (첫번째 인자 오류)
          setValue(item, selectedRow && selectedRow[item] || false);
        } else {
          setValue(item, selectedRow && selectedRow[item] || "")
        }
      });

      setHexColor(selectedRow?.color || "");
      setShowColorPicker(false);
    }, [selectedRow]
  );

  const popover = {
    // position: "absolute",
    zIndex: "2"
  };
  
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    '&:hover': {
      backgroundColor: 'red',
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {crudMode === 'C' && "거래선 구분 등록"}
        {crudMode === 'R' && "거래선 구분 상세"}
        {crudMode === 'U' && "거래선 구분 수정"}
      </DialogTitleClose>
      <DialogContent>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingLeft: '20px', border: "1px solid #C4C4C4", /*backgroundColor: checkedProjectYN ? "#5dc061" : "#d32f2f", color: 'white', */borderRadius: '5px' }}>
              {/* TODO : 읽기전용 기능 추가 필요 */}
              <FormInputSwitch
                name={"projectYN"}
                checked={checkedProjectYN}
                onChange={handleChangeProjectYN}
                control={control}
                label={"프로젝트 관렵업체 여부"}
                // color="success"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"displayType"}
              control={control}
              label={"표시명"}
              inputProps={
                { readOnly: crudMode === 'R', maxLength: 1 }/*, 콤마 있으면 parsing 오류*/
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            {/* TODO : 읽기전용 기능 추가 필요 */}
            <Button
              fullWidth
              variant={hexColor === "" ? "outlined" : "contained"}
              sx={{
                bgcolor: hexColor,
                ':hover': {
                  bgcolor: hexColor,
                },
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              {hexColor === "" ? "색상없음" : "색상"}
            </Button>
            {
              showColorPicker && (
                <div style={popover}>
                  <div style={cover} onClick={() => setShowColorPicker(false)} />
                  <SketchPicker
                    color={hexColor}
                    onChange={(color) => {
                      setHexColor(color.hex);
                      // console.log(color)
                    }}
                    // onSwatchHover={(color, e) => {
                    //   console.log("color", color);
                    // }}
                  />
                </div>
              )
            } 
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
        }
        {
          crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button>
        }
        {/* TODO : 아래 코드 안되는 이유? */}
        {/* <Button onClick={reset}>{"초기화"}</Button> */}
        {
          crudMode === 'C' && <Button onClick={() => reset()}>{"초기화"}</Button>
        }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GClientTypeDialog;
