import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm, /*useFieldArray*/ } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Fab,
  Grid,
  Icon,
  LinearProgress,
  Link,
  Typography,
  IconButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Divider,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  Add,
  Remove,
  Delete,
  Edit,
  FilePresent,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  More,
  OpenInNew,
  Window,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
// import OutputIcon from '@mui/icons-material/Output';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { loadCSS } from 'fg-loadcss';

import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuidv4 } from 'uuid';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff'; // TODO : 추후 Json diff와도 비교 검토 필요
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  objectEmptyCheck,
  hideWatermark,
  changeArrayOrder,
} from "../../utils";
import usePrevious from "../hook/usePrevious";
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputDate,
  FormInputDateRangePicker,
  FormInputSwitch,
  FormInputText,
  FormInputAutoComplete,
  FormInputMultipleSelect,
} from "../form";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { NavigationGuard } from "../Navigation";
import GClientManagement from "../GClient/GClientManagement";
import GGlassManagement from "../GGlass/GGlassManagement";
import GOrderManagement from "../GOrder/GOrderManagement";
import * as constructionWorkTypeActions from "../../store/constructionWorkType";
import * as gprojectActions from "../../store/gproject";
import * as gclientTypeActions from "../../store/gclientType";
import * as gclientActions from "../../store/gclient";
import * as gglassActions from "../../store/gglass";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as g04docuActions from "../../store/g04docu";

import { GClientList } from "../GClient";
import GProjectGlassDialog from "./GProjectGlassDialog";

const theme = createTheme();

const today = new Date();
let endDate = new Date();
endDate.setFullYear(today.getFullYear() + 2);

const GProjectG04Dialog = ({
  crudMode,
  setCrudMode,
  modify,
  setModify,
  open,
  setOpen,
  selectedRow,
  setSelectedRow,
  // gtypeDetailsWithGComponent,
  gtypes,
  refresh,
}) => {
  const dependentGcomponentMap = new Map();

  const [errors, setErrors] = useState([]);
  const [dateValue, setDateValue] = useState(new Date()); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
  const [checkedDoneYN, setCheckedDoneYN] = useState(false);
  // const [periodValue, setPeriodValue] = useState([null, null]); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
  const [fullScreen, setFullScreen] = useState(true);
  const [basicExpanded, setBasicExpanded] = useState(true);
  const [generalDocu04Expanded, setGeneralDocu04Expanded] = useState(true);
  const [rawMaterialExpanded, setRawMaterialExpanded] = useState(true);
  const [subMaterialProcessExpanded, setSubMaterialProcessExpanded] = useState(true);
  const [subMaterialBuildExpanded, setSubMaterialBuildExpanded] = useState(true);
  const [mode, setMode] = useState('all');
  const [saveMode, setSaveMode] = useState('');
  const [gclientTypes, setGclientTypes] = useState([]);
  
  // const [selectedRowGGlass, setSelectedRowGGlass] = useState({});
  // const [from, setFrom] = useState("");
  const [openGGlass, setOpenGGlass] = useState(false);
  const [gGlass, setGGlass] = useState({});

  // TODO : 추후 react hook form의 useFieldArray 방식을 쓸 수 있는지 검토
  const [gclientDetails, setGclientDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false); // 데이터 수정중 일 경우 페이지 이동시 안내
  
  const [initData, setInitData] = useState({}); // 등록 다이얼로그 상태일 때 초기데이터

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});

  const [drawerState, setDrawerState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    // right: false,
  });
  const [baseSubMaterialProcess, setBaseSubMaterialProcess] = useState([]);
  const [baseSubMaterialBuild, setBaseSubMaterialBuild] = useState([]);

  const [selectedGGlass, setSelectedGGlass] = useState(undefined);
  const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [selectedSubMaterialProcessItems, setSelectedSubMaterialProcessItems] = useState([]);
  const [selectedSubMaterialBuildItems, setSelectedSubMaterialBuildItems] = useState([]);
  const [selectedProcessGClients, setSelectedProcessGClients] = useState([]);
  const prevDrawerState = usePrevious(drawerState);

  const [params, setParams] = useState({});

  const [show, setShow] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [gtypeDetailsWithGComponentLength, setGtypeDetailsWithGComponentLength] = useState(0);
  
  const [gclientG04GeneralDocuMaps, setGclientG04GeneralDocuMaps] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [renderSubMaterialProcessItems, setRenderSubMaterialProcessItems] = useState(true);
  const [renderSubMaterialBuildItems, setRenderSubMaterialBuildItems] = useState(true);
  const [selectedG04docuGCertifications, setSelectedG04docuGCertifications] = useState([]);

  // 미리 선언해야 사용할 수 있으므로 위치 변경
  const g04docuGCertificationsSubMaterialProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsSubMaterialProcess);
  const g04docuGCertificationsSubMaterialBuild = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsSubMaterialBuild);
  const g04docuGCertificationsProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsProcess);

  // redux form의 useForm을 통해 setValue를 호출하여 바로 form에 적용하려면 defaultValues가 설정되어 있어야 하는데, 여기서는 form이 동적으로 변하므로 미리 defaultValues를 선언해놓을 수 없다.
  // 따라서 아래와 같이 동적으로 변하는 부분을 미리 로딩해서 defaultValuesForSubMaterials에 설정하고 static한 부분과 결합하여 defalutValues를 구성한다. (GGlassCompenet 참조)
  const defaultValuesForSubMaterials = {};
  g04docuGCertificationsSubMaterialProcess.forEach((material, index) => {
    // defaultValuesForSubMaterials[material.id] = "";
    // defaultValuesForSubMaterials[`${material.id}_usable`] = true;\
    // console.log(material)
    // if (material.gcomponentItems) {
    //   material.gcomponentItems.forEach(item => {
    //     defaultValuesForSubMaterials[`${item.id}_0`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_1`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_2`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_3`] = "";
    //     defaultValuesForSubMaterials[`${item.id}_4`] = "";

    //   })
    // }
    // defaultValuesForSubMaterials[`${material.id}_0`] = "";
    // defaultValuesForSubMaterials[`${material.id}_1`] = "";
    // defaultValuesForSubMaterials[`${material.id}_2`] = "";
    // defaultValuesForSubMaterials[`${material.id}_3`] = "";
    // defaultValuesForSubMaterials[`${material.id}_4`] = "";
    // defaultValuesForSubMaterials[`${material.id}_5`] = "";
  });

  g04docuGCertificationsSubMaterialBuild.forEach((material, index) => {
    // defaultValuesForSubMaterials[material.id] = "";
  });

  // 아래 form components의 name과 연계
  const defaultValues = Object.assign({
    id: "",
    site: "",
    siteAddress: "",
    startDate: today.getDateWithStartHours(),
    endDate: endDate.getDateWithEndHours(),
    comments: "",
    doneYN: false,
    constructionWorkTypeId: "",

    no: "",
    gglassId: "",

    gclientId: "",
    gclientName: "",
    gclientsProcess: [],
  }, defaultValuesForSubMaterials);

  console.log(defaultValues)
  const handleSelectUpDown = async ({ type, rownum }) => {
    setLoaded(false);
    
    // TODO : rows 변경
    let newArr;
    const newRows = rows;
    
    if (type === "up") {
      if (rownum > 0) {
        newArr = changeArrayOrder(newRows, rownum, -1)
      } else {
        setLoaded(true);
        return;
      }
    } else if (type === "down") {
      if (rownum < newRows.length - 1) {
        newArr = changeArrayOrder(newRows, rownum, 1)
      } else {
        setLoaded(true);
        return;
      }
    } else if (type === "first") {
      newArr = changeArrayOrder(newRows, rownum, -rownum) // 0번째에 넣어야 하므로 (함수 안에서 2번째 3번째 인자의 합으로 계산)
    } else if (type === "last") {
      newArr = changeArrayOrder(newRows, rownum, newRows.length-1-rownum)
    } else {
      newArr = newRows;
    }

  
    setRows(newArr.map((row, i) => {
      return {
        ...row,
        rownum: i,
      }
    }));

    setTimeout(() => setLoaded(true), 300);
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [];
    console.log(params)
    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', rownum: params.row.rownum })}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', rownum: params.row.rownum })}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', rownum: params.row.rownum })}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', rownum: params.row.rownum })}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '일련번호',
      width: 120,
      hide: true,
    },
    {
      field: 'rownum',
      headerName: '순번',
      width: 60,
      // hide: true,
      type: 'number',
      valueGetter: (params) => params.row.rownum+1,
    },
    {
      field: 'no',
      headerName: '품번',
      width: 150,
    },
    {
      field: 'gglassId',
      headerName: '유리 템플릿 아이디',
      width: 200,
      hide: true,
    },
    {
      field: 'gglassName',
      headerName: '유리 템플릿',
      width: 200,
    },
    {
      field: 'specification',
      headerName: '사양',
      flex: 1,
      // width: 500,
      // editable: true,
      // valueGetter: (params) => params.row.selectedGcomponentItems,
      // renderCell: (params) => { // valueGetter -> renderCell
      //   console.log(params)
      //   return (
      //     <>{params.formattedValue}</>
      //   )
      // },
      renderCell: (params) => { // valueGetter -> renderCell
        const { value } = params;
        if (!value) {
          return "";
        }

        // const layers = formattedValue.split("+");
        const layers = value.split("+");
        return layers.map((layer, i) => {
          return (
            <>
              <Chip
                sx={{ m: 0.2 }}
                color="secondary" variant="outlined"
                label={
                  layer.split("|").map((item, j) => {
                    return (
                      <>
                        <Chip label={item} size={"small"} color="primary" sx={{ mr: (j !== layer.split("|").length - 1) ? 1.2 : 0 }} />
                      </>
                    )
                  })
                }
              />
              {
                i === layers.length - 1 ? "" : <span style={{ color: '#1976d2' }}>{" + "}</span>
              }
            </>
          )
        })
      },
    },
    // {
    //   field: 'otherSpecs',
    //   headerName: '비고',
    //   width: 500,
    //   // editable: true,
    //   // valueGetter: (params) => params.row.selectedGcomponentItems,
    //   valueGetter: (params) => {
    //     // let specification = "";
    //     let otherSpecs = "";

    //     const selected = params.row.selectedGcomponentItems;
    //     selected && selected.forEach(gtypeDetails => {
    //       // let productName = "";
    //       let otherSpec = "";
    //       gtypeDetails.forEach(gcomponentItem => {
    //         if (Array.isArray(gcomponentItem.value)) {
    //           gcomponentItem.value.forEach(item => {
    //             const { applyType, code } = item;
    //             if (applyType === 'productName') {
    //               // productName += code ? `${code} ` : "";
    //             } else if (applyType === 'etc') {
    //               otherSpec += code ? `${code} ` : "";
    //             }
    //           })
    //         } else {
    //           const { applyType, code } = gcomponentItem.value;
    //           if (applyType === 'productName') {
    //             // productName += code ? `${code} ` : "";
    //           } else if (applyType === 'etc') {
    //             // otherSpecs += gcomponentItem.value.code ? `${gcomponentItem.value.code}, ` : "";
    //             otherSpec += code ? `${code} ` : "";
    //           }
    //         }
    //       });
    //       // productName = productName.substring(0, productName.length - 1);
    //       // specification += (productName !== "" ? `${productName} + ` : "");

    //       otherSpec = otherSpec.substring(0, otherSpec.length - 1);
    //       otherSpecs += (otherSpec !== "" ? `${otherSpec} + ` : "");
    //     });
        
    //     return otherSpecs.substring(0, otherSpecs.length - 2);
    //   }
    // },
    ...g04docuGCertificationsProcess.map((item, i) => {
      return {
        field: item.id,
        headerName: item.name,
        width: 280,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          // TODO : i 인덱스 순서 맞는지 검토 필요
          const { selectedProcessGClients } = params.row;
          if (selectedProcessGClients && Array.isArray(selectedProcessGClients) && selectedProcessGClients.length-1 > i && selectedProcessGClients[i].value) {
            return params.row.selectedProcessGClients[i].value.map(value => <Chip label={value.name} variant="outlined" size={"small"} color="primary" sx={{ mr: 1 }} />)
          }
        }
      }
    }),
    {
      field: 'actions',
      headerName: <Tooltip title={""} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 200,
      // TODO : description 설정시 나타나는 툴팁 때문에 바로 기능버튼을 누르기 불편한 부분이 있어 여기서만 우선 주석처리. 추후 다른 목록들도 검토
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      hide: checked ? false : true,
      getActions: (params) => generateActions(params),
    },
  ];

  const handleDialogClose = () => {
    // TODO : 수정한 것이 있다면 닫기 전 confirm 창 띄울 것
    setOpen(false);
    setMode('all');

    setCrudMode('');

    // if (saveMode === "save") { // 저장(저장 후 닫기 아님) 후 닫기 시 목록 refresh 필요
    //   const { pathname } = location;
    //   if (pathname === "/projects") {
    //     refresh(false);
    //   } else if (pathname === "/g04docuGenerateFile") {
    //     refresh(true);
    //   }
    // }

    initDialog();
  };

  const handleChangeDoneYN = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요 => FormInputDropdown1과 FormInputDate1은 set~함수를 만들고 외부에서 호출하도록 구현(검토 필요)
    setValue("doneYN", !checkedDoneYN)
    setCheckedDoneYN(!checkedDoneYN);
    checkEdit({ newRight: null, newGclientDetails: null });
  }

  const handleDialogMinMax = () => {
    setFullScreen(!fullScreen);

    // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
    setLoaded(false);
    setShowGrid(false);

    setTimeout(
      async () => {
        await hideWatermark();
        setTimeout(() => setShowGrid(true), 300);
        setTimeout(() => setLoaded(true), 500);
      }, 300
    )
  }
  
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // TODO : 현재 react hook form의 경우 배열에서 해당 인덱스의 값 삭제시 컴포넌트의 value 값 문제로 사용하고 있지 않음
  // const { fields, append, replace, remove } = useFieldArray({ control: control, name: "gclientIds" });
  // const dialogRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();

  const sessionUser = useSelector(state => state.session.sessionUser);
  const gclients = useSelector((state) => state.gclient.gclients);
  const constructionWorkTypes = useSelector(state => state.constructionWorkType.constructionWorkTypes);
  const gtypeDetailsWithGComponent = useSelector((state) => state.gtypeDetail.gtypeDetailsWithGComponent);
  const gglasses = useSelector((state) => state.gglass.gglasses);
  // defaultValues에서 사용하기 위해 위로 위치 이동
  // const g04docuGCertificationsSubMaterialProcess = useSelector((state) => state.g04docu.g04docuGCertificationsSubMaterialProcess);
  // const g04docuGCertificationsSubMaterialBuild = useSelector((state) => state.g04docu.g04docuGCertificationsSubMaterialBuild);
  
  // 데이터 관리
  
  // no dispatch
  const addGProjectWithResult = ({
    id,
    site,
    siteAddress,
    startDate,
    endDate,
    gclientDetails,
    comments,
    doneYN,
    constructionWorkTypeId,
    selectedGlasses,
    selectedSubMaterialProcessItems,
    selectedSubMaterialBuildItems,
    docu04,
  }) => {
    return gprojectActions.createWithResult({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      gclientDetails,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
    });
  }

  const modifyGProjectWithResult = ({
    id,
    site,
    siteAddress,
    startDate,
    endDate,
    gclientDetails,
    comments,
    doneYN,
    constructionWorkTypeId,
    selectedGlasses,
    selectedSubMaterialProcessItems,
    selectedSubMaterialBuildItems,
    docu04,
  }) => {
    return gprojectActions.modifyWithResult({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      gclientDetails,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
    });
  }

  const selectAllGClientG04GeneralDocuByGClientDirect = (gclientId) => gclientG04GeneralDocuMapActions.selectAllByGClientDirect(gclientId)
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)

  // dispatch
  const selectAllGClients = () => dispatch(gclientActions.selectAll())
  const selectGlasses = () => dispatch(gglassActions.selectAllByQuery())
  const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))
  const initGTypeDetailsWithGComponent = () => dispatch(gtypeDetailActions.initWithComponent())
  const selectAllConstructionWorks = () => dispatch(constructionWorkTypeActions.selectAll())

  const onSubmit = ({ id, site, siteAddress, startDate, endDate, comments, doneYN, constructionWorkTypeId }, mode) => {
    // TODO : validation 처리 필요. 특히 날짜 등...
    setErrors([]);

    if (mode === 'save') {
      setLoading(true);
    }

    // const aaa = g04docuGCertificationsProcess.map(item => {
    //   // console.log(getValues(item.id));
    //   return {
    //     id: item.id,
    //     name: item.name,
    //     value: getValues(item.id),
    //   }
    // });
    // console.log(aaa)

    // TODO : 지금은 2
    // 1. startDate, endDate의 데이터 타입은 string, 2022-12-08T01:29:08.157Z
    // 2. startDate, endDate의 데이터 타입은 object
    // console.log(rows);
    console.log({
      id,
      site,
      siteAddress,
      gclientDetails,
      startDate,
      endDate,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses: rows,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04: true,
      mode,
    })
    // console.log(typeof endDate);
    // console.log(startDate.toUTCString());
    // console.log(endDate.toUTCString());
    // return;

    let func;
    if (modify) {
      func = modifyGProjectWithResult;
    } else {
      func = addGProjectWithResult;
    }
    
    func({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      gclientDetails,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses: rows,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04: true,
    }).then (res => {
        if (mode === 'saveAndClose') {
          handleDialogClose();
          // refresh();
        } else { // mode === 'save'
          setTimeout(() => setLoading(false), 1000);
          console.log(res);
          // console.log(gproject);
          setModify(true);
          setValue("id", res.gproject.id);

          // TODO : 초기화(페이지 나갈때 변경여부 묻지 않도록) 필요하고, 닫기시 목록의 refresh 필요함
        }
        const { pathname } = location;
        if (pathname === "/projects") {
          refresh(false);
        } else if (pathname === "/g04docuGenerateFile") {
          refresh(true);
        }
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });

    // setSaveMode(mode);
  }

  useEffect(
    () => {
      // TODO : g04docuGCertificationsSubMaterialProcess는 g04docu DB 설정 데이터, baseSubMaterialProcess는 g04docuGCertificationsSubMaterialProcess에 selectedSubMaterialProcessItems 데이터가 반영된 값
      if (selectedRow) {
        const subMaterialProcessItems = selectedRow.selectedSubMaterialProcessItems;
        if (subMaterialProcessItems && Array.isArray(subMaterialProcessItems) && subMaterialProcessItems.length > 0) {
          setSelectedSubMaterialProcessItems(subMaterialProcessItems);
          
          const newBaseSubMaterialProcess = [];
          subMaterialProcessItems.forEach(item => {
            const subMaterial = g04docuGCertificationsSubMaterialProcess.find(g04 => g04.id === item.id);
            if (subMaterial) {
              newBaseSubMaterialProcess.push(subMaterial);
            }
          });
          setBaseSubMaterialProcess(newBaseSubMaterialProcess);
        } else {
          setSelectedSubMaterialProcessItems(g04docuGCertificationsSubMaterialProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
          setBaseSubMaterialProcess(g04docuGCertificationsSubMaterialProcess);
        }
      } else {
        setSelectedSubMaterialProcessItems(g04docuGCertificationsSubMaterialProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
        setBaseSubMaterialProcess(g04docuGCertificationsSubMaterialProcess);
      }
    }, [g04docuGCertificationsSubMaterialProcess]
  )

  useEffect(
    () => {
      if (selectedRow) {
        const subMaterialBuildItems = selectedRow.selectedSubMaterialBuildItems;
        if (subMaterialBuildItems && Array.isArray(subMaterialBuildItems) && subMaterialBuildItems.length > 0) {
          setSelectedSubMaterialBuildItems(subMaterialBuildItems);

          const newBaseSubMaterialBuild = [];
          subMaterialBuildItems.forEach(item => {
            const subMaterial = g04docuGCertificationsSubMaterialBuild.find(g04 => g04.id === item.id);
            if (subMaterial) {
              newBaseSubMaterialBuild.push(subMaterial);
            }
          });
          setBaseSubMaterialBuild(newBaseSubMaterialBuild);
        } else {
          setSelectedSubMaterialBuildItems(g04docuGCertificationsSubMaterialBuild.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
          setBaseSubMaterialBuild(g04docuGCertificationsSubMaterialBuild);
        }
      } else {
        setSelectedSubMaterialBuildItems(g04docuGCertificationsSubMaterialBuild.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
        setBaseSubMaterialBuild(g04docuGCertificationsSubMaterialBuild);
      }
    }, [g04docuGCertificationsSubMaterialBuild]
  )

  useEffect(
    () => {
      // if (selectedRow) {
      //   const processGClients = selectedRow.selectedProcessGClients;
      //   if (processGClients && Array.isArray(processGClients) && processGClients.length > 0) {
      //     setSelectedProcessGClients(processGClients);
      //   } else {
      //     setSelectedProcessGClients(g04docuGCertificationsProcess.map((item, i) => ({ id: item.id, name: item.name, index: i, usable: true, value: []})));
      //   }
      // } else {
        g04docuGCertificationsProcess.length > 0 && setSelectedProcessGClients(g04docuGCertificationsProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: []})));
      // }
    }, [g04docuGCertificationsProcess]
  )

  useEffect(
    async () => {
      await hideWatermark();
      await selectAllGClients();
    }, [dispatch]
  );

  useEffect(
    async () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === "startDate") {
            setValue("startDate", (new Date(selectedRow["startDate"])).getDateWithStartHours() || value);
          } else if (item === "endDate") {
            setValue("endDate", (new Date(selectedRow["endDate"])).getDateWithEndHours() || value);
          } else {
            setValue(item, selectedRow[item] || value);
          }
        }
    
        // 그외 초기화할 것들은 여기서 초기화
        setCheckedDoneYN(selectedRow["doneYN"] || defaultValues.doneYN);

        if (crudMode === 'U') {
          setBasicExpanded(false);
          setGeneralDocu04Expanded(false);
        }
  
        if (!selectedRow.owner) {
          setValue("gclientId", sessionUser.id);
          setValue("gclientName", sessionUser.name);
        } else {
          setValue("gclientId", selectedRow.owner.id);
          setValue("gclientName", selectedRow.owner.name);
        }

        if(selectedRow.selectedGlasses.length > 0) {
          setRows(selectedRow.selectedGlasses.map((glass, i) => ({
            ...glass,
            rownum: i,
          })));
        }
      }
    }, [selectedRow]
  );

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setCheckedDoneYN(false);
    setBasicExpanded(true);
    setGeneralDocu04Expanded(true);

    initGTypeDetailsWithGComponent();
    setSelectedGGlass(undefined);
    
    setRows([]);
    setSelectedSubMaterialProcessItems(g04docuGCertificationsSubMaterialProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
    setSelectedSubMaterialBuildItems(g04docuGCertificationsSubMaterialBuild.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: {}})));
    setSelectedProcessGClients(g04docuGCertificationsProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: []})));

    setLoaded(false);
    setShowGrid(false);
  }

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [crudMode]
  );

  useEffect(
    async () => {
      if (open) {
        const gclientId = sessionUser.id;
        const maps = await selectAllGClientG04GeneralDocuByGClientDirect(gclientId);
        setGclientG04GeneralDocuMaps(maps);

        selectGlasses();
        selectAllConstructionWorks();

        setLoaded(false);
        setShowGrid(false);
        
        setTimeout(
          async () => {
            await hideWatermark();
            setTimeout(() => setShowGrid(true), 300);
            setTimeout(() => setLoaded(true), 500);
          }, 300
        )
      }
    }, [open]
  )

  // TODO : 기존 데이터와 수정된 데이터를 전체 비교. 전체 비교하여 이 기능을 사용할지 말지는 선택하게 하는 방법 검토
  const checkEdit = (param) => {
    const { newRight, newGclientDetails } = param ? param : { newRight: null, newGclientDetails: null };
    console.log(">>>>>>>>>>>>>>>>>>>>>> previous data")
    const prevData = selectedRow ? selectedRow : initData;
    console.log(prevData)
    console.log(">>>>>>>>>>>>>>>>>>>>>> present data")
    const newData = {
      id: getValues('id'),
      site: getValues('site'),
      siteAddress: getValues('siteAddress'),
      startDate: getValues('startDate'),
      endDate: getValues('endDate'),
      comments: getValues('comments'), 
      doneYN: getValues('doneYN'),
      gclientDetails: newGclientDetails ? newGclientDetails : gclientDetails,
      // selectedGlasses: newRight ? newRight : right,
      order: prevData.order,
      orderDetail: prevData.orderDetail,
      createdAt: prevData.createdAt,
      updatedAt: prevData.updatedAt,
    };
    console.log(newData)
    // const difference = diff(prevData, newData);
    // console.log(">>>>>>>>>>>>>>>>>>>>>> difference")
    // console.log(difference)
    // TODO : 객체 비교시 비교대상이 아닌 속성은 검토해서 최종 변경 여부 판단
    const detailedDifference = detailedDiff(prevData, newData) // returns an object with the added, deleted and update
    console.log(">>>>>>>>>>>>>>>>>>>>>> detailedDifference")
    console.log(detailedDifference)

    // TODO : 객체의 모든 속성(하위 객체 포함)의 값까지 100% 점검하지는 않으므로 혹시 그렇게 하려면 고도화 필요
    const { added, deleted, updated } = detailedDifference;
    if (objectEmptyCheck(added) && objectEmptyCheck(deleted) && objectEmptyCheck(updated)) {
      setEditing(false);
    } else {
      setEditing(true);
      // let editedSelectedGlasses = true;
      // let editedgclientDetails = true;
      // let editedEtc = true;

      // let editedAdd = !objectEmptyCheck(added);
      // let editedDelete = !objectEmptyCheck(deleted);
      // // gclientDetails에는 다이얼로그가 나타날때 그 안의 index값이 변하므로 실제로 내용을 수정하지 않아도 다르게 나타남
      // // 따라서 index만 변했다면 수정이 되지 않았다고 판단
      // const { gclientDetails } = updated;
      // if (gclientDetails) {
      //   const updatedGclientDetails = [];
      //   for (const [key, value] of Object.entries(gclientDetails)) {
      //     const gclientDetail = value;
      //     for (const [key, value] of Object.entries(gclientDetail)) {
      //       const gclients = value;
      //       for (const [key, value] of Object.entries(gclients)) {
      //         const gclient = value;
      //         const keys = Object.keys(gclient);
      //         if (!(keys.length ===1 && keys[0] === "index")) {
      //           // console.log(gclient)
      //           updatedGclientDetails.push("updated");
      //         }
      //       }
      //     }
      //   }
        
      //   if (updatedGclientDetails.length <= 0) {
      //     editedgclientDetails = false;
      //   }

      //   // console.log(Object.keys(updated))
      //   // console.log(Object.entries(defaultValues).map(item => item[0]));
      //   const a = Object.keys(updated);
      //   const b = Object.entries(defaultValues).map(item => item[0]);

      //   const result = a.filter((value) => b.map(item => item).indexOf(value) !== -1);
      //   if (result.length <= 0) {
      //     editedEtc = false;
      //   }

      // }

      // console.log(`editedSelectedGlasses: ${editedSelectedGlasses}, editedgclientDetails: ${editedgclientDetails}, editedEtc: ${editedEtc}`);
      // setEditing(editedAdd || editedDelete || /*editedSelectedGlasses || */editedgclientDetails || editedEtc);
    }
  }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    return true;
  }

  const setGcomponentItems = () => {
    const gcomponentItems = selectedGcomponentItems?.length > 0 ?  selectedGcomponentItems : selectedGGlass?.selectedGcomponentItems;
    gcomponentItems?.forEach((gtypeDetails, i) => {
      gtypeDetails.forEach((gcomponentItem, j) => {
        if (Array.isArray(gcomponentItem.value)) { // 다중선택일 경우. TODO : 현재 value가 배열인 경우가 다중선택일 경우엔데 속성값으로 판단이 아니라 배열여부이므로 추후 재검토할 것
          // setValue(`${i}_${j}_${gcomponentItem.id}`, gcomponentItem.value);
          // setMultipleValue(gcomponentItem.value);
          console.log(gcomponentItem.value);
        } else {
          console.log(`${i}_${j}_${gcomponentItem.id}`);
          console.log(gcomponentItem.value.id);
          setValue(`${i}_${j}_${gcomponentItem.id}`, gcomponentItem.value.id || ""); // gcomponentItem.value.id가 undefined이면 || "" 없으면 값 초기화 안됨. (setValue(item, undefined) 안됨)
          setValue(`${i}_${j}_${gcomponentItem.id}_gclient`, gcomponentItem.value.selectedGClientId || ""); // gcomponentItem.value.id가 undefined이면 || "" 없으면 값 초기화 안됨. (setValue(item, undefined) 안됨)
        }
      });
    });
  }

  const setSubMaterialProcessItems = () => {
    selectedSubMaterialProcessItems.forEach((item, i) => {
      console.log(item)
      setValue(`${item.id}_usable_${i}`, item.usable ? item.usable : false);
      setValue(`${item.id}_${i}`, item.value && item.value.id ? item.value.id : "");
      setValue(`${item.id}_gclient_${i}`, item.value && item.value.selectedGClientId ? item.value.selectedGClientId : "");
    })
  }

  const setSubMaterialBuildItems = () => {
    selectedSubMaterialBuildItems.forEach((item, i) => {
      // setValue(`${item.id}_usable`, item.usable);
      // setValue(item.id, item.value.id);
      // setValue(`${item.id}_gclient`, item.value.selectedGClientId);
      setValue(`${item.id}_usable_${i}`, item.usable ? item.usable : false);
      setValue(`${item.id}_${i}`, item.value && item.value.id ? item.value.id : "");
      setValue(`${item.id}_gclient_${i}`, item.value && item.value.selectedGClientId ? item.value.selectedGClientId : "");
    })
  }

  // useEffect(
  //   () => {
  //     console.log(selectedProcessGClients);
  //   }, [selectedProcessGClients]
  // )

  const setProcessGClients = () => {
    g04docuGCertificationsProcess?.map((g04docu, i) => {
      const { id, name } = g04docu;
      // TODO : id를 사용하여 gclients.gclientType04docus의 g04docuGCertificationId로 찾는다.
      const selectedGclients = gclients.filter(gclient => {
        if (gclient.gclientType04docus) {
          return gclient.gclientType04docus.find(gclientType04docu => gclientType04docu.g04docuGCertificationId === id );
        } else {
          return false;
        }
      });
      console.log(selectedGclients)
    });
    
    console.log(selectedProcessGClients);
    selectedProcessGClients.forEach((item, i) => {
      // setValue(item.id, item.value.id);
      // setValue(`${item.id}_gclient`, item.value.selectedGClientId);
    })
  }

  const handleClickAttachment = async (e, documentPath) => {
    e.stopPropagation();

    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const handleChangeGGlass = async (e) => {
    setSelectedGcomponentItems([]);
    // e.target.name은 undefined 임???
    const gglassId = e.target.value;

    // !selectedId && setValue('no', "");

    setValue("gglassId", gglassId);

    const selGGlass = gglasses.find(gglass => gglass.id === gglassId);
    if (selGGlass) {
      setSelectedGGlass(selGGlass);

      setSelectedG04docuGCertifications(selGGlass.selectedG04docuGCertifications);

      setSelectedGcomponentItems(selGGlass.selectedGcomponentItems);

      const gtypeId = selGGlass.gtypeId;
      // setValue("gtypeId", gtypeId);
      // setLoadedGcomponents(false);
      await selectGTypeDetailsWithGComponent(gtypeId);
    }

    // TODO : 설정된 가공업체 초기화 필요
    g04docuGCertificationsProcess.length > 0 && setSelectedProcessGClients(g04docuGCertificationsProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true, value: []})));
  }

  const changeGGlass = async (gglassId) => {
    // !selectedId && setValue('no', "");

    setValue("gglassId", gglassId);
    
    const selGGlass = gglasses.find(gglass => gglass.id === gglassId);
    if (selGGlass) {
      setSelectedGGlass(selGGlass);
      setSelectedGcomponentItems(selGGlass.selectedGcomponentItems);
      
      const gtypeId = selGGlass.gtypeId;
      await selectGTypeDetailsWithGComponent(gtypeId);
    }
  }

  const getSpecification = (gcomponentItems) => {
    let specification = "";
    // let otherSpecs = "";

    const selected = gcomponentItems;
    selected && selected.forEach(gtypeDetails => {
      let productName = "";
      // let otherSpec = "";
      gtypeDetails.forEach(gcomponentItem => {
        if (Array.isArray(gcomponentItem.value)) {
          gcomponentItem.value.forEach(item => {
            const { applyType, code } = item;
            if (applyType === 'productName') {
              productName += code ? `${code}|` : "";
            }/* else if (applyType === 'etc') {
              otherSpec += code ? `${code} ` : "";
            }*/
          })
        } else {
          const { applyType, code } = gcomponentItem.value;
          if (applyType === 'productName') {
            productName += code ? `${code}|` : "";
          }/* else if (applyType === 'etc') {
            // otherSpecs += gcomponentItem.value.code ? `${gcomponentItem.value.code}, ` : "";
            otherSpec += code ? `${code} ` : "";
          }*/
        }
      });
      productName = productName.substring(0, productName.length - 1);
      specification += (productName !== "" ? `${productName}+` : "");

      // otherSpec = otherSpec.substring(0, otherSpec.length - 1);
      // otherSpecs += (otherSpec !== "" ? `${otherSpec} + ` : "");
    });
    return specification.substring(0, specification.length - 1);
    // return specification.substring(0, specification.length - 3);
    // setSpecValue("otherSpecs", otherSpecs.substring(0, otherSpecs.length - 2));
  }

  const handleClickAdd = () => {
    setLoaded(false);
    
    const no = getValues("no");
    const gglassId = getValues("gglassId");
    const gglassName = gglasses.find(gglass => gglass.id === gglassId).name;

    // TODO : 여기서 비고(etc)에 설정된 속성은 배제한다.
    const gcomponentItems = JSON.parse(JSON.stringify(selectedGcomponentItems));
    console.log(gcomponentItems)
    gtypeDetailsWithGComponent.map((gtypeDetail, i) => {
      gtypeDetail.map((gcomponent, j) => {
        if (gcomponent.type === 'property' &&
          gcomponent.properties &&
          gcomponent.properties.length &&
          gcomponent.properties.length > 0
        ) {
          if (gcomponent.properties.filter(property => property.applyType === 'productName').length !== gcomponent.properties.length) {
            if (Array.isArray(gcomponentItems[i][j]?.value)) {
              gcomponentItems[i][j].value = [];
            } else {
              gcomponentItems[i][j].value = {};
            }
          }
        }
      })
    })

    console.log(selectedGGlass);
    const { gtypeId, gtypeName } = selectedGGlass;
    // 선택된 행 위치에 추가한다. 선택된 것이 없다면 맨 아래에 추가한다.
    const newRows = [].concat(rows);
    const rowNo = rows.findIndex(row => row.id === selectedId);

    const newRow = {
      id: uuidv4(),
        rownum: newRows.length,
        gprojectId: getValues('id'),
        no,
        gglassId,
        gglassName,
        gtypeId,
        gtypeName,
        gglassName: gglasses.find(gglass => gglass.id === gglassId)?.name,
        selectedGcomponentItems: gcomponentItems,
        selectedProcessGClients,
        specification: getSpecification(gcomponentItems),
    };

    console.log(newRow);

    if (rowNo >= 0) {
      newRows.splice(rowNo, 0, newRow);

      setRows(newRows.map((row, i) => {
        return {
          ...row,
          rownum: i,
        }
      }));
    } else {
      setRows(newRows.concat([newRow]));
    }

    setTimeout(() => setLoaded(true), 300)
  }

  const handleClickModify = () => {
    setLoaded(false);
    
    const newRows = [].concat(rows);
    const rowNo = rows.findIndex(row => row.id === selectedId);

    if (rowNo >= 0) {
      // 선택된 행 정보를 변경한다.
      const no = getValues("no");
      const gglassId = getValues("gglassId");
      const gglassName = gglasses.find(gglass => gglass.id === gglassId).name;

      const { gtypeId, gtypeName } = selectedGGlass;

      newRows.splice(rowNo, 1, {
        id: uuidv4(),
        rownum: rowNo,
        gprojectId: getValues('id'),
        no,
        gglassId,
        gglassName,
        gtypeId,
        gtypeName,
        gglassName: gglasses.find(gglass => gglass.id === gglassId)?.name,
        selectedGcomponentItems,
        selectedProcessGClients,
        specification: getSpecification(selectedGcomponentItems),
      });

      setRows(newRows);
    }

    setSelectedId("");

    setTimeout(() => setLoaded(true), 300)
  }

  const handleClickDelete = () => {
    setLoaded(false);
    
    const newRows = [].concat(rows);
    const rowNo = rows.findIndex(row => row.id === selectedId);
    if (rowNo >= 0) {
      // 선택된 행 정보를 삭제한다.
      newRows.splice(rowNo, 1);
      
      setRows(newRows.map((row, i) => {
        return {
          ...row,
          rownum: i,
        }
      }));
    }

    setSelectedId("");

    setTimeout(() => setLoaded(true), 300)
  }

  const handleChangeManufacturer = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent) => {
    setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}_gclient`, e.target.value);
    
    // TODO : 제조사 설정 정보를 selectedGcomponentItems에도 넣어야 함
    const newSelectedGcomponentItems = JSON.parse(JSON.stringify(selectedGcomponentItems));

    console.log(newSelectedGcomponentItems);
    newSelectedGcomponentItems[gtypeDetailsOrder] = newSelectedGcomponentItems[gtypeDetailsOrder].map(gcomponentItem => {
      if (item.id === gcomponentItem.id) {
        gcomponentItem = {
          ...gcomponentItem,
          value : {
            ...gcomponentItem.value,
            selectedGClientId: e.target.value,
          }
        }
      }

      return gcomponentItem;
    });

    console.log(newSelectedGcomponentItems);

    setSelectedGcomponentItems(newSelectedGcomponentItems);
  }

  const handleChangeGcomponentItem = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent) => {
    
    // "없음" 선택한 경우 해당 구성요소가 의존하고 있는 구성요소가 있을 경우 안내메시지 띄우고 "없음" 선택안되도록 함
    // properties가 0("없음")인 경우 selectedGcomponentItems[gtypeDetailsOrder]에서 dependentGcomponentItem가 있는 것 중 gcomponentId가 item.id인 것이 있으면 안내 메시지 출력 
    // console.log(selectedGcomponentItems);
    const properties = item.properties.filter(property => property.id === e.target.value);
    if (properties.length <= 0) { // "없음"일 경우
      const gcomponentItems = selectedGcomponentItems[gtypeDetailsOrder];
      // console.log(gcomponentItems)
      const result = gcomponentItems.filter(gcomponentItem => {
        return gcomponentItem.value?.dependentGcomponentItem && JSON.parse(gcomponentItem.value?.dependentGcomponentItem).gcomponentId === item.id
      });
      // console.log(result)
      if (result.length > 0) {
        const { name, value } = result[0];
        const messageAlert = (
          <div>
            <span style={{ color: "#1976d2" }}>{`'${name}'`}</span>{`(이)가 '`}
            <span style={{ color: "#1976d2" }}>{`${value.name}`}</span>{`'(으)로 선택된 경우 `}
            <span style={{ color: "#1976d2" }}>{`'${item.name}'`}</span>{`(은)는 `}
            {/* <span style={{ color: "red" }}>{`'없음'`}</span>{`을 선택할 수 없고,`} */}
            <br/>
            <span style={{ color: "#d32f2f",  }}><i><u>{`반드시 값을 선택`}</u></i></span>
            {`해야 합니다.`}
          </div>
        );

        setAlertInfo({
          titleAlert: "안내",
          messageAlert,
          open: true,
        });

        return;
      }
    }

    let value = "";
    if (properties.length > 0) {
      value = properties[0];
    }
    
    setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, value?.id || value);
    // console.log(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
    
    const itemNew = {
      id: item.id,
      name: item.name,
      code: item.code, // TODO : 다른 곳도 필요시 코드 추가해야 함
      value,
    };

    const gcomponentItems = selectedGcomponentItems?.length > 0 ?  selectedGcomponentItems : selectedGGlass?.selectedGcomponentItems;
    
    const selectedNew = [].concat(gcomponentItems);
    selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
    
    // TODO : 해당 값이 변할 때 의존구성요소값이 있다면 그 구성요소의 값을 설정해야 한다. 값을 변화시킬 수 있는데 화면에 설정값으로 렌터링은 아직...
    if (properties.length > 0 && properties[0]?.dependentGcomponentItem?.length > 0) {
      const dependentGcomponentItem = JSON.parse(properties[0].dependentGcomponentItem);
      // console.log(dependentGcomponentItem)

      let targetGcomponentItemOrder = "";
      gcomponent.forEach((component, idx) => {
        if (component.id === dependentGcomponentItem.gcomponentId) {
          targetGcomponentItemOrder = idx;
        }
      });
      
      // console.log(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`);
      setValue(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`, `${dependentGcomponentItem.id}`);
      
      const dependentGcomponentItemNew = {
        id: dependentGcomponentItem.gcomponentId,
        name: dependentGcomponentItem.name,
        value: dependentGcomponentItem,
      }

      selectedNew[gtypeDetailsOrder][targetGcomponentItemOrder] = dependentGcomponentItemNew;

      // TODO : 등록하기에서는 템플릿구조가 선택되지 않아 map 구성이 안되어 있으므로 여기서 구성 필요
      dependentGcomponentMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, {
        ...dependentGcomponentItemNew,
        gtypeOrder: gtypeDetailsOrder,
        gcomponentOrder: targetGcomponentItemOrder,
      });

      // console.log(dependentGcomponentMap);
    } else {
      const map = dependentGcomponentMap.get(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
      if (map) {
        setValue(`${map.gtypeOrder}_${map.gcomponentOrder}_${map.id}`, "");
        selectedNew[map.gtypeOrder][map.gcomponentOrder] = {
          id: map.id,
          name: map.name,
          value: "",
        };
      }
    }
    
    setSelectedGcomponentItems(selectedNew);

    // TODO : 아래처럼 할지 useEffect 쓸지 추후 검토
    // setSpec(selectedNew);
  }

  const handleChangeMultiGcomponentItem = (value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/) => {
    // console.log({value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/});
    const itemNew = {
      id: item.id,
      name: item.name,
      value: value.length > 0 ? value.map(v => JSON.parse(v)) : [], // 모두 선택해제되면 []로 설정
    };

    const selectedNew = [].concat(selectedGcomponentItems);
    selectedNew[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
    
    setSelectedGcomponentItems(selectedNew);
  }

  const generateGTypeDetailsWithRawMaterials = () => {
    console.log(selectedGGlass);
    // const gcomponentItems = selectedGcomponentItems?.length > 0 ?  selectedGcomponentItems : selectedGGlass?.selectedGcomponentItems;
    const gcomponentItems = selectedGcomponentItems;
    return (
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {
            gtypeDetailsWithGComponent.map((gtypeDetail, i) => {
              return (
                <Grid container spacing={1} sx={{ mt: 1 }}>
                {
                  gtypeDetail.map((gcomponent, j) => {
                    // TODO : 같은 레벨의 properties안에서도 applyType이 productName/etc 섞여 있음
                    if (gcomponent.type === 'property' &&
                        gcomponent.properties &&
                        gcomponent.properties.length &&
                        gcomponent.properties.length > 0 &&
                        gcomponent.properties.filter(property => property.applyType === 'productName').length === gcomponent.properties.length // && // TODO : applyType이 "productName"이 조건이 아니라 별도의 속성이 추가해야 할 수도 있음(2023-03-31)
                        // gcomponent.properties.filter(property => property.applyType === 'productName').length > 0
                      ) {
                      console.log(gcomponent);
                      const materials = gcomponent.properties.filter(property => (property.itemType === "RAW_MATERIAL" || property.itemType === "SUB_MATERIAL_PROCESS"))
                      const manufacturers = gcomponent.properties.filter(property => property.id === getValues(`${i}_${j}_${gcomponent.id}`))[0];
                      console.log(manufacturers?.gclients);
                      return (
                        <>
                          <Grid item sx={{ width: 300 }}>
                            {
                              gcomponent.multipleYN ? (
                                <FormInputMultipleSelect
                                  id="multiple-type-select"
                                  // name={`${gcomponent.gtypeDetailId}_${gcomponent.id}`}
                                  name={`${i}_${j}_${gcomponent.id}`}
                                  control={control}
                                  label={gcomponent.name}
                                  setValue={setValue}
                                  onChangeItem={(value) => handleChangeMultiGcomponentItem(value, gcomponent, i, j, gtypeDetail)}
                                  // value={...}
                                  // setMultiselectValue={...}
                                  dynamicItems={{ selectedGcomponentItems: gcomponentItems, gtypeDetailsIndex: i, gcomponentItemIndex: j, gcomponentItem: gcomponent }} // selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex] 넘기면 값이 고정되므로 안됨
                                  options={gcomponent.properties}
                                  chipSize={"small"}
                                />
                              ) : (
                                // <FormInputAutoComplete // TODO : FormInputAutoComplete 사용하려면 외부에서 값을 설정하는 방법을 찾아야 함
                                <FormInputText
                                  select
                                  // <FormInputDropdown
                                  // name={`${gcomponent.gtypeDetailId}_${gcomponent.id}`}
                                  name={`${i}_${j}_${gcomponent.id}`}
                                  control={control}
                                  label={gcomponent.name}
                                  onChange={(e) => handleChangeGcomponentItem(e, gcomponent, i, j, gtypeDetail)}
                                  // onChangeSelectValue={(e) => handleChangeGcomponentItem(e, gcomponent, i, j, gtypeDetail)}
                                  options={
                                    [{ label: '없음', value: "" }].concat(gcomponent.properties.map(property => {
                                      return {
                                        label: property.name,
                                        value: property.id,
                                      }
                                    }))
                                  }
                                  // value={gcomponentItems[i][j].value.id} // FormInputAutoComplete 값 설정 방법이 value 셋팅으로는 안되고 FormInputText라도 고정됨
                                />
                              )
                            }
                          </Grid>
                          { 
                            materials.length > 0 && (
                              <Grid item sx={{ width: 300 }}>
                                <FormInputText
                                  select
                                  name={`${i}_${j}_${gcomponent.id}_gclient`}
                                  control={control}
                                  label={"제조사"}
                                  onChange={(e) => handleChangeManufacturer(e, gcomponent, i, j, gtypeDetail)}
                                  // onChangeSelectValue={(e) => handleChangeGcomponentItem(e, gcomponent, i, j, gtypeDetail)}
                                  options={
                                    [{ label: '없음', value: "" }] // TODO : "없음"은 제조사를 설정하지 않는 경우(?)를 위해 남겨둠
                                      .concat(manufacturers?.gclients ? manufacturers?.gclients.map(item => {
                                        return {
                                          label: item.name,
                                          value: item.id,
                                        }
                                      }) : [])
                                  }
                                  // value={gcomponentItems[i][j].value.id} // FormInputAutoComplete 값 설정 방법이 value 셋팅으로는 안되고 FormInputText라도 고정됨
                                />
                              </Grid>
                            )
                          }
                        </>
                      )
                    }
                  })
                }
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    )
  }

  const handleChangeSubMaterialItems = (e, item, i, itemType) => {
    console.log(item)
    // TODO : 의존성 여부 대응 필요
    const { value } = e.target;
    // setValue(`${item.id}_${i}`, value);
    
    const valueNew = item.gcomponentItems.find(gcomponentItem => gcomponentItem.id === value)
    let itemNew = {};
    
    let items = [];
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      items = selectedSubMaterialProcessItems;

      itemNew = {
        // id: item.id,
        // name: item.name,
        // value: valueNew,
        ...selectedSubMaterialProcessItems[i],
        value: valueNew,
      };
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      items = selectedSubMaterialBuildItems;

      itemNew = {
        // id: item.id,
        // name: item.name,
        // value: valueNew,
        ...selectedSubMaterialBuildItems[i],
        value: valueNew,
      };
    }
    
    // 아래처럼 하면 배열안에 null인 요소가 발생한다.
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    // if (selectedNew.length > 0) {
    //   selectedNew = selectedNew.map(selectedSubMaterial => {
    //     if (item.id === selectedSubMaterial.id) {
    //       return itemNew;
    //     }
  
    //     return selectedSubMaterial;
    //   });
    // } else {
    //   selectedNew.push(itemNew);
    // }
    
    console.log(selectedNew)
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setSelectedSubMaterialProcessItems(selectedNew);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setSelectedSubMaterialBuildItems(selectedNew);
    }
  }

  const handleChangeUsableCheckButton = (e, item, i, itemType) => {
    const { checked } = e.target;
    console.log(checked)
    // setValue(`${item.id}_usable_${i}`, checked);

    let itemNew = {};
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      itemNew = Object.assign({}, selectedSubMaterialProcessItems[i]);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      itemNew = Object.assign({}, selectedSubMaterialBuildItems[i]);
    }
    itemNew.usable = checked;
    itemNew.index = i;

    console.log(itemNew);
    console.log(selectedSubMaterialProcessItems)
    
    let items = [];
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      items = selectedSubMaterialProcessItems;
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      items = selectedSubMaterialBuildItems;
    }

    // 아래처럼 하면 배열안에 null인 요소가 발생한다.
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    console.log(selectedNew)
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setSelectedSubMaterialProcessItems(selectedNew);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setSelectedSubMaterialBuildItems(selectedNew);
    }
  }

  const handleChangeManufacturerForSubMaterialItems = (e, item, i, itemType) => {
    // setValue(`${item.id}_gclient_${i}`, e.target.value);
    
    // TODO : 제조사 설정 정보를 selectedSubMaterialProcessItems 에도 넣어야 함
    let items;

    if (itemType === 'SUB_MATERIAL_PROCESS') {
      items = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      items = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
    }

    console.log(item)
    console.log(items);
    items = items.map(selectedSubMaterial => {
      if (selectedSubMaterial && item.id === selectedSubMaterial.id && i === selectedSubMaterial.index) {
        selectedSubMaterial = {
          ...selectedSubMaterial,
          value : {
            ...selectedSubMaterial.value,
            selectedGClientId: e.target.value,
          }
        }
      }

      return selectedSubMaterial;
    });

    console.log(items);

    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setSelectedSubMaterialProcessItems(items);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setSelectedSubMaterialBuildItems(items);
    }
  }

  const handleChangeMultiProcessGClients = (id, g04docu, value, i) => {
    const itemNew = {
      id,
      name: g04docu.name,
      code: g04docu.code,
      value: value.map(i => JSON.parse(i)),
    };
    
    const items = selectedProcessGClients;

    // 아래처럼 하면 배열안에 null인 요소가 발생하는데, selectedProcessGClient가 이미 구성되어 있으므로 문제가 없는 것임
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    // console.log(selectedNew)

    setSelectedProcessGClients(selectedNew);
  }

  const addSubMaterial = (e, g04docu, i, itemType) => {
    // console.log(selectedSubMaterialProcessItems)
    
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setRenderSubMaterialProcessItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialProcess = JSON.parse(JSON.stringify(baseSubMaterialProcess));
      newBaseSubMaterialProcess.splice(i+1, 0, baseSubMaterialProcess[i]);
      setBaseSubMaterialProcess(newBaseSubMaterialProcess);

      const newSelectedSubMaterialProcessItems = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
      newSelectedSubMaterialProcessItems.splice(i+1, 0, {
        ...selectedSubMaterialProcessItems[i],
        value: {},
      });

      setTimeout(() => setRenderSubMaterialProcessItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialProcessItems(newSelectedSubMaterialProcessItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setRenderSubMaterialBuildItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialBuild = JSON.parse(JSON.stringify(baseSubMaterialBuild));
      newBaseSubMaterialBuild.splice(i+1, 0, baseSubMaterialBuild[i]);
      setBaseSubMaterialBuild(newBaseSubMaterialBuild);

      const newSelectedSubMaterialBuildItems = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
      newSelectedSubMaterialBuildItems.splice(i+1, 0, {
        ...selectedSubMaterialBuildItems[i],
        value: {},
      });

      setTimeout(() => setRenderSubMaterialBuildItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialBuildItems(newSelectedSubMaterialBuildItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    }
  }

  const handleSubMaterialUpDown = async (type, rownum, itemType) => {
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setRenderSubMaterialProcessItems(false);
      
      let newArr;
      // 부자재 목록
      let newRows = JSON.parse(JSON.stringify(baseSubMaterialProcess));

      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setBaseSubMaterialProcess(newArr);
      
      // 부자재 목록 선택 정보
      newRows = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setSelectedSubMaterialProcessItems(newArr.map((item, i) => ({
        ...item,
        index: i,
      })));

      setTimeout(() => setRenderSubMaterialProcessItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setRenderSubMaterialBuildItems(false);
      
      let newArr;
      // 부자재 목록
      let newRows = JSON.parse(JSON.stringify(baseSubMaterialBuild));

      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setBaseSubMaterialBuild(newArr);
      
      // 부자재 목록 선택 정보
      newRows = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setSelectedSubMaterialBuildItems(newArr.map((item, i) => ({
        ...item,
        index: i,
      })));

      setTimeout(() => setRenderSubMaterialBuildItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
    }
  }

  const removeSubMaterial = (e, g04docu, i, itemType) => {
    // console.log(selectedSubMaterialProcessItems)
    
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setRenderSubMaterialProcessItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialProcess = JSON.parse(JSON.stringify(baseSubMaterialProcess));
      newBaseSubMaterialProcess.splice(i, 1);
      // console.log(newBaseSubMaterialProcess);
      setBaseSubMaterialProcess(newBaseSubMaterialProcess);

      const newSelectedSubMaterialProcessItems = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
      // console.log(selectedSubMaterialProcessItems[i])
      newSelectedSubMaterialProcessItems.splice(i, 1);
      
      // console.log(newSelectedSubMaterialProcessItems.map((item, i) => ({
      //   ...item,
      //   index: i,
      // })));

      setTimeout(() => setRenderSubMaterialProcessItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialProcessItems(newSelectedSubMaterialProcessItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setRenderSubMaterialBuildItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialBuild = JSON.parse(JSON.stringify(baseSubMaterialBuild));
      newBaseSubMaterialBuild.splice(i, 1);
      // console.log(newBaseSubMaterialBuild);
      setBaseSubMaterialBuild(newBaseSubMaterialBuild);

      const newSelectedSubMaterialBuildItems = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
      // console.log(selectedSubMaterialBuildItems[i])
      newSelectedSubMaterialBuildItems.splice(i, 1);
      
      // console.log(newSelectedSubMaterialBuildItems.map((item, i) => ({
      //   ...item,
      //   index: i,
      // })));

      setTimeout(() => setRenderSubMaterialBuildItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialBuildItems(newSelectedSubMaterialBuildItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    }
  }

  const getItemsCount = (id, items) => {
    const matches = items.filter(item => {
      if (item.id === id) {
        return true;
      }

      return false;
    });

    return matches.length;
  }

  const generateGcomponentSubMaterialProcessItems = () => {
    if (renderSubMaterialProcessItems) {
      return (
        <Grid container spacing={2}>
        {
          baseSubMaterialProcess?.map((g04docu, i) => {
            const { id, name, gcomponentItems } = g04docu;
            const selectedId = selectedSubMaterialProcessItems[i]?.value?.id;
            const manufacturers = gcomponentItems?.find(gcomponentItem => gcomponentItem.id === selectedId);

            return (
              <Grid item sm={12}>
                <Grid container spacing={1}>
                  <Grid item sm={1} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
                    <FormInputCheckbox
                      name={`${id}_usable_${i}`}
                      control={control}
                      onChangeCheckValue={(e) => handleChangeUsableCheckButton(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                    />
                  </Grid>
                  <Grid item /*sx={{ width: 300 }}*/sm={4}>
                    <FormInputText
                      select
                      name={`${id}_${i}`}
                      control={control}
                      label={name}
                      onChange={(e) => handleChangeSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                      options={
                        [{ label: '없음', value: "" }].concat(gcomponentItems ? gcomponentItems.map(gcomponentItem => {
                          return {
                            label: gcomponentItem.name,
                            value: gcomponentItem.id,
                          }
                        }) : [])
                      }
                      // options={[]}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormInputText
                      select
                      name={`${id}_gclient_${i}`}
                      control={control}
                      label={"제조사"}
                      onChange={(e) => handleChangeManufacturerForSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                      options={
                        [{ label: '없음', value: "" }] // TODO : "없음"은 제조사를 설정하지 않는 경우(?)를 위해 남겨둠
                          .concat(manufacturers?.gclients ? manufacturers?.gclients.map(item => {
                            return {
                              label: item.name,
                              value: item.id,
                            }
                          }) : [])
                      }
                    />
                  </Grid>
                  <Grid item sm={3} display="flex" alignItems={"center"}>
                    <IconButton
                      // color="primary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => addSubMaterial(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      // color="secondary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => removeSubMaterial(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                      disabled={getItemsCount(id, baseSubMaterialProcess) <= 1 ? true : false}
                    >
                      <Remove />
                    </IconButton>
                    <IconButton
                      // color="secondary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => handleSubMaterialUpDown('up', i, 'SUB_MATERIAL_PROCESS')}
                      disabled={i > 0 ? false : true}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      // color="secondary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => handleSubMaterialUpDown('down', i, 'SUB_MATERIAL_PROCESS')}
                      disabled={i === baseSubMaterialProcess.length - 1 ? true : false}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
  }

  const generateGSubMaterialBuildItems = () => {
    if (renderSubMaterialBuildItems) {
      return (
        <Grid container spacing={2}>
        {
          baseSubMaterialBuild.map((g04docu, i) => {
            const { id, name, gcomponentItems } = g04docu;
            const selectedId = selectedSubMaterialBuildItems[i]?.value?.id;
            const manufacturers = gcomponentItems?.find(gcomponentItem => gcomponentItem.id === selectedId);
  
            return (
              <Grid item sm={12}>
                <Grid container spacing={1}>
                  <Grid item sm={1} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
                    <FormInputCheckbox
                      name={`${id}_usable_${i}`}
                      control={control}
                      onChangeCheckValue={(e) => handleChangeUsableCheckButton(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormInputText
                      select
                      name={`${id}_${i}`}
                      control={control}
                      label={name}
                      onChange={(e) => handleChangeSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                      options={
                        [{ label: '없음', value: "" }].concat(gcomponentItems ? gcomponentItems.map(gcomponentItem => {
                          return {
                            label: gcomponentItem.name,
                            value: gcomponentItem.id,
                          }
                        }) : [])
                      }
                      // options={[]}
                    />
                  </Grid>
                  <Grid item /*sx={{ width: 300 }}*/sm={4}>
                    <FormInputText
                      select
                      name={`${id}_gclient_${i}`}
                      control={control}
                      label={"제조사"}
                      onChange={(e) => handleChangeManufacturerForSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                      options={
                        [{ label: '없음', value: "" }] // TODO : "없음"은 제조사를 설정하지 않는 경우(?)를 위해 남겨둠
                          .concat(manufacturers?.gclients ? manufacturers?.gclients.map(item => {
                            return {
                              label: item.name,
                              value: item.id,
                            }
                          }) : [])
                      }
                    />
                  </Grid>
                  <Grid item sm={3} display="flex" alignItems={"center"}>
                    <IconButton
                      // color="primary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => addSubMaterial(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      // color="secondary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => removeSubMaterial(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                      disabled={getItemsCount(id, baseSubMaterialBuild) <= 1 ? true : false}
                    >
                      <Remove />
                    </IconButton>
                    <IconButton
                      // color="secondary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => handleSubMaterialUpDown('up', i, 'SUB_MATERIAL_BUILD')}
                      disabled={i > 0 ? false : true}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      // color="secondary"
                      sx={{ color: theme.palette.grey[600] }}
                      size="small"
                      onClick={(e) => handleSubMaterialUpDown('down', i, 'SUB_MATERIAL_BUILD')}
                      disabled={i === baseSubMaterialBuild.length - 1 ? true : false}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
  }

  const generateGProcesses = () => {
    return (
      <Grid container spacing={2}>
      {
        g04docuGCertificationsProcess?.map((g04docu, i) => {
          const { id, name } = g04docu;
          // TODO : id를 사용하여 gclients.gclientType04docus의 g04docuGCertificationId로 찾는다.
          const selectedGclients = gclients.filter(gclient => {
            if (gclient.gclientType04docus) {
              return gclient.gclientType04docus.find(gclientType04docu => gclientType04docu.g04docuGCertificationId === id );
            } else {
              return false;
            }
          });
          
          return (
            <Grid item sm={12} /*sx={{ width: 600 }}*/>
              {/* <FormInputText
                select
                name={id}
                control={control}
                label={name}
                // onChange={(e) => handleChangeSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                options={
                  [{ label: '없음', value: "" }].concat(selectedGclients ? selectedGclients.map(gclient => {
                    return {
                      label: gclient.name,
                      value: gclient.id,
                    }
                  }) : [])
                }
                // options={[]}
              /> */}
              <FormInputMultipleSelect
                id="multiple-type-select"
                name={id}
                label={name}
                control={control}
                setValue={setValue}
                onChangeItem={(value) => handleChangeMultiProcessGClients(id, g04docu, value, i)}
                // value={selectedProcessGClients[i]?.value} // && selectedProcessGClients.length > 0 ? selectedProcessGClients.find(item => item?.id === id)?.value : []}
                // setMultiselectValue={setSelectedProcessGClients}
                // value={...}
                // setMultiselectValue={...}
                dynamicGClients={{ selectedProcessGClients, i, selectedGclients: JSON.parse(JSON.stringify(selectedGclients)) }}
                options={selectedGclients}
                chipSize={"small"}
                // inputProps={
                //   { readOnly: crudMode === 'R' }
                // }
              />
            </Grid>
          )
        })
      }
      </Grid>
    )
  }

  const generateGProcesses1 = () => {
    return g04docuGCertificationsProcess?.map((g04docu, i) => {
      const { id, name } = g04docu;
      // TODO : id를 사용하여 gclients.gclientType04docus의 g04docuGCertificationId로 찾는다.
      const selectedGclients = gclients.filter(gclient => {
        if (gclient.gclientType04docus) {
          return gclient.gclientType04docus.find(gclientType04docu => gclientType04docu.g04docuGCertificationId === id );
        } else {
          return false;
        }
      });
      
      return (
        <Grid item sx={{ width: 300, mt: 2 }}>
          <FormInputMultipleSelect
            id="multiple-type-select"
            name={id}
            label={name}
            control={control}
            setValue={setValue}
            onChangeItem={(value) => handleChangeMultiProcessGClients(id, g04docu, value, i)}
            dynamicGClients={{ selectedProcessGClients, i, selectedGclients: JSON.parse(JSON.stringify(selectedGclients)) }}
            options={selectedGclients}
            chipSize={"small"}
            // inputProps={
            //   { readOnly: true }
            // }
            // disabled={true}
            disabled={selectedG04docuGCertifications?.filter(g04docu => g04docu.id === id && g04docu.checked).length > 0 ? false : true}
          />
        </Grid>
      )
    })
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={!fullScreen && PaperComponent} // fullScreen일때는 드래그 허용하지 않음
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          onMinMax={handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: fullScreen ? '' : 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          <div id="dialog-position" /*ref={dialogRef}*/>
          {/* <div
            id="dialog-position"
            ref={el => {
              if (el) {
                dialogTitleRect = el.getBoundingClientRect();
                // setDialogTitleBoundingClientRect()
              }
            }}
          > */}
            {"서류발급"}
          </div>
        </DialogTitleClose>
        <DialogContent>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <NavigationGuard when={editing} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Accordion expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}>
                <AccordionSummary aria-controls="basic-content" id="basic-header">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <Typography variant="h6" component="div">{"기본정보"}</Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={10} display="flex" alignItems="center">
                      {
                        !basicExpanded && (
                          <Stack direction="row" spacing={2}>
                            <Chip label={"현장명"} />
                            <Grid display="flex" alignItems="center">
                              {getValues('site')}
                            </Grid>
                            <Chip label={"현장주소"} />
                            <Grid display="flex" alignItems="center">
                              {getValues('siteAddress')}
                            </Grid>
                          </Stack>
                        )
                      }
                    </Grid> */}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'none' }}>
                      <FormInputText
                        name={"id"}
                        control={control}
                        label={"아이디"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name={"site"}
                        control={control}
                        label={"현장명"}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name={"siteAddress"}
                        control={control}
                        label={"현장주소"}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    {/* gclient 정보는 project 정보가 이미 저장되어 있다면 owner 정보, 그렇지 않다면  로그인 정보의 gclient 정보를 활용 */}
                    <Grid item xs={12} sm={6} sx={{ display: 'none' }}>
                      <FormInputText
                        name={"gclientId"}
                        control={control}
                        label={"업체아이디"}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name={"gclientName"}
                        control={control}
                        label={"업체명"}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        select
                        name={"constructionWorkTypeId"}
                        control={control}
                        label={"공종명"}
                        options={constructionWorkTypes.map(constructionWorkType => {
                          return {
                            label: constructionWorkType.name,
                            value: constructionWorkType.id,
                          }
                        })}
                        onEdit={checkEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <FormInputDate
                            name="startDate"
                            control={control}
                            label={"시작"}
                            onChangePeriodValue={(value) => handleChangePeriod(value, "startDate")}
                            value={getValues("startDate")}
                            setValue={setValue}
                            onEdit={checkEdit}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormInputDate
                            name="endDate"
                            control={control}
                            label={"마감"}
                            onChangePeriodValue={(value) => handleChangePeriod(value, "endDate")}
                            value={getValues("endDate")}
                            setValue={setValue}
                            onEdit={checkEdit}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
                          <div style={{ paddingLeft: '20px', backgroundColor: checkedDoneYN ? "#5dc061" : "#d32f2f", color: 'white', borderRadius: '5px' }}>
                            <FormInputSwitch
                              name={"doneYN"}
                              checked={checkedDoneYN}
                              onChange={handleChangeDoneYN}
                              control={control}
                              label={"마감여부"}
                              color="success"
                            />
                          </div>
                      </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"comments"}
                        control={control}
                        label={"설명"}
                        multiline
                        maxRows={5}
                        onEdit={checkEdit}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <Accordion expanded={generalDocu04Expanded} onChange={() => setGeneralDocu04Expanded(!generalDocu04Expanded)}>
                <AccordionSummary aria-controls="client-content" id="client-header">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                    <Typography variant="h6" component="div">{"기본서류"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      {
                        gclientG04GeneralDocuMaps?.map(g04GeneralDocu => {
                          const { id, name, code, documentPath } = g04GeneralDocu;
                          return (
                            <Button
                              variant={documentPath ? "contained" : "outlined"}
                              endIcon={documentPath ? 
                              <Tooltip
                                title={"다운로드"}
                              >
                                  <FilePresent onClick={(e) => handleClickAttachment(e, documentPath)} />
                              </Tooltip> : <></>}
                              // TODO : 여기서도 첨부할 수 있게 할 것인지 추후 논의
                              // onClick={() => handleClickGeneralDocu({ id, name, code, documentPath })}
                              sx={{ mr: 1 }}
                            >
                              {name}
                            </Button>
                          )
                        })
                      }
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion expanded={rawMaterialExpanded} onChange={() => setRawMaterialExpanded(!rawMaterialExpanded)}>
                <AccordionSummary aria-controls="glass-content" id="glass-header">
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography variant="h6" component="div">{"원자재"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item sm={12}>
                      <Grid container spacing={1}>
                        <Grid item sx={{ width: 300 }}>
                          <FormInputText
                            select
                            name={"gglassId"}
                            control={control}
                            label={"유리 템플릿"}
                            options={gglasses.map(gglass => {
                              return {
                                label: gglass.name,
                                value: gglass.id,
                              }
                            })}
                            onChange={handleChangeGGlass}
                          />
                        </Grid>
                        <Grid item sx={{ width: 300 }}>
                          <FormInputText
                            name={"no"}
                            control={control}
                            label={"품번"}
                          />
                        </Grid>
                        {/* <Grid item sx={{ width: 1200 }}>
                          <FormInputText
                            name={"no"}
                            control={control}
                            label={"세부정보"}
                            value={JSON.stringify(gtypeDetailsWithGComponent)}
                            multiline
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2 }}>
                      <Box sx={{ p: 0.8, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                        {"사양"}
                      </Box>
                    </Grid>
                    <Grid item sm={12}>
                    {
                      generateGTypeDetailsWithRawMaterials()
                    }
                    {
                      setGcomponentItems()
                    }
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2 }}>
                      {/* {"가공업체 선택"} */}
                      <Box sx={{ p: 0.8, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                        {"가공업체 선택"}
                      </Box>
                    </Grid>
                    {
                      generateGProcesses1()
                    }
                    <Grid container spacing={2}>
                      <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
                        <Button
                          variant={"contained"}
                          onClick={handleClickAdd}
                          sx={{ mr: 1 }}
                        >
                          {"추가"}
                        </Button>
                        <Button
                          variant={"contained"}
                          onClick={handleClickModify}
                          sx={{ mr: 1 }}
                          disabled={selectedId ? false : true}
                        >
                          {"수정"}
                        </Button>
                        <Button
                          variant={"contained"}
                          onClick={handleClickDelete}
                          sx={{ mr: 2 }}
                          disabled={selectedId ? false : true}
                        >
                          {"삭제"}
                        </Button>
                        <FormControlLabel
                          control={
                            <Switch 
                              checked={checked}
                              onChange={handleChangeOrder}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label={"순서"}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      {/* TODO : 214는 빈 그리드 높이, 57은 헤더 높이, 52는 행높이, 53은 footer 높이 => 1px 단위까지 정확하지는 않음 */}
                      <div style={{ height: rows.length === 0 ? 214 : 57 + rows.length*52 + 53, width: '100%' }}>
                        <Skeleton variant="rounded" height={showGrid ? 0 : '100%'} />
                        <DataGridPro
                          localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                          columnHeaderHeight={38}
                          rowHeight={34}
                          sx={{ visibility: showGrid ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                          initialState={{ pinnedColumns: { right: ['actions'] } }}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            loadingOverlay: LinearProgress,
                          }}
                          loading={!loaded}
                          rows={rows}
                          columns={columns}
                          autoHeight={true}
                          // pageSize={pageSize}
                          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                          // rowsPerPageOptions={[10, 20, 50, 100]}
                          // pagination
                          // onRowSelectionModelChange={(newRowSelectionModel) => { // mui document에는 있는데 ???
                          //   console.log(newRowSelectionModel)
                          // }}
                          onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = rows.filter((row) =>
                              selectedIDs.has(row.id),
                            );
                            
                            setSelectedRows(selectedRows);
            
                            // 현재는 단일 선택 기준임
                            if (selectedRows.length === 1) {
                              const { id, no, gglassId, gglassName, selectedGcomponentItems, selectedProcessGClients } = selectedRows[0];
                              
                              changeGGlass(gglassId);
                              console.log(selectedGcomponentItems);
                              console.log(selectedProcessGClients);
                              // TODO : 이것 말고 다른 방법이 있는가
                              // setSelectedGcomponentItems(selectedGcomponentItems);
                              setSelectedGcomponentItems(JSON.parse(JSON.stringify(selectedGcomponentItems)));
                              if (selectedProcessGClients) {
                                setSelectedProcessGClients(selectedProcessGClients);
                              } else {
                                g04docuGCertificationsProcess.length > 0 && setSelectedProcessGClients(g04docuGCertificationsProcess.map((item, i) => ({ id: item.id, name: item.name, index: i, usable: true, value: []})));
                              }
                              
                              setValue('no', no);
                              setValue('gglassId', gglassId);
                              // setValue('gglassName', gglassName);

                              const selGGlass = gglasses.find(gglass => gglass.id === gglassId);
                              if (selGGlass) {
                                setSelectedG04docuGCertifications(selGGlass.selectedG04docuGCertifications);
                              }

                              setSelectedId(ids[0])
                            }
                          }}
                          // onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <Accordion expanded={subMaterialProcessExpanded} onChange={() => setSubMaterialProcessExpanded(!subMaterialProcessExpanded)}>
                <AccordionSummary aria-controls="glass-content" id="glass-header">
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography variant="h6" component="div">{"가공부자재"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item sm={12}>
                    {
                      generateGcomponentSubMaterialProcessItems()
                    }
                    {
                      setSubMaterialProcessItems()
                    }
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={6}>
              <Accordion expanded={subMaterialBuildExpanded} onChange={() => setSubMaterialBuildExpanded(!subMaterialBuildExpanded)}>
                <AccordionSummary aria-controls="glass-content" id="glass-header">
                  <Grid container>
                    <Grid item sm={6}>
                      <Typography variant="h6" component="div">{"시공부자재"}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                  <Grid item sm={12}>
                    {
                      generateGSubMaterialBuildItems()
                    }
                    {
                      setSubMaterialBuildItems()
                    }
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="small"
            onClick={() => handleSubmit(onSubmit)('save')}
            loading={loading}
          >
            {"저장"}
          </LoadingButton>
          <Button onClick={() => handleSubmit(onSubmit)('saveAndClose')}>{"저장 후 닫기"}</Button>
          <Button onClick={() => {
            reset();
            // setPeriodValue(null, null); // TODO : 추후 오늘날짜로 초기화하는게 어떨지 결정
          }}>{"초기화"}</Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GProjectG04Dialog;
