import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const AlertDialog = ({
  alertInfo,
  setAlertInfo,
  setLoading,
  afterClose,
}) => {
  const handleClose = () => {
    alertInfo && setAlertInfo({
      ...alertInfo,
      open: false,
    });

    setLoading && setLoading(false);

    afterClose && afterClose()
  }

  const handleConfirm = async () => {
    try {
      if (alertInfo?.handleConfirm) {
        await alertInfo.handleConfirm();
      }
      handleClose();
    } catch (error) {
      console.error('Confirm action error:', error);
    }
  }

  return (
    <div>
      <Dialog
        open={Boolean(alertInfo?.open)}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertInfo?.titleAlert}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertInfo?.messageAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {alertInfo?.type === 'confirm' ? (
            <>
              <Button onClick={handleConfirm} color="primary">
                {alertInfo?.confirmText || '확인'}
              </Button>
              <Button onClick={handleClose} color="inherit">
                {alertInfo?.cancelText || '취소'}
              </Button>
            </>
          ) : (
            <Button onClick={handleClose} autoFocus>
              {alertInfo?.confirmText || '확인'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;