import React, { useState, useEffect, useMemo, useCallback, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Backdrop,
  Box, 
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  LinearProgress,
  Switch,
  Tooltip,
  Paper,
} from '@mui/material';
import Draggable from 'react-draggable';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  Search,
  OpenInNew,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash'; 
import { DialogTitleClose, PaperComponent, } from "../dialog";
import { CustomNoRowsOverlay, } from "../datagrid";
import { FormInputText } from "../form";
import AddressBookDialogRegister from './AddressBookDialogRegister';
import * as addressBookActions from '../../store/addressBook';
import { getGridHeight } from '../../constants/gridHeights';
import { formatMobileNumber } from '../../utils/formatters';

const defaultValues = {
  searchWord: ""
};

// 현재 화면에 맞는 Grid 높이 계산
const GRID_HEIGHT = getGridHeight('TWO_LINE_LAYER', 0); 

const AddressBookDialog = ({ open, setOpen, type, credential, email }) => {
  
  const {handleSubmit, reset, control, getValues, setValue } = useForm({ defaultValues: defaultValues });
  const [crudMode, setCrudMode] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errors, setErrors] = useState([]);
  const [publicYN, setPublicYN] = useState({});
  const [checked, setChecked] = useState(false);
  const [sort, setSort] = useState([]);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [initialFilter, setInitialFilter] = useState('전체');
  const searchInputRef = useRef(null);

  const dispatch = useDispatch();

  const selectAll = (searchWord, sort, initConst) => dispatch(addressBookActions.selectAll(searchWord, sort, initConst));
  const selectAddressBook = (seq) => dispatch(addressBookActions.select(seq));
  const createAddressBook = (data) => dispatch(addressBookActions.create(data));
  const modifyAddressBook = (data) => dispatch(addressBookActions.modify(data));
  const removeAddressBook = (seq) => dispatch(addressBookActions.remove(seq));

  const rows = useSelector((state) => state.addressBook.addressBooks);
  const rowCount = useSelector((state) => state.addressBook.count);
  const selectedRow = useSelector((state) => state.addressBook.addressBook);
  
  const initialButtons = [
    '전체',
    'ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ',
    //'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    //'0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
  ];
  const buttonStyle = {
    minWidth: '15px',        // 버튼의 최소 너비를 줄입니다
    //padding: '1px 5.5px',  // 한글 + 영문
    padding: '1px 22.5px',   // 한글
    fontSize: '0.7rem',      // 글자 크기를 줄입니다
  };

  // Dialog가 열릴 때마다 검색어 입력 필드에 포커스
  useEffect(() => {
    if (open) {
      selectAllAddressBooks();
      
      // setTimeout을 사용하여 Dialog가 완전히 열린 후 포커스
      const timer = setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (rows.length > 0) {
      const initialPublicYN = {};
      rows.forEach(row => {
        initialPublicYN[row.seq] = row.publicYN;
      });
      setPublicYN(initialPublicYN);
    }
  }, [rows]);

  // 주소록 목록 조회
  const selectAllAddressBooks = useCallback(() => {
    setLoaded(false);
    dispatch(addressBookActions.selectAll(getValues("searchWord"), sort))
      .then((response) => {
        console.log(response);
        searchInputRef.current?.focus(); // 항상 포커스 복원
      })
      .catch(error => {
        console.error('주소록 목록 조회 실패:', error);
        setLoaded(true);
      })
      .finally(() => {
        setLoaded(true);
        searchInputRef.current?.focus(); // 항상 포커스 복원
      });
  }, [dispatch, getValues, sort]); 

  // 검색어 검색 함수
  const debouncedSearchFn = useMemo(
    () => debounce((term) => {
      dispatch(addressBookActions.selectAll(term, sort)).finally(() => {
        // 데이터 로딩 완료 후 포커스 복원
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      });
    }, 300),
    [dispatch, sort]
  );
  
  // 검색어 입력 핸들러
  const handleChangeSearchWord = useCallback((e) => {
    const searchWord = e.target.value;
    setValue("searchWord", searchWord);
    debouncedSearchFn(searchWord);
  }, [debouncedSearchFn, setValue]);

  const handleInitialClick = (initial) => {
    setInitialFilter(initial);
    setLoaded(false);
    // "전체"가 아닐 경우에만 dispatch
    const initConst = initial !== "전체" ? initial : null;

    dispatch(addressBookActions.selectAll(getValues("searchWord"), sort, initConst))
    setLoaded(true);
  };


  // 검색버튼 클릭 핸들러
  const handleClickSearch = () => {
    selectAllAddressBooks();
  };

  // 새 주소 추가 다이얼로그 열기
  const handleClickRegister = () => {
    setCrudMode('C');
    setOpenRegisterDialog(true);
  };

  // 주소록 등록 성공 후 주소록 목록 다시 조회
  const handleRegisterSuccess = () => {
    selectAllAddressBooks(); // 주소록 목록 다시 조회
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page, pageSize]
  );

  
  const handlePublicYNChange = useCallback(async (e, params) => {

    e.stopPropagation();

    const newPublicYN = e.target.checked;
    const { id, row } = params;
    const updatedAddressBook = { ...row, publicYN:newPublicYN};

    //e.stopPropagation();
    //console.log('newPublicYN:', newPublicYN,'seq:', id, 'params:', params);
    
    // 로컬 상태 즉시 업데이트
    setPublicYN(prev => ({ ...prev, [row.seq]: newPublicYN }));

    try {
      // 서버에 변경 사항 전송
      await dispatch(addressBookActions.modify(updatedAddressBook));
    } catch (error) {
      console.error('공유 여부 변경 실패:', error);
      // 실패 시 원래 상태로 되돌리기
      setPublicYN(prev => ({ ...prev, [row.seq]: !newPublicYN }));
    }
  }, [dispatch]);

  // 주소록 선택 기능 구현
  const handleAction = useCallback(async ({ type, params }) => {
    const seq = params.row.seq;
    //console.log(type, params);

    setOpenBackdrop(true);

    switch (type) {
      case 'detail':
      case 'edit':
      case 'paste':
        setCrudMode(type === 'detail' ? 'R' : type === 'edit' ? 'U' : 'C');
        try {
          await selectAddressBook(seq);
          setOpenRegisterDialog(true);
        } catch (error) {
          console.error('주소록 선택 실패:', error);
        }
        break;
      case 'delete':
        if (window.confirm('정말로 삭제하시겠습니까?')) {
          try {
            await removeAddressBook(seq);
            selectAllAddressBooks();
          } catch (error) {
            console.error('주소록 삭제 실패:', error);
          }
        }
        break;
      default:
        break; 
    }

    setOpenBackdrop(false);
  }, [selectAllAddressBooks, selectAddressBook, removeAddressBook]);

  const generateActions = useCallback((params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleAction({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleAction({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleAction({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleAction({ type: 'delete', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }, [handleAction]);

  const columns = useMemo(() => 
    [
      { field: 'name', headerName: '이름', flex: 1.5 },
      { field: 'email', headerName: '이메일', flex: 2 },
      { field: 'phone', headerName: '모바일', flex: 1.5,
        renderCell: (params) => formatMobileNumber(params.value)
      },
      { field: 'company', headerName: '거래선명', flex: 2 },
      { field: 'duties', headerName: '직책', flex: 1 },
      { field: 'publicYN', headerName: '공유여부', flex: 1,
        renderCell: (params) => {
          const isPublic = publicYN[params.row.seq] ?? params.value;  
          return (
            <Tooltip title={isPublic ? "공유" : "비공유"} arrow key={`tooltip-${params.row.seq}-${isPublic}`}>
              <Switch
                checked={isPublic}
                onChange={(e) => handlePublicYNChange(e, params)}
                color="primary"
              />
            </Tooltip>
          );
        },
        renderHeader: (params) => (
          <Tooltip title="주소록 공유여부" arrow>
            <span>{params.colDef.headerName}</span>
          </Tooltip>
        ),
      },
      { field: 'actions', 
        headerName: '기능', 
        flex: 0.5, 
        type: 'actions', 
        getActions: (params) => generateActions(params),
        renderHeader: (params) => (
          <Tooltip title="상세/수정/복사하여 등록/삭제" followCursor>
            <Box>기능</Box>
          </Tooltip>
        ),
      },
    ], [generateActions, publicYN, handlePublicYNChange]
  );  
  
  const PaperComponent = forwardRef((props, ref) => {
    const nodeRef = useRef(null);
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        nodeRef={nodeRef}
      >
        <Paper {...props} ref={nodeRef}>
          {props.children}
        </Paper>
      </Draggable>
    );
  });
  
  
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleClose}
          style={{ cursor: 'move' }}
        >
          {"주소록"}
        </DialogTitleClose>
        <DialogContent>
          <Box sx={{ mt: 0 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <FormInputText
                  name={"searchWord"}
                  control={control}
                  label={"검색어(회사명/이름/이메일/모바일)를 입력하세요."}
                  onCustomChange={handleChangeSearchWord}
                  inputRef={searchInputRef}
                  inputProps={{             
                    autoComplete: 'off'     // 자동완성 비활성화
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  startIcon={<Search />}
                  onClick={handleClickSearch}
                  sx={{ mr: 1 }}
                >
                  {"검색"}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleClickRegister}
                >
                  {"등록하기"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ButtonGroup variant="outlined" size="small" aria-label="초성 검색">
                  {initialButtons.map((initial) => (
                    <Button 
                  key={initial} 
                  onClick={() => handleInitialClick(initial)}
                  variant={initialFilter === initial ? "contained" : "outlined"}
                  style={buttonStyle}
                >
                      {initial}
                    </Button>
                  ))}
                </ButtonGroup>  
              </Grid>
            </Grid>
            <div style={{ height: GRID_HEIGHT.FULL, width: '100%', marginTop: '20px' }}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                initialState={{ pinnedColumns: { right: ['actions'] } }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                }}
                columnHeaderHeight={38}
                rowHeight={34}
                getRowId={(row) => row.seq} // seq를 고유 ID로 사용
                rows={rows}
                rowCount={rowCount}
                loading={!loaded}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                onRowDoubleClick={(params) => handleAction({ type: 'edit', params })}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
      <AddressBookDialogRegister
        crudMode={crudMode}
        setCrudMode={setCrudMode}
        open={openRegisterDialog}
        setOpen={setOpenRegisterDialog}
        selectedRow={selectedRow}
        queryPaging={queryPaging}
        onSuccess={handleRegisterSuccess}
      />
    </>
  );
};

export default AddressBookDialog;