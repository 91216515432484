import { createTheme } from '@mui/material/styles';

const pretendardFont = [
  'Pretendard',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createTheme({
  typography: {
    fontFamily: pretendardFont,
    h1: { fontFamily: pretendardFont },
    h2: { fontFamily: pretendardFont },
    h3: { fontFamily: pretendardFont },
    h4: { fontFamily: pretendardFont },
    h5: { fontFamily: pretendardFont },
    h6: { fontFamily: pretendardFont },
    subtitle1: { fontFamily: pretendardFont },
    subtitle2: { fontFamily: pretendardFont },
    body1: { fontFamily: pretendardFont },
    body2: { fontFamily: pretendardFont },
    button: { fontFamily: pretendardFont, fontSize: 16, },
    caption: { fontFamily: pretendardFont },
    overline: { fontFamily: pretendardFont },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Pretendard';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('./assets/css/font.css');
        }
        body {
          font-family: ${pretendardFont};
        }

        // 다이얼로그 버튼 폰트 크기
        .MuiDialogActions-root > button {
          font-size: 16px !important;
        }

        // 토글버튼 그룹 스타일
        .MuiButtonBase-root.MuiToggleButton-root {
          margin-left: 5px !important;
          padding: 8px !important;
          border-radius: 25px !important;
          background-color: #EDEDED !important;
          border-color: #EDEDED !important;
        }
        
        // 다이알로그 제목 위치
        .MuiDialogTitle-root {
          padding: 34px 24px 25px 24px !important;
          font-size: 30px !important;
          font-weight: 600 !important;
        }
        .MuiDialogTitle-root p,
        .MuiDialogTitle-root span {
          font-size: 30px !important;
          font-weight: 600 !important;
        }
        // 다이알로그 내용 위치
        .MuiDialogContent-root {
          padding-top: 10px !important;
        }
        // 다이알로그 X버튼 위치
        .MuiIconButton-root[aria-label="close"] {
          margin-top: 25px !important;
        }
        // 다이알로그 X버튼 크기
        .MuiDialog-root .MuiDialogTitle-root .MuiSvgIcon-root[data-testid="CloseIcon"] {
          width: 35px !important;
          height: 35px !important;
        }
        // 다이알로그 전체크기 아이콘
        .MuiDialog-root .MuiSvgIcon-root[data-testid="ZoomInMapIcon"] {
          width: 30px !important;
          height: 35px !important;
        }
        .MuiDialog-root .MuiSvgIcon-root[data-testid="ZoomOutMapIcon"] {
          width: 30px !important;
          height: 35px !important;
        }
        // 다이알로그 하단 영역
        .MuiDialogActions-root.MuiDialogActions-spacing {
          padding: 15px 10px 32px 0px !important;
        }
        // 다이알로드 + 버튼 위치
        .MuiSpeedDial-fab {
          margin-top: 25px !important;
        }
        
        // 토글버튼 그룹 스타일
        .MuiButtonBase-root.MuiToggleButton-root {
          margin-left: 5px !important;
          padding: 8px !important;
          border-radius: 25px !important;
          background-color: #EDEDED !important;
          border-color: #EDEDED !important;
        }
        .MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
          color: #FFFFFF !important;
          background-color: #2EA2DE !important;
          border-color: #2EA2DE !important;
        }
        
        // 토글버튼 그룹 스타일 - 소형
        .MuiButtonBase-root.MuiToggleButton-root.MuiToggleButton-sizeSmall {
          height: 30px !important;
          line-height: 30px !important;
          border-radius: 30px !important;

          background-color: #EDEDED !important;
          border-color: #EDEDED !important;
        }
        .MuiButtonBase-root.MuiToggleButton-root.MuiToggleButton-sizeSmall.Mui-selected {
          color: #FFFFFF !important;
          background-color: #2EA2DE !important;
          border-color: #2EA2DE !important;
        }
        .MuiToggleButtonGroup-root {
          height: auto !important;
        }

        // Chip 높이 설정
        .MuiChip-root {
          height: 20px !important;
          line-height: 25px !important;
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: pretendardFont,
        },
      },
    },
  },
});

export default theme;