import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Stack,  
  TextField,
  ToggleButtonGroup, 
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import {
  FormInputDate,
  FormInputText,
} from "../form";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import { format } from 'date-fns'; 
import * as gclientActions from "../../store/gclient";
import * as dateUtils from '../../utils/dateUtils';
import { getGridHeight } from "../../constants/gridHeights";
import { PARTNERSHIP_TYPES, PARTNERSHIP_TYPE_LABELS } from "../../constants/gclientTypes";
import { MEMBERSHIP_TYPES, MEMBERSHIP_TYPE_LABELS } from "../../constants/gclientTypes";
import { uploadFilePath, fileServerUrl, mode } from '../../config';
import { filePreviewServerUrl } from '../../config';
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";

const theme = createTheme();

const defaultValues = {
  searchName: "",
  partnerships: [''],
  memberships: [''],
  serviceDate: "",
  cmsDocumentPath: "",
  signYn: "",
  cmsYn: "",
  serviceMonth: "",
  startPaidEndDate: "",
  endPaidEndDate: "",
  };

const GClientAssociation = ({ title, from }) => {

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [partnerships, setPartnerships] = useState(['']);
  const [memberships, setMemberships] = useState([MEMBERSHIP_TYPES[1]]);
  const searchInputRef = useRef("");
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 0); // 0px 추가 여백

  const handleClickViewCMSDocument = (documentPath, gclientId) => {
    let path = "";
    if (sessionUser.type === 'ADMIN' || sessionUser.id === gclientId) {
      const randNumber = Math.floor(Math.random()*99); // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
      const encodedPath = encodeURIComponent(documentPath.replace(uploadFilePath, ''));
      path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
    } else {
      path = `${filePreviewServerUrl}${encodeURIComponent(documentPath)}`;
    }
    // window.open(`${path}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  // CMS결제일자 변경버튼 액션
  const CmsPayDayCell = ({ value, row, id }) => {
    const [month, setMonth] = useState(value ? value.substring(0, 2) : '');
    const [day, setDay] = useState(value ? value.substring(2, 4) : '');
    const [isModified, setIsModified] = useState(false);
    
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Select
          value={month}
          onChange={(e) => {
            setMonth(e.target.value);
            setIsModified(true);
          }}
          size="small"
          sx={{ 
            width: 65,
            height: 25,
            '.MuiSelect-select': {
              padding: '2px 8px',
              fontSize: '0.75rem',
            }
          }}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <MenuItem 
              key={i} 
              value={String(i + 1).padStart(2, '0')}
              sx={{ fontSize: '0.75rem' }}
            >
              {`${i + 1}월`}
            </MenuItem>
          ))}
        </Select>
        
        <Select
          value={day}
          onChange={(e) => {
            setDay(e.target.value);
            setIsModified(true);
          }}
          size="small"
          sx={{ 
            width: 65,
            height: 25,
            '.MuiSelect-select': {
              padding: '2px 8px',
              fontSize: '0.75rem',
            }
          }}
          disabled={!month}
        >
          {Array.from({ length: month ? new Date(2024, parseInt(month), 0).getDate() : 0 }, (_, i) => (
            <MenuItem 
              key={i} 
              value={String(i + 1).padStart(2, '0')}
              sx={{ fontSize: '0.75rem' }}
            >
              {`${i + 1}일`}
            </MenuItem>
          ))}
        </Select>
  
        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            if (month && day) {
              try {
                await modifyCmsPayDay({ id, cmsPayDay: month + day });
                setIsModified(false);
                // 성공 시 목록 새로고침 필요하다면 추가
                // handleClickSearch();
              } catch (error) {
                console.error('Failed to update CMS pay day:', error);
              }
            }
          }}
          disabled={!isModified || !month || !day}
          sx={{ 
            minWidth: '35px',
            height: '25px',
            padding: '0 4px',
            fontSize: '0.75rem'
          }}
        >
          변경
        </Button>
      </Stack>
    );
  };

  // CMS결제금액 변경버튼 액션
  const CmsAmountCell = ({ value, row, id }) => {
    // 초기값에 콤마 추가
    const formatNumber = (num) => {
      if (!num) return '';
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    
    const [amount, setAmount] = useState(value ? formatNumber(value) : '');
    const [isModified, setIsModified] = useState(false);
  
    const handleAmountChange = (e) => {
      // 숫자와 콤마만 허용
      const newValue = e.target.value.replace(/[^\d,]/g, '');
      // 콤마 제거 후 숫자만 추출
      const numericValue = newValue.replace(/,/g, '');
      
      if (numericValue) {
        // 천단위 콤마 추가
        const formattedValue = Number(numericValue).toLocaleString();
        setAmount(formattedValue);
        setIsModified(true);
      } else {
        setAmount('');
        setIsModified(true);
      }
    };
  
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <TextField
          value={amount}
          onChange={handleAmountChange}
          size="small"
          sx={{ 
            width: 80,
            '& .MuiInputBase-input': {
              padding: '2px 8px',
              fontSize: '0.75rem',
              height: '21px',
            }
          }}
          inputProps={{
            style: { textAlign: 'right' }
          }}
        />
  
        <Button
          variant="contained"
          size="small"
          onClick={async () => {
            if (amount) {
              try {
                // API 호출 시 콤마 제거하고 숫자만 전송
                const numericAmount = amount.replace(/,/g, '');
                await modifyCmsAmount({ id, cmsAmount: numericAmount });
                setIsModified(false);
              } catch (error) {
                console.error('Failed to update CMS amount:', error);
              }
            }
          }}
          disabled={!isModified || !amount}
          sx={{ 
            minWidth: '35px',
            height: '25px',
            padding: '0 4px',
            fontSize: '0.75rem'
          }}
        >
          변경
        </Button>
      </Stack>
    );
  };

  const columnsBasic = [
    {
      field: 'name',
      headerName: '회사명',
      width: 180,
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 90,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'address',
      headerName: '주소',
      width: 210,
    },
    {
      field: 'partnership',
      headerName: '파트너쉽',
      width: 90,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'membership',
      headerName: '멤버쉽',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          MEMBERSHIP_TYPE_LABELS[params.value]
        )
      }
    },
    {
      field: 'cmsDocumentPath',
      headerName: 'CMS 신청서',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {params.row.cmsDocumentPath && (
              <Tooltip title={"CMS 신청서 보기"} placement="right-start">
                <IconButton
                  aria-label="view"
                  // edge="end"
                  onClick={() => handleClickViewCMSDocument(params.row.cmsDocumentPath, params.id)}
                  sx={{ color: "#1976D2" }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    {
      field: 'cmsPayDay',
      headerName: 'CMS결제일자',
      width: 220,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CmsPayDayCell 
          value={params.row.cmsPayDay} 
          row={params.row} 
          id={params.id} 
        />
      )
    },
    {
      field: 'cmsAmount',
      headerName: 'CMS결제금액',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <CmsAmountCell 
          value={params.row.cmsAmount} 
          row={params.row} 
          id={params.id}
        />
      )
    },
    {
      field: 'joinDate',
      headerName: '최초 가입일',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateUtils.dateFormat(params.value),
    },
    {
      field: 'serviceDate',
      headerName: '서비스 시작일',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateUtils.dateFormat(params.value),
    },
    {
      field: 'phone',
      headerName: '연락처',
      width: 120,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 200,
    },
   
  ];


  const sessionUser = useSelector(state => state.session.sessionUser);

  const rows = useSelector((state) => state.gclient.gclients);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllByParams = (params) => dispatch(gclientActions.selectAllByParams(params))
  const modifyCmsPayDay = ({ id, cmsPayDay }) => gclientActions.modifyCmsPayDay({ id, cmsPayDay })
  const modifyCmsAmount = ({ id, cmsAmount }) => gclientActions.modifyCmsAmount({ id, cmsAmount })
  
  // debounce된 검색 함수 생성
  const debouncedSearch = useCallback(
    debounce((searchName) => {
      setValue("searchName", searchName);
      handleClickSearch();
    }, 500),  // 500ms 딜레이
    [] // 컴포넌트가 마운트될 때만 생성
  );

  // 검색어 변경 핸들러
  const handleChangeSearchName = useCallback((e) => {
    const searchName = e.target.value;
    setValue("searchName", searchName);
   
    debouncedSearch(searchName);
  }, [debouncedSearch]);

  // 컴포넌트 언마운트 시 debounce 취소
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  
  // 검색 버튼 클릭 시 호출
  const handleClickSearch = () => {
    const params = {
      listtype: 'OPT01',
      signYn: getValues("signYn"),
      cmsYn: getValues("cmsYn"),
      name: getValues("searchName"),
      startPaidEndDate: null,
      endPaidEndDate: null
    };

    // 파트너쉽
    const currentPartnerships = getValues("partnerships");
    if (currentPartnerships?.length > 0 && !currentPartnerships.includes("")) {
      params.partnerships = [...currentPartnerships];
    }
    //console.log("params.partnerships:", params.partnerships);
    
    // 멤버쉽
    const currentMemberships = getValues("memberships");
    if (currentMemberships?.length > 0 && !currentMemberships.includes("")) {
      params.memberships = [...currentMemberships];
    }
    //console.log("params.memberships:", params.memberships);

    if (getValues("serviceMonth") !== "") {
      const dateRange = dateUtils.getPaidEndDateRange(getValues("serviceMonth"));
      params.startPaidEndDate = dateRange.startPaidEndDate;
      params.endPaidEndDate = dateRange.endPaidEndDate;
    }
    
    console.log("Search Params:", params);
    
    selectAllByParams(params);
  };

  // 파트너쉽 변경 핸들러
  const handlePartnershipChange = (event, newPartnership) => {

    const clickedValue = event.target.value;
    const isPressed = event.target.getAttribute('aria-pressed') === 'true';
    //console.log('clickedValue : ', clickedValue, 'isPressed : ', isPressed);
    //console.log('newPartnership : ', newPartnership, newPartnership.length);
    
    if (newPartnership.length === 0) {
      return;
    }
    
    let updatedPartnerships;
    if (clickedValue === '') {
      updatedPartnerships = [''];  // 전체만 활성화, 나머지 모두 비활성화
    } else {  
      // 전체가 아닌 경우
      updatedPartnerships = newPartnership.filter(value => value !== '');
    }

    console.log('updatedPartnerships : ', updatedPartnerships);

    setPartnerships(() => updatedPartnerships);
    setValue("partnerships", updatedPartnerships);
    
    handleClickSearch();
  };

  const handleMembershipChange = (event, newMembership) => {
    console.log('newMembership : ', newMembership, newMembership.length);
    if (newMembership.length === 0) {
      return;
    }
    let updatedMemberships;
    const filteredMemberships = newMembership.filter(value => value !== "");

    if (filteredMemberships.length >= MEMBERSHIP_TYPES.length) {
      updatedMemberships = [""];
    } else{
      updatedMemberships = filteredMemberships;
    }
    
    console.log('updatedMemberships : ', updatedMemberships, updatedMemberships.length);

    setMemberships(() => updatedMemberships);
    setValue("memberships", updatedMemberships);

    handleClickSearch();
  };


  useEffect(
    async () => {
      await selectAllByParams();
      
      setLoaded(true);
    }, [dispatch]
  );

  const cmsPaymentMonthOptions = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => ({
      label: `${i + 1}월`,
      value: String(i + 1).padStart(2, '0')  // '01', '02', ... '12'
    }));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid>
            {
              (from === undefined || from === null) && (
                <>
                  <Grid item xs={12} sm={10}>
                    <Stack direction="row" spacing={1}>
                      <FormInputText
                        name="searchName"
                        control={control}
                        label={"회사명 또는 사업자번호로 검색하세요."}
                        onCustomChange={handleChangeSearchName}
                        inputRef={searchInputRef}
                        sx={{
                          width: 250,
                          '& .MuiFormLabel-root' : {
                            fontSize: '0.85rem',
                          },
                          input: { fontSize: '0.85rem' }
                        }}
                      />
                      <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                        <Typography style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 1)', marginLeft: '40px' }}>
                          파트너쉽
                        </Typography>
                        <ToggleButtonGroup
                          value={partnerships}
                          onChange={handlePartnershipChange}
                          aria-label="파트너쉽"
                          multiple
                          size="small"
                          color="primary"
                          sx={{ height: 37 }}
                        >
                          <ToggleButton value="" aria-label="전체" color="primary">
                            전체
                          </ToggleButton>
                          {PARTNERSHIP_TYPES.map((type) => (
                            <ToggleButton key={type} value={type} aria-label={type} color="primary">
                              {PARTNERSHIP_TYPE_LABELS[type]}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </FormControl>
                      {/* <FormInputText
                        select
                        name="cmsPaymentMonth"
                        control={control}
                        label={"CMS결제월"}
                        options={cmsPaymentMonthOptions}
                        onChange={(e) => {
                          setValue("cmsPaymentMonth", e.target.value);
                          handleClickSearch();
                        }}
                        sx={{
                          width: 150, 
                          marginLeft: '20px',
                          '& .MuiFormLabel-root': {
                            fontSize: '0.85rem',
                          },
                          '& .MuiSelect-select': {
                            fontSize: '0.85rem',
                          }
                        }}
                      /> */}
                    </Stack>
                  </Grid>
                </>
              )
            }

          </Grid>
          <div style={{ height: GRID_HEIGHT.FULL, width: '100%', marginTop: 10 }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columnsBasic}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
            />
          </div>
        </Box>
        <DocumentViewerDrawer 
          open={documentViewerDrawerOpen}
          onClose={() => setDocumentViewerDrawerOpen(false)}
          url={currentUrl}
        />
      </Container>
    </ThemeProvider>
  );
};

export default GClientAssociation;
