import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Grid,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputAutoComplete1,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as userActions from "../../store/user";
import * as dialogActions from "../../store/components/dialog";
// import * as gclientActions from "../../store/gclient";

import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  userId: "",
  name: "",
  mobile: "",
};

const UserDialog = ({
  refresh,
}) => {
  const [phoneMask, setPhoneMask] = useState("000-0000-0000");
  const [error, setError] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const options = useSelector((state) => state.dialog.options);

  // options 변경 시 isOpen 상태 업데이트
  useEffect(() => {
    setIsOpen(options?.open || false);
  }, [options]);

  const handleDialogClose = () => {
    dispatch(dialogActions.setOptions({ open: false, crudMode: '' }));
setIsOpen(false);
    initDialog()
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setFadeIn(false);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const addUser = ({ id, userId, name, mobile, gclientId }) => dispatch(userActions.create({ id, userId, name, mobile, gclientId }))
  const modifyUser = ({ id, userId, name, mobile, gclientId }) => dispatch(userActions.modify({ id, userId, name, mobile, gclientId }))

  // const selectAllGClientDirect = () => gclientActions.selectValidAllDirect();

  const onSubmit = ({ id, userId, name, mobile }) => {
    const gclientId = sessionUser.id;
    // console.log({ id, userId, name, mobile, gclientId });
    // return;
    let func;
    if (options?.crudMode === 'U') {
      func = modifyUser;
    } else if ((options?.crudMode === 'C')) {
      func = addUser
    }
    func({ id, userId, name, mobile, gclientId })
      .then (res => {
        handleDialogClose();
        refresh(gclientId);
      })
      .catch (async (e) => {
        console.log(e)
        setError(e);
        setFadeIn(true);
        setTimeout(() => setFadeIn(false), 2000);
      });
  }

  // useEffect(
  //   () => {
  //     const selectGClients = async () => {
  //       const resGClients = await selectAllGClientDirect();
  //       console.log(resGClients)
  //       setGClients(resGClients);
  //     }

  //     selectGClients();
  //   }, [dispatch]
  // )


  useEffect(
    () => {
      if (options?.crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [options]
  );

  // useEffect(
  //   () => {
  //     if (selectedRow) {
  //       for (const [item, value] of Object.entries(defaultValues)) {
  //         setValue(item, selectedRow[item] || value);
  //       }

  //       // 그외 설정할 것들은 여기서 한다.
  //     }
  //   }, [selectedRow]
  // );

  const handleChangeMobile = (e) => {
    const { value } = e.target;
    
    if(value.length < 13) {
      setPhoneMask("000-000-00000"); // 한자리 여분을 두어야 함
    } else {
      setPhoneMask("000-0000-0000");
    }
    
    setValue('mobile', value);
  }

  return (
    <Dialog
    open={isOpen}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { options?.crudMode === 'C' && "회원사 사용자 등록"}
        { options?.crudMode === 'R' && "회원사 사용자 상세"}
        { options?.crudMode === 'U' && "회원사 사용자 수정"}
      </DialogTitleClose>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
            />
          </Grid>
          {/* TODO : 추후 시스템관리자가 회원사 사용자를 관리할 때 필요하면 추가 */}
          {/* <Grid item xs={12}>
            <FormInputAutoComplete1
              fullWidth
              control={control}
              required
              label={"회원사"}
              name={"gclientId"}
              // TODO : 우신복층유리 1차 오픈 위한 코드
              // options={[{ label: "전체", id: "NONE" }].concat(gclients.map(gclient => ({ label: gclient.name, id: gclient.id })))}
              options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
              setValue={setValue}
              // onCustomChange={handleChangeGClient}
              errorStyle={{ message: false, border: true }}
            />
          </Grid> */}
          <Grid item xs={12} display="flex" alignItems="end">
            <Fade in={fadeIn}>
              <Typography sx={{ color: "#ff5722" }}>
                { error?.message }
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              autoComplete="userId"
              required
              fullWidth
              label="이메일 아이디"
              name={"userId"}
              control={control}
              errorStyle={{ message: false, border: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              autoComplete="name"
              name={"name"}
              control={control}
              required
              fullWidth
              label={"이름"}
              errorStyle={{ message: false, border: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              autoComplete="mobile"
              name={"mobile"}
              control={control}
              required
              fullWidth
              label={"모바일"}
              InputProps={{
                inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                inputProps: {
                  style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                    // mask: "000-0000-0000",
                    mask: phoneMask,
                    definitions: {
                      '#': /[1-9]/,
                    },
                  },
                }
              }}
              errorStyle={{ message: false, border: true }}
              onChange={handleChangeMobile}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { options?.crudMode === 'C' && <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button> }
        { options?.crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        {/* TODO : 아래 코드 안되는 이유? */}
        {/* <Button onClick={reset}>{"초기화"}</Button> */}
        { options?.crudMode === 'C' && <Button onClick={() => reset()}>{"초기화"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
