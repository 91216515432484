import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Fab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Zoom,
  useScrollTrigger,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import GridViewIcon from '@mui/icons-material/GridView';
import {
  DialogTitleClose,
} from "../dialog";
import * as gclientActions from "../../store/gclient";
import * as securityRequestActions from "../../store/securityRequest";
import * as gprojectMapActions from "../../store/gprojectMap";
import * as gprojectActions from "../../store/gproject";
import * as gglassPerformanceActions from "../../store/gglassPerformance";
import SecurityRequest from './SecurityRequest';
import SecurityApproval from './SecurityApproval';
import ProjectParticipation from './ProjectParticipation';
import DomesticCertification from './DomesticCertification';
import ProjectWithPerformance from './ProjectWithPerformance';
import ExpiredDocuments from './ExpiredDocuments';


const theme = createTheme();

const Dashboard = () => {
  const [gclients, setGClients] = useState([]);
  const [displayMode, setDisplayMode] = useState('inline'); // 'inline' 또는 'dialog'
  const [sections, setSections] = useState({
    securityRequest: false,
    securityApproval: false,
    project: false,
    domesticAuth: false,
    projectWithPerformance: false,
    expiredDocuments: false,
  });
  const [domesticAuthCount, setDomesticAuthCount] = useState(0);
  const [expiredDocuments, setExpiredDocuments] = useState({
    certCnt: 0,  // 인증서 건수
    ntaxCnt: 0,  // 국세납입증명서 건수
    ltaxCnt: 0,  // 지방세납입증명서 건수
    testCnt: 0,  // 시험성적서 건수
    total: 0     // 전체 합계
  });

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rowsToRequest = useSelector((state) => state.securityRequest.securityToRequests);
  const rowsToApproval = useSelector((state) => state.securityRequest.securityToApprovals);
  const rowsJoinedProject = useSelector((state) => state.gprojectMap.gprojects);
  const rowsGDomesticAuthProject = useSelector((state) => state.gproject.gprojects);
  const publishedTotalCount = useSelector((state) => state.gglassPerformance.publishedTotalCount);

  const dispatch = useDispatch();

  const selecteSecurityToRequestsByQuery = (senderId) => dispatch(securityRequestActions.selecteSecurityToRequestsByQuery(senderId))
  const selecteSecurityToApprovalsByQuery = (receiverId) => dispatch(securityRequestActions.selecteSecurityToApprovalsByQuery(receiverId))
  const selectJoinedGProjectsByOwnerByQuery = (ownerId) => dispatch(gprojectMapActions.selectGProjectsByOwnerByQuery(ownerId));
  const selectAllProjectsOr4docuProjects = (docu04, searchName, searchSite) => dispatch(gprojectActions.selectAllProjectsOr4docuProjects(docu04, searchName, searchSite))
  const selectProjectsOr4docuProjectsByOwner = (docu04, gclientId, searchName, searchSite) => dispatch(gprojectActions.selectProjectsOr4docuProjects(docu04, gclientId, searchName, searchSite))
  const selectAllGClientDirect = () => gclientActions.selectAllDirect()
  const selectPublishedTotalCount = () => dispatch(gglassPerformanceActions.selectPublishedTotalCount())

  useEffect(() => {
    setDomesticAuthCount(
      rowsGDomesticAuthProject.map(p => {
        const { gdomesticAuths } = p;
        if (gdomesticAuths && Array.isArray(gdomesticAuths)) {
          return p.gdomesticAuths?.filter(g => g?.authYN === 'Y').length;
        }
        return 0;
      }).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      )
    );
  }, [rowsGDomesticAuthProject]);

  

  // 스크롤 트리거 및 스크롤 동작
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // 초기 데이터 로딩을 위한 함수들
  const loadSecurityCounts = async () => {
    // SecurityRequestApproval 컴포넌트에서 사용하는 카운트 조회 로직
    // const counts = await fetchSecurityCounts(); // API 호출
    updateCount('security', 1);
  };

  const loadProjectCount = async () => {
    // ProjectParticipation 컴포넌트에서 사용하는 카운트 조회 로직
    // const count = await fetchProjectCount(); // API 호출
    updateCount('project', 2);
  };

  const loadCertificationCount = async () => {
    // DomesticCertification 컴포넌트에서 사용하는 카운트 조회 로직
    // const count = await fetchCertificationCount(); // API 호출
    updateCount('certification', 3);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      const { id, type, name } = sessionUser;
      const resGClients = await selectAllGClientDirect();
      setGClients(resGClients);
      

      // 열람
      selecteSecurityToRequestsByQuery(id);
      selecteSecurityToApprovalsByQuery(id);

      // 프로젝트 참여 현황
      selectJoinedGProjectsByOwnerByQuery(id);

      // 국내가공유리제품 사전 인증서 발급건수
      const docu04 = true;
      if (type === 'ADMIN') { // 시스템 관리자
        selectAllProjectsOr4docuProjects(docu04);
      } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
        selectProjectsOr4docuProjectsByOwner(docu04, id);
      }

      // 성능확인서 발급건수
      selectPublishedTotalCount();

      // 문서현황 목록, IF-DOCSTAT-001
      const resExpiredDocuments = await gclientActions.selectDocuments({ status: 'DEFAULT', gclientName: name });
      console.log("resExpiredDocuments:", resExpiredDocuments);
      // 문서 개수 합계 계산
      const totalCounts = resExpiredDocuments.reduce((acc, doc) => {
        return {
          testCnt: acc.testCnt + (doc.testCnt || 0),
          ntaxCnt: acc.ntaxCnt + (doc.ntaxCnt || 0),
          ltaxCnt: acc.ltaxCnt + (doc.ltaxCnt || 0),
          certCnt: acc.certCnt + (doc.certCnt || 0),
        };
      }, { testCnt: 0, ntaxCnt: 0, ltaxCnt: 0, certCnt: 0});

      setExpiredDocuments({
        ...totalCounts,
        total: totalCounts.testCnt + totalCounts.ntaxCnt + totalCounts.ltaxCnt + totalCounts.certCnt
      });
    };

    loadInitialData();
  }, []);

  const handleClose = (sectionName) => {
    setSections(prev => ({
      ...prev,
      [sectionName]: false
    }));
  };

  // 섹션 토글 함수
  const toggleSection = (sectionName) => {
    console.log(sectionName);
    if (displayMode === 'dialog') {
      setSections(prev => ({
        ...prev,
        [sectionName]: !prev[sectionName]
      }));
    } else {
      // inline 모드에서는 다른 섹션들을 닫고 선택된 섹션만 토글
      setSections(prev => ({
        securityRequest: sectionName === 'securityRequest' ? !prev.securityRequest : false,
        securityApproval: sectionName === 'securityApproval' ? !prev.securityApproval : false,
        project: sectionName === 'project' ? !prev.project : false,
        domesticAuth: sectionName === 'domesticAuth' ? !prev.domesticAuth : false,
        projectWithPerformance: sectionName === 'projectWithPerformance' ? !prev.projectWithPerformance : false,
        expiredDocuments: sectionName === 'expiredDocuments' ? !prev.expiredDocuments : false,
      }));
    }
  };

  // 카운트 업데이트 함수
  const updateCount = (sectionName, count) => {
    setSections(prev => ({
      ...prev,
      [sectionName]: {
        ...prev[sectionName],
        count: count
      }
    }));
  };

  const renderContent = () => {
    if (displayMode === 'dialog') {
      return (
        <>
          <Dialog 
            open={sections.securityRequest} 
            onClose={() => handleClose('securityRequest')}
            maxWidth="lg"
            fullWidth
            fullScreen
          >
            <DialogTitleClose
              id="draggable-dialog-title"
              onClose={() => handleClose('securityRequest')}
              fullScreen={true}
              color="white"
              style={{ backgroundColor: "#1976d2" }}
            >
              <div id="dialog-position">
                열람요청
              </div>
            </DialogTitleClose>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <SecurityRequest />
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>

          <Dialog 
            open={sections.securityApproval} 
            onClose={() => handleClose('securityApproval')}
            maxWidth="lg"
            fullWidth
            fullScreen
          >
            <DialogTitleClose
              id="draggable-dialog-title"
              onClose={() => handleClose('securityApproval')}
              fullScreen={true}
              color="white"
              style={{ backgroundColor: "#1976d2" }}
            >
              <div id="dialog-position">
                열람승인
              </div>
            </DialogTitleClose>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <SecurityApproval />
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>

          <Dialog 
            open={sections.project} 
            onClose={() => handleClose('project')}
            maxWidth="lg"
            fullWidth
            fullScreen
          >
            <DialogTitleClose
              id="draggable-dialog-title"
              onClose={() => handleClose('project')}
              fullScreen={true}
              color="white"
              style={{ backgroundColor: "#1976d2" }}
            >
              <div id="dialog-position">
                참여프로젝트
              </div>
            </DialogTitleClose>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <ProjectParticipation gclients={gclients} />
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>

          <Dialog 
            open={sections.domesticAuth} 
            onClose={() => handleClose('domesticAuth')}
            maxWidth="lg"
            fullWidth
            fullScreen
          >
            <DialogTitleClose
              id="draggable-dialog-title"
              onClose={() => handleClose('domesticAuth')}
              fullScreen={true}
              color="white"
              style={{ backgroundColor: "#1976d2" }}
            >
              <div id="dialog-position">
                국내가공유리 인증서
              </div>
            </DialogTitleClose>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <DomesticCertification gclients={gclients} />
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>
          {/* 만료된 문서현황 */}
          <Dialog 
            open={sections.expiredDocuments} 
            onClose={() => handleClose('expiredDocuments')}
            maxWidth="lg"
            fullWidth
            fullScreen
          >
            <DialogTitleClose
              id="draggable-dialog-title"
              onClose={() => handleClose('expiredDocuments')}
              fullScreen={true}
              color="white"
              style={{ backgroundColor: "#2EA2DE" }}
            >
              <div id="dialog-position">
                만료된 문서현황
              </div>
            </DialogTitleClose>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <ExpiredDocuments expiredDocuments={expiredDocuments} />
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>

          <Dialog 
            open={sections.projectWithPerformance} 
            onClose={() => handleClose('projectWithPerformance')}
            maxWidth="lg"
            fullWidth
            fullScreen
          >
            <DialogTitleClose
              id="draggable-dialog-title"
              onClose={() => handleClose('projectWithPerformance')}
              fullScreen={true}
              color="white"
              style={{ backgroundColor: "#1976d2" }}
            >
              <div id="dialog-position">
                프로젝트별 성능확인서 조회
              </div>
            </DialogTitleClose>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <ProjectWithPerformance gclients={gclients} />
              </Box>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>
        </>
      );
    }

    // inline 모드
    return (
      <>
        {sections.securityRequest && <SecurityRequest />}
        {sections.securityApproval && <SecurityApproval />}
        {sections.project && <ProjectParticipation gclients={gclients} />}
        {sections.domesticAuth && <DomesticCertification gclients={gclients} />}
        {sections.projectWithPerformance && <ProjectWithPerformance gclients={gclients} />}
      </>
    );
  };

  const handleDisplayModeChange = (e, newValue) => {
    if (newValue) {
      // 디스플레이 모드 변경 시 모든 섹션 닫기
      setSections({
        securityRequest: false,
        securityApproval: false,
        project: false,
        domesticAuth: false,
        projectWithPerformance: false,
      });
      setDisplayMode(newValue);
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Container 
        component="main" 
        maxWidth="false" 
        style={{ 
          minHeight: 'calc(100vh - 386px)',  // footer 높이를 고려한 최소 높이
          paddingBottom: '100px'  // footer와의 간격 유지
        }}
      >
        <Box
          sx={{
            minHeight: 'calc(100vh - 410px)',
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
          }}
        >
          <CssBaseline />
          <Grid container spacing={2} sx={{ mt: 3, mb: 6.6, position: 'relative' }}>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <ToggleButtonGroup
                value={displayMode}
                exclusive
                size="small"
                onChange={handleDisplayModeChange}  // 여기를 수정
                sx={{ mb: 2 }}
              >
                <ToggleButton 
                  value="inline"
                  aria-label="인라인 모드"
                  sx={{ 
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      }
                    }
                  }}
                >
                  <Tooltip title="인라인 모드">
                    <ViewWeekIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton 
                  value="dialog"
                  aria-label="다이얼로그 모드"
                  sx={{ 
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      }
                    }
                  }}
                >
                  <Tooltip title="다이얼로그 모드">
                    <GridViewIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={() => toggleSection('securityRequest')}
                sx={{
                  p: 5, 
                  fontSize: '1.2rem', 
                  backgroundColor: '#2EA2DE',
                  borderRadius: 0, // 각진 모서리로 변경
                  // aspectRatio: '1.408', // 가로:세로 = 100:71 비율 설정 (1.408:1)
                  '&:hover': {
                    backgroundColor: '#2482B2',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>열람요청</span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>{rowsToRequest.length}</span>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={() => toggleSection('securityApproval')}
                sx={{
                  p: 5, 
                  fontSize: '1.2rem', 
                  backgroundColor: '#024878',
                  borderRadius: 0, // 각진 모서리로 변경
                  // aspectRatio: '1.408', // 가로:세로 = 100:71 비율 설정 (1.408:1)
                  '&:hover': {
                    backgroundColor: '#013A60',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>열람승인</span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>{rowsToApproval.length}</span>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={() => toggleSection('project')}
                sx={{
                  p: 5, 
                  fontSize: '1.2rem', 
                  backgroundColor: '#EE9300',
                  borderRadius: 0, // 각진 모서리로 변경
                  '&:hover': {
                    backgroundColor: '#BE7500',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>참여프로젝트</span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>{rowsJoinedProject.length}</span>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={() => toggleSection('domesticAuth')}
                sx={{
                  p: 5, 
                  fontSize: '1.2rem', 
                  backgroundColor: '#7A5E3B',
                  borderRadius: 0, // 각진 모서리로 변경
                  '&:hover': {
                    backgroundColor: '#624B2F',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>국내가공유리 인증서</span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>{domesticAuthCount}</span>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={() => toggleSection('expiredDocuments')}
                sx={{
                  p: 5, 
                  fontSize: '1.2rem', 
                  backgroundColor: '#E88A9E',
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#E88A90',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>만료된 문서현황</span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span style={{ fontSize: '20pt' }}>{expiredDocuments.total}</span>
                  </Grid>
                </Grid>
              </Button>
            </Grid> */}
            {
              (sessionUser.type === 'ADMIN' || sessionUser.bizRegNumber === '220-82-07388') && ( // 관리자와 협회만 볼 수 있음
                <Grid item xs={12} sm={3}>
                  <Button 
                    variant="contained" 
                    fullWidth 
                    onClick={() => toggleSection('projectWithPerformance')}
                    sx={{
                      p: 5, 
                      fontSize: '1.2rem', 
                      backgroundColor: '#727171',
                      borderRadius: 0, // 각진 모서리로 변경
                      '&:hover': {
                        backgroundColor: '#5C5C5C',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <span style={{ fontSize: '20pt' }}>성능확인서</span>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <span style={{ fontSize: '20pt' }}>{publishedTotalCount}</span>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              )
            }
            <Grid item xs={12} sm={12}>
              {renderContent()}
            </Grid>
            {/* 위로 가기 버튼 */}
            <Zoom in={trigger}>
              <Fab
                onClick={scrollToTop}
                color="primary"
                size="small"
                aria-label="scroll back to top"
                sx={{
                  position: 'fixed',
                  bottom: 16,
                  right: 16,
                }}
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </Zoom>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Dashboard;