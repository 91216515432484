import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ContentPaste,
  Delete,
  CheckCircleOutline,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Search,
  Filter1,
  Filter2,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputText,
  FormInputSwitch,
  FormInputToggleButton,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import * as gcomponentActions from "../../store/gcomponent";
import * as dialogActions from "../../store/components/dialog";

const defaultValues = {
  id: "",
  name: "",
  code: "",
  type: "none",
  comments: "",
  pid: "",
  pname: "",
  multipleYN: false,
};

const GComponentDialog = ({
  refresh,
}) => {
  
  const [showMultipleYN, setShowMultipleYN] = useState(false);
  const [checkedMultipleYN, setCheckedMultipleYN] = useState(false);
  const [openFindDialog, setOpenFindDialog] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [loaded, setLoaded] = useState(false);
  const [selectedRowFind, setSelectedRowsFind] = useState([]);

  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  const options = useSelector((state) => state.dialog.options);

  const selectedRow = useSelector((state) => state.gcomponent.gcomponent);
  const rowsFind = useSelector((state) => state.gcomponent.gcomponentsTreeFind);
  const selectedRows = useSelector((state) => state.datagrid.selectedRows);

  const addGComponent = ({ id, name, code, comments, pid, type, multipleYN }) => dispatch(gcomponentActions.create({ id, name, code, comments, pid, type, multipleYN }))
  const modifyGComponent = ({ id, name, code, comments, pid, type, multipleYN }) => dispatch(gcomponentActions.modify({ id, name, code, comments, pid, type, multipleYN }))
  const selectAllByQueryFind = (id) => dispatch(gcomponentActions.selectAllByQueryFind(id))
  const selectByPid = (pid) => dispatch(gcomponentActions.selectByPid(pid))

  useEffect(
    () => {
      if (options?.crudMode === 'C') {
        setValue("id", uuidv4());

        if (options?.values) {
          for (const [item, value] of Object.entries(options.values)) {
            setValue(item, value);
          }
        }
      }
    }, [options]
  );

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
        if (selectedRow["type"] === "property") {
          setShowMultipleYN(true);
        }

        setCheckedMultipleYN(selectedRow["multipleYN"] || defaultValues.multipleYN);
      }
    }, [selectedRow]
  );

  const columns = [
    // {
    //   field: 'name',
    //   headerName: '이름',
    //   width: 180,
    //   // editable: true,
    // },
    {
      field: 'code',
      headerName: '코드',
      width: 120,
      // editable: true,
    },
    // {
    //   field: 'gunitId',
    //   headerName: '단위아이디',
    //   width: 100,
    //   hide: true,
    //   // editable: true,
    // },
    // {
    //   field: 'gunitName',
    //   headerName: '단위',
    //   width: 140,
    //   // editable: true,
    //   description: "단위를 표시합니다.",
    // },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 200,
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 200,
    // },
    // {
    //   field: 'functions',
    //   headerName: "기능",
    //   width: 60,
    //   type: 'actions',
    //   hide: true,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<Edit />}
    //       label={"수정"}
    //       onClick={() => handleSelect({ type: 'edit', id: params.id })}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       icon={<Delete />}
    //       label={"삭제"}
    //       onClick={() => handleSelect({ type: 'delete', id: params.id })}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       icon={<Add />}
    //       label={"하위등록"}
    //       onClick={() => handleSelect({ type: 'addChild', id: params.id })}
    //       showInMenu
    //     />,
    //   ],
    // },
  ];

  const handleDialogClose = () => {
    dispatch(dialogActions.setOptions({ open: false, crudMode: '' }));

    initDialog();
  }

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setShowMultipleYN(false);
  }

  const handleChangeType = async (e, disabled) => {
    if (disabled) return;

    let show = false;
    if (e.target.value === "property") {
      show = true;
    }
    setShowMultipleYN(show);
  }

  const handleChangeMultipleYN = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요
    setValue("multipleYN", !checkedMultipleYN)
    setCheckedMultipleYN(!checkedMultipleYN);
  }

  // TODO : 추후 아이디 "중복확인" 추가할 것
  const onSubmit = ({ id, name, code, comments, pid, type, multipleYN }) => {
    let func;
    if (options?.crudMode === 'U') {
      // TODO : 자기 자신을 상위(또는 하위)로 가질 수 없으므로 id, pid 같이 않도록 유효성 체크 필요
      func = modifyGComponent;
    } else if (options?.crudMode === 'C') {
      func = addGComponent
    }    
    // console.log({ id, name, code, comments, pid, type, multipleYN });
    // return;
    
    func({ id, name, code, comments, pid, type, multipleYN })
      .then (res => {
        refresh && refresh();

        handleDialogClose();

        if (selectedRows.length === 1) {
          selectByPid(selectedRows[0].id);
        }
      })
      .catch (async (res) => {
        const data = await res.json();
        // if (data && data.errors) setErrors(data.errors);
      });
  }

  // 부모아이디 찾기 다이얼로그
  const handleClickFind = async () => {
    const id = getValues("id");
    await selectAllByQueryFind(id);
    
    setOpenFindDialog(true);
    setLoaded(true);
  }

  const handleFindDialogClose = () => {
    setLoaded(false);
    setOpenFindDialog(false);
  }
  
  return (
    <>
      {/* 구성요소 다이얼로그 */}
      <Dialog
        PaperProps={{style: {overflowY: 'visible'}}}
        open={options?.open || false}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          { options?.crudMode === 'C' && "구성요소 등록" }
          { options?.crudMode === 'R' && "구성요소 상세" }
          { options?.crudMode === 'U' && "구성요소 수정" }
        </DialogTitleClose>
        <DialogContent
          style={{ overflowY: 'visible' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInputToggleButton
                name="type"
                control={control}
                label="구분"
                exclusive
                options={[
                  {
                    label: "없음",
                    value: "none",
                  },
                  {
                    label: "그룹",
                    value: "group",
                  },
                  {
                    label: "속성",
                    value: "property"
                  },
                ]}
                onClick={(e) => handleChangeType(e, options?.crudMode === 'R' ? true : false)}
                disabled={options?.crudMode === 'R'}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
                inputProps={
                  { readOnly : true }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"name"}
                control={control}
                label={"이름"}
                inputProps={{ readOnly: options?.crudMode === 'R' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"code"}
                control={control}
                label={"코드"}
                inputProps={{ readOnly: options?.crudMode === 'R' }}
              />
            </Grid>
            {
              showMultipleYN && (<Grid item xs={12}>
                <FormInputSwitch
                  name={"multipleYN"}
                  checked={checkedMultipleYN}
                  onChange={handleChangeMultipleYN}
                  control={control}
                  label={"다중선택"}
                />
              </Grid>)
            }
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
                inputProps={{ readOnly: options?.crudMode === 'R' }}
              />
            </Grid>
            <Grid item xs={10} sx={{ display: 'none' }}>
              <FormInputText
                name={"pid"}
                control={control}
                label={"상위 구성요소아이디"}
                disabled
              />
            </Grid>
            <Grid item xs={10}>
              <FormInputText
                name={"pname"}
                control={control}
                label={"상위 구성요소명"}
                disabled
              />
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
              <Fab
                color="primary"
                size="small"
                variant="extended"
                onClick={handleClickFind}
                /**
                 * 찾기 버튼을 비활성해야 하는 경우
                 * 1. 상세보기일 경우
                 * 2. 하위등록일 경우 (crudMode가 'C'이고 pid가 설정된 경우)
                 */
                disabled={
                  options?.crudMode === 'R' || // 1
                  (options?.crudMode === 'C' && options?.values?.pid !== undefined && options?.values?.pid !== null && options?.values?.pid !== "") // 2
                }
              >
                <Search />
                {"찾기"}&nbsp;
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            options?.crudMode === 'C' && (<>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>)
          }
          { options?.crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      {/* 상위 구성요소 찾기/선택 다이얼로그 */}
      <Dialog
        open={openFindDialog}
        onClose={handleFindDialogClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitleClose
          onClose={handleFindDialogClose}
        >
          {"상위요소 선택"}
        </DialogTitleClose>
        <DialogContent>
          <Grid container>
            <div style={{ height: 400, width: '100%' }}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                treeData
                groupingColDef={{ headerName : "아이디 (하위요소 개수)" }}
                getTreeDataPath={(row) => row.path}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                }}
                loading={!loaded}
                rows={rowsFind}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                columnHeaderHeight={38}
                rowHeight={34}
                onRowClick={(params) => {
                  setOpenFindDialog(false);

                  const { id, name } = params.row;
                  setValue("pid", id);
                  setValue("pname", name);
                }}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFindDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GComponentDialog;