import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { SketchPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputText,
  FormInputSwitch,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gclientTypeActions from "../../store/gclientType";
import * as gclientType04docuActions from "../../store/gclientType04docu";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  gclientTypeId: "",
  g04docuGCertificationId: "",
  displayType: "",
  comments: "",
};

// const ITEM_HEIGHT = 48;

const GClientType04docuDialog = ({
  // modify,
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [hexColor, setHexColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [g04docuGCertificationDisabled, setG04docuGCertificationDisabled]  = useState(true);
  const [g04docuGCertification, setG04docuGCertification] = useState(null);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();
  
  const gclientTypes = useSelector((state) => state.gclientType.gclientTypes);
  const g04docuGCertifications = useSelector((state) => state.g04docuGCertification.g04docuGCertifications);

  // 데이터 관리
  const addGClientType04docu = ({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }) => dispatch(gclientType04docuActions.create({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }))
  const modifyGClientType04docu = ({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }) => dispatch(gclientType04docuActions.modify({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }))
  const selectAllGclientType = () => dispatch(gclientTypeActions.selectAll());
  const selectG04docuGCertificationByDivisionByQuery = (division) => dispatch(g04docuGCertificationActions.selectByDivisionByQuery(division))

  const onSubmit = ({ id, name, code, gclientTypeId, g04docuGCertificationId, displayType, comments }) => {
    setErrors([]);
    
    let func;
    if (crudMode === 'C') {
      func = addGClientType04docu;
    } else if (crudMode === 'U') {
      func = modifyGClientType04docu;
    }

    func({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color: hexColor })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      await selectAllGclientType(); // await를 하지 않으면 select 클릭시 아이템이 구성되지 않았다가 뒤늦게 추가되므로 await 사용
      await selectG04docuGCertificationByDivisionByQuery('PROCESS')
    }, [dispatch]
  );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
        setG04docuGCertificationDisabled(true);
      }
    }, [crudMode]
  );

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
        if (selectedRow["gclientTypeId"]) {
          const selectedGClientTypes = gclientTypes.filter(gclientType => gclientType.id === selectedRow["gclientTypeId"] && gclientType.code === 'FABRICATOR');
          if (selectedGClientTypes.length > 0) {
            setG04docuGCertificationDisabled(false);
          }
        }

        if (selectedRow["g04docuGCertificationId"]) {
          setG04docuGCertification(g04docuGCertifications.find(g04docuGCertification => g04docuGCertification.id === selectedRow["g04docuGCertificationId"]));
        }

        setHexColor(selectedRow?.color || "");
        setShowColorPicker(false);
      }

      // // selectedRow의 null 여부에 따라(수정 또는 등록) 값을 보여줄지 초기화될지 결정됨
      // ["id", "name", "code", "gclientTypeId", "displayType", "comments"].forEach(item => {
      //   if (item === "id") {
      //     setValue(item, selectedRow && selectedRow[item] || uuidv4());
      //   } else {
      //     setValue(item, selectedRow && selectedRow[item] || "")
      //   }
      // });

      // setHexColor(selectedRow?.color || "");
      // setShowColorPicker(false);
    }, [selectedRow]
  );

  const popover = {
    // position: "absolute",
    zIndex: "2"
  };
  
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    '&:hover': {
      backgroundColor: 'red',
    },
  };

  const handleChangeGclientType = (e) => {
    const { value } = e.target;
    setValue("gclientTypeId", value);
    
    const selectedGClientTypes = gclientTypes.filter(gclientType => gclientType.id === value && gclientType.code === 'FABRICATOR');
    if (selectedGClientTypes.length > 0) {
      setG04docuGCertificationDisabled(false);
    } else {
      setG04docuGCertificationDisabled(true);
    }
  }

  const handleChangeG04docuGCertification = (e) => {
    const { value } = e.target;
    setValue("g04docuGCertificationId", value); // change이벤트 잡으면 setValue 하지 않으면 값이 설정되지 않는다.

    setG04docuGCertification(g04docuGCertifications.find(g04docuGCertification => g04docuGCertification.id === value));
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {crudMode === 'C' && "거래선 구분 등록"}
        {crudMode === 'R' && "거래선 구분 상세"}
        {crudMode === 'U' && "거래선 구분 수정"}
      </DialogTitleClose>
      <DialogContent>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              select
              name="gclientTypeId"
              control={control}
              label={"거래선 구분"}
              // sx={{ width: 300 }}
              options={
                [{ label: '없음', value: undefined }].concat(gclientTypes.map(gclientType => {
                  return {
                    label: gclientType.name,
                    value: gclientType.id,
                  }
                }))
              }
              onChange={handleChangeGclientType}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              select
              name="g04docuGCertificationId"
              control={control}
              label={"규격"}
              // sx={{ width: 300 }}
              options={
                [{ label: '없음', value: null }].concat(g04docuGCertifications.map(g04docuGCertification => {
                  return {
                    label: <><Chip label={g04docuGCertification.gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{g04docuGCertification.name}</>,
                    value: g04docuGCertification.id,
                  }
                }))
              }
              onChange={handleChangeG04docuGCertification}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
              disabled={g04docuGCertificationDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"displayType"}
              control={control}
              label={"표시명"}
              inputProps={
                { readOnly: crudMode === 'R', maxLength: 1 }/*, 콤마 있으면 parsing 오류*/
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            {/* TODO : 읽기전용 기능 추가 필요 */}
            <Button
              fullWidth
              variant={hexColor === "" ? "outlined" : "contained"}
              sx={{
                bgcolor: hexColor,
                ':hover': {
                  bgcolor: hexColor,
                },
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              {hexColor === "" ? "색상없음" : "색상"}
            </Button>
            {
              showColorPicker && (
                <div style={popover}>
                  <div style={cover} onClick={() => setShowColorPicker(false)} />
                  <SketchPicker
                    color={hexColor}
                    onChange={(color) => {
                      setHexColor(color.hex);
                      // console.log(color)
                    }}
                    // onSwatchHover={(color, e) => {
                    //   console.log("color", color);
                    // }}
                  />
                </div>
              )
            }
              
          </Grid>
          
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
        }
        {
          crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button>
        }
        {/* TODO : 아래 코드 안되는 이유? */}
        {/* <Button onClick={reset}>{"초기화"}</Button> */}
        {
          crudMode === 'C' && <Button onClick={() => reset()}>{"초기화"}</Button>
        }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GClientType04docuDialog;
