// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { authServerUrl, gcoreServerUrl } from '../config';

const SET_GCLIENT = 'gclient/setGClient';
const REMOVE_GCLIENT = "gclient/removeGClient";
const SET_GCLIENTS = 'gclient/setGClients';

const setGClient = (gclient) => ({
  type: SET_GCLIENT,
  payload: gclient,
});

const removeGClient = () => ({
  type: REMOVE_GCLIENT,
});

export const setGClients = (gclients) => ({
  type: SET_GCLIENTS,
  payload: gclients,
});

export const create = ({ id, gclientTypes, gclientType04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, zipCode, phone2, otherPhones, address2, otherAddresses, membership, partnership, joinDate, createdAt, products }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`, {
    method: "POST",
    body: JSON.stringify({ id, gclientTypes, gclientType04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, zipCode, phone2, otherPhones, address2, otherAddresses, membership, partnership, joinDate, createdAt, products }),
  });
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return response;
};

export const modify = ({ id, gclientTypes, gclientType04docus, name, phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, zipCode, phone2, otherPhones, address2, otherAddresses, membership, partnership, joinDate, createdAt, products }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`, {
    method: "PUT",
    body: JSON.stringify({ id, gclientTypes, gclientType04docus, name, phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, zipCode, phone2, otherPhones, address2, otherAddresses, membership, partnership, joinDate, createdAt, products }),
  });
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return response;
};

export const modifyAndGetData = ({ id, gclientTypes, gclientType04docus, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership, partnership }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/`, {
    method: "PUT",
    body: JSON.stringify({ id, gclientTypes, gclientType04docus, name, /*code, */phone, address, email, inCharges, systemUrl, phrases, /*comments, */initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership, partnership }),
  });
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return data.gclient;
};

export const leave = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/leave/${id}`, {
    method: "PUT",
  });
  dispatch(setGClient(null));
  return response;
};

export const rejoin = (bizRegNumber) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/rejoin/bizRegNumber/${bizRegNumber}`, {
    method: "PUT",
  });
  dispatch(setGClient(null));
  return response;
};

export const rejoinDirect = async (bizRegNumber) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/rejoin/bizRegNumber/${bizRegNumber}`, {
    method: "PUT",
  });
  const data = await response.json();
  return data.result;
};

export const modifyRejectAlarmEmailYN = ({ id, rejectAlarmEmailYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/rejectAlarmEmail/${id}`, {
    method: "PUT",
    body: JSON.stringify({ rejectAlarmEmailYN }),
  });
  dispatch(setGClient(null));
  return response;
};

export const selectAll = (valid) => async dispatch => {
  const url = new URL(`${authServerUrl}/api/gclients/`);
  if (valid !== undefined) {
    url.searchParams.append('valid', valid);
  }
  
  const response = await csrfFetch(dispatch, url.toString());
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectAllWithFields = (valid, fields) => async dispatch => {
  const url = new URL(`${authServerUrl}/api/gclients/comboData`);
  if (valid !== undefined) {
    url.searchParams.append('valid', valid);
  }

  if (Array.isArray(fields) && fields.length > 0) {
    fields.forEach(field => {
      url.searchParams.append('fields', field);
    });
  }

  const response = await csrfFetch(dispatch, url.toString());
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectAllDirect = async (valid) => {
  const url = new URL(`${authServerUrl}/api/gclients/`);
  if (valid !== undefined) {
    url.searchParams.append('valid', valid);
  }
  
  const response = await csrfFetch(null, url.toString());
  const data = await response.json();
  return data.gclients;
}

export const selectValidAllDirect = async () => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/valid`);
  const data = await response.json();
  return data.gclients;
}

export const selectGClientsDirect = async (id) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/comboData?valid=true`);
  const data = await response.json();
  return data.gclients;
}

export const selectAllByIds = (ids) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/select/ids`, {
    method: "POST",
    body: JSON.stringify({ ids }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectAllByParams = (params = {}) => async dispatch => {
  // URLSearchParams 대신 직접 쿼리 문자열 구성
  let queryParts = [];

  // gclientTypes, inCharges, gclientType04docus, phrases, mailSettings 필드 제외.
  if (params.listtype !== undefined && params.listtype !== '') {
    queryParts.push(`listtype=OPT01`);
  }
  
  // valid 파라미터 추가
  queryParts.push(`valid=${params.valid ?? true}`);
  
  // signYn 파라미터 추가
  if (params.signYn !== undefined && params.signYn !== '') {
    queryParts.push(`signYn=${params.signYn}`);
  }
  
  // cmsYn 파라미터 추가
  if (params.cmsYn !== undefined && params.cmsYn !== '') {
    queryParts.push(`cmsYn=${params.cmsYn}`);
  }
  
  // 날짜 파라미터 추가
  if (params.startPaidEndDate) {
    //queryParts.push(`startPaidEndDate=${params.startPaidEndDate.replace(/ /g, '%20')}`);
    queryParts.push(`startPaidEndDate=${encodeURIComponent(params.startPaidEndDate)}`);
  }
  
  if (params.endPaidEndDate) {
    //queryParts.push(`endPaidEndDate=${params.endPaidEndDate.replace(/ /g, '%20')}`);
    queryParts.push(`endPaidEndDate=${encodeURIComponent(params.endPaidEndDate)}`);
  }

  // Array 파라미터 - memberships
  if (Array.isArray(params.memberships) && params.memberships.length > 0) {
    params.memberships.forEach(membership => {
      queryParts.push(`memberships=${encodeURIComponent(membership)}`);
    });
  }
  
  // Array 파라미터 - partnerships
  if (Array.isArray(params.partnerships) && params.partnerships.length > 0) {
    params.partnerships.forEach(partnership => {
      queryParts.push(`partnerships=${encodeURIComponent(partnership)}`);
    });
  }

  // String 파라미터 - name
  if (params.name?.trim()) {
    queryParts.push(`name=${encodeURIComponent(params.name.trim())}`);
  }

  const queryString = queryParts.join('&');

  //console.log("Input values:", params);
  console.log("Final params:", queryString);

  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/?${queryString}`);
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectDirect = async (id) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${id}`);
  const data = await response.json();
  return data.gclient;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/${id}`);
  const data = await response.json();
  dispatch(setGClient(data.gclient));
  return response;
}

export const selectEmailByBizRegNumberDirect = async (bizRegNumber) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/selectEmail/bizRegNumber/${bizRegNumber}`);
  const data = await response.json();
  return data.email;
}

export const init = () => dispatch => {
  dispatch(setGClient(null));
}

export const selectByTypeByQuery = (type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByType/${type}`);
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectByType04docusByQuery = (type04docus) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByType04docus`, {
    method: "POST",
    body: JSON.stringify({ type04docus }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectByType04docusByQueryDirect = async (type04docus) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectByType04docus`, {
    method: "POST",
    body: JSON.stringify({ type04docus }),
  });
  const data = await response.json();
  return data.gclients;
}

export const selectByG04GCertificationCodesByQuery = (g04GCertificationCodes) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByG04GCertificationCodes`, {
    method: "POST",
    body: JSON.stringify({ g04GCertificationCodes }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const selectByG04GCertificationIdsByQuery = (g04GCertificationIds) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/query/selectByG04GCertificationIds`, {
    method: "POST",
    body: JSON.stringify({ g04GCertificationIds }),
  });
  const data = await response.json();
  dispatch(setGClients(data.gclients));
  return response;
}

export const remove = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGClient());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

export const modifyRowOrder = async ({ id, type, toOrder }) => {

  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id, toOrder }),
  });

  return response;
}

export const uploadFileDirect = async (gclientId, formData) => {
  const options = {
    method: 'POST',
    // headers: { 'Content-Type': 'multipart/form-data' }, // TODO : 왜 이것을 지정하면 서버에서 못받지??? 추후 분석
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    },
    body: formData,
  }

  // TODO : 아래에서 오류 발생하여 바꾸었는데(사실 같은 방식인데 차이점을 모르겠음) 추후 분석
  // const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${gclientId}/uploadFile`, {
  //   method: "POST",
  //   // headers: {
  //   //   'Content-Type': 'multipart/form-data',
  //   //   // 'Content-type': 'x-www-form-urlencoded',
  //   // },
  //   body: formData,
  // });
  const response = await window.fetch(`${authServerUrl}/api/gclients/${gclientId}/uploadFile`, options);
  
  const data = await response.json();
  return data.file;
};

// TODO : 테스트
export const uploadFileDirect1 = async (gclientId, formData) => {
  const options = {
    method: 'POST',
    body: formData,
  }

  const response = await window.fetch(`${authServerUrl}/api/gclients/${gclientId}/uploadFile1`, options);
  
  const data = await response.json();
  return data.file;
};

export const downloadDirect = async (documentPath) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/downloadFile`, {
    method: "POST",
    body: JSON.stringify({ file: documentPath }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  // const data = await response.json();
  // return data;
  return response;
};

export const removeDirect = async (gclientId, documentPath) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${gclientId}/deleteFile`, {
    method: "DELETE",
    body: JSON.stringify({ file: documentPath }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  const data = await response.json();
  return data.file;
};

export const uploadCmsFile = (id, file, partnership, membership) => async (dispatch) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('partnership', partnership);
    formData.append('membership', membership);

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      body: formData,
    };

    const response = await window.fetch(`${authServerUrl}/api/gclients/${id}/uploadCmsFile`, options);

    const data = await response.json();
    return data.file;

};

// CMS 결제일자 변경
export const modifyCmsPayDay = async ({ id, cmsPayDay }) => {

  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${id}/updateCmsInfo`, {
    method: "PUT",
    body: JSON.stringify({ cmsPayDay }),
  });

  return response;
}

// CMS 결제금액 변경
export const modifyCmsAmount = async ({ id, cmsAmount }) => {

  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/${id}/updateCmsInfo`, {
    method: "PUT",
    body: JSON.stringify({ cmsAmount }),
  });

  return response;
}

// CMS 정산 목록 조회
export const selectCmsPayInfos = async (cmsPayMonth) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclients/cmsPayInfos?cmsPayMonth=${cmsPayMonth}`, {
    method: "GET",
  });
  const data = await response.json();
  return data.gclients;
}

// 문서현황 목록, IF-DOCSTAT-001
export const selectDocuments = async (params) => {
  const url = new URL(`${gcoreServerUrl}/api/g04docuStatus`);

  if (params.status) {
    url.searchParams.append('status', params.status);
  }
  if (params.gclientName) {
    //url.searchParams.append('gclientName', encodeURIComponent(params.gclientName));
    url.searchParams.append('gclientName', params.gclientName);
  }

  const response = await csrfFetch(null, url.toString());

  const data = await response.json();
  return data.g04docuStatusList;
}

// 거래선 문서목록, IF-DOCSTAT-002
export const selectGClientDocuments = async (gclientId, docType, status) => {
  let url = new URL(`${gcoreServerUrl}/api/g04docuStatus/gclient/${gclientId}`);
  
  if (docType) {
    url.searchParams.append('docType', docType);
  }

  // DEFAULT- 만료문서만, UNREG, 미등록 문서, ONEWEEK: 만료일 1주일 이내 문서
  if (status) {
    url.searchParams.append('status', status);
  }

  const response = await csrfFetch(null, url.toString());
  const data = await response.json();
  return data.g04docuStatus;
}

// 거래선 문서목록 상세, IF-DOCSTAT-003
export const selectGClientDocumentsDetail = async (gclientId, docType, status) => {
  let url = new URL(`${gcoreServerUrl}/api/g04docuStatus/docList/gclient/${gclientId}`);
  
  if (docType) {
    url.searchParams.append('docType', docType);
  }

  // DEFAULT- 만료문서만, UNREG, 미등록 문서, ONEWEEK: 만료일 1주일 이내 문서
  if (status) {
    url.searchParams.append('status', status);
  }

  const response = await csrfFetch(null, url.toString());
  const data = await response.json();
  return data.g04docuStatusDocList;
}

const initialState = {
  gclient: null,
  gclients: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GCLIENT:
      newState = Object.assign({}, state, { gclient: action.payload });
      return newState;
    case REMOVE_GCLIENT:
      newState = Object.assign({}, state, { gclient: null });
      return newState;
    case SET_GCLIENTS:
      newState = Object.assign({}, state, { gclients: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
