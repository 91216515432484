import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Fab, Zoom, useScrollTrigger
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Email,
  Password,
  ContactMail,
  AddressBook, 
  PersonAdd,
  Edit,
} from '@mui/icons-material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  FormInputText,
} from "../../components/form";
import {
  AlertDialog,
  ConfirmDialog,
} from "../../components/dialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom"
import { uploadFilePath, fileServerUrl } from '../../config';
import { objectEmptyCheck } from "../../utils";
import * as sessionActions from "../../store/session";
import * as menuActions from '../../store/menu';
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gclientTypeActions from "../../store/gclientType";
import * as gclientType04docuActions from "../../store/gclientType04docu";
import * as gclientActions from "../../store/gclient";
import * as userActions from "../../store/user";

import CertifiedBox from "./CertifiedBox";
import PasswordChangeDialog from "../Password/PasswordChangeDialog";
import EmailSettingDialog from "./EmailSettingDialog";
import Users from "./Users";
import Security from "./Security";
import G04GeneralDocu from "./G04GeneralDocu";
import G04docuGCertification from "./G04docuGCertification";
import G04docuGTestRawMaterial from "./G04docuGTestRawMaterial";
import G04docuGTestSubMaterial from "./G04docuGTestSubMaterial";
import G04docuGTestProcess from "./G04docuGTestProcess";
import './Profile.css';
import G04docuEtc from './G04docuEtc';
import ScrollToTop from '../../utils/scrollUtils';
import AddressBookDialog from "./AddressBookDialog";
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";

const defaultValues = {
  id: "",
  name: "",
  bizRegNumber: "",
  password: "",
  confirmPassword: "",
  phone: "",
  fax: "",
  address: "",
  ceo: "",
  email: "",

  // 날인문구
  // supplierLabel: "제공사",
  supplierLabel: "",
  supplier: "",
  phrase: "",

  userId: "",
  userName: "",
  mobile: "",
};

const validateFields = {
  name: "회사명",
  ceo: "대표자",
  phone: "대표전화번호",
  address: "주소",
  email: "회사대표메일",
};

const theme = createTheme();

const requiredMessage = "을(를) 입력해주세요.";
const emailMessage = "을(를) 올바른 이메일 형태로 입력해주세요.";

const validateSchema = {
  // bizRegNumber: yup.string().required(requiredMessage),
  name: yup.string().required(requiredMessage),
  ceo: yup.string().required(requiredMessage),
  phone: yup.string().required(requiredMessage),
  // password: yup.string().required(requiredMessage),
  // confirmPassword: yup.string().required(requiredMessage),
  address: yup.string().required(requiredMessage),
  email: yup.string().email(emailMessage).required(requiredMessage),
}

const schema = yup.object(validateSchema).required();

const Profile = () => {
  const { handleSubmit, setValue, control, getValues, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });
  
  const sessionUser = useSelector((state) => state.session.sessionUser);
  const gclientTypes = useSelector((state) => state.gclientType.gclientTypes);
  const sessionUserRole = useSelector((state) => state.session.sessionUser.role);
  const gclientType04docus = useSelector((state) => state.gclientType04docu.gclientType04docus);
  const sessionUserLoginType = useSelector((state) => state.session.sessionUser.sessionUserLoginType);

  // no dispatch
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const selectAllGClientDirect = () => gclientActions.selectAllDirect()

  // dispatch
  const dispatch = useDispatch();

  const modifyUser = ({ id, userId, name, mobile, gclientId }) => dispatch(userActions.modify({ id, userId, name, mobile, gclientId }))
  const deleteUser = (id) => dispatch(userActions.remove(id))
  const gclientLeave = (id) => dispatch(gclientActions.leave(id));
  const modifyGClient = ({ id, gclientTypes, gclientType04docus, name, bizRegNumber, password, confirmPassword, phone, address, email, inCharges, phrases, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => dispatch(gclientActions.modify({ id, gclientTypes, gclientType04docus, name, bizRegNumber, password, confirmPassword, phone, address, email, inCharges, phrases, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }))
  const selectAllByGClient = (gclientId) => dispatch(userActions.selectAllByGClient(gclientId))
  const selectGClientTypes = () => dispatch(gclientTypeActions.selectAll())
  const selectGClientType04docus = () => dispatch(gclientType04docuActions.selectAll())

  const [phoneMask, setPhoneMask] = useState("000-0000-0000");
  const [mobileMask, setMobileMask] = useState("000-0000-0000");

  const [inCharges, setInCharges] = useState([]);

  // 헤더 토글
  const [basicExpanded, setBasicExpanded] = useState(true);
  const [signExpanded, setSignExpanded] = useState(true);
  // 

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedGClientId, setSelectedGClientId] = useState("");
  const [selectedGClient, setSelectedGClient] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedGClientTypeIds, setSelectedGClientTypeIds] = useState([]); // 대분류 선택 id 배열
  const [gclientTypeAndGClientType04docu, setGClientTypeAndGClientType04docu] = useState([]); // 대분류와 하위의 세분류와 세분류 선택 id 배열 및 보이기 여부 플래그 포함 데이터 구조

  // 비밀번호 변경 다이얼로그 관련
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [mode, setMode] = useState("");
  const [type, setType] = useState("");
  const [openEmailAppPasswordDialog, setOpenEmailAppPasswordDialog] = useState(false);
  const [openEmailAppPasswordDialogUser, setOpenEmailAppPasswordDialogUser] = useState(false);
  const [openAddressBookDialog, setOpenAddressBookDialog] = useState(false);

  // 

  const [alertInfo, setAlertInfo] = useState({});

  // 회원탈퇴
  const [leaveObject, setLeaveObject] = useState({});
  const [confirmOpenLeave, setConfirmOpenLeave] = useState(false);

  // 미리보기
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(
    () => {
      if (!objectEmptyCheck(errors)) {
        console.log(errors)
        let error;
        for (const [key, value] of Object.entries(validateFields)) {
          // console.log(`${key}: ${value}`);
          if (errors[key]) {
            error = errors[key];
            error.fieldName = value;
            break;
          }
        }

        // console.log(error)
        if (error) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: `${error?.fieldName}${error?.message}`,
            open: true,
          });
        }
      }
    }, [errors]
  )

  const [URLThumbnail, setURLThumbnail] = useState();

  // 스크롤 트리거 및 스크롤 동작 추가
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const createImageURL = (fileBlob) => {  // createObjectURL 방식
    if (URLThumbnail) URL.revokeObjectURL(URLThumbnail);

    const url = URL.createObjectURL(fileBlob);

    setURLThumbnail(url);
  };

  const onImageChange = (e) => {
    const { files } = e.target;

    if (!files || !files[0]) return;

    const uploadImage = files[0];

    createImageURL(uploadImage);
  };

  useEffect(
    async () => {
      setOpenBackdrop(true);

      const { sessionUserLoginType } = sessionUser;
      if (sessionUserLoginType === "GCLIENT") {
        if (sessionUser.initPassword) {
          setOpenPasswordDialog(true);
          setMode('C');
        }
      } else if (sessionUserLoginType === "USER") {
        if (sessionUser.user.initPassword) {
          setOpenPasswordDialog(true);
          setMode('C');
        }
      }

      // 기본정보 설정
      for (const [item, value] of Object.entries(defaultValues)) {
        setValue(item, sessionUser[item] || value);
      }

      if (sessionUser["phrases"]) {
        const { supplierLabel, supplier, phrase } = sessionUser["phrases"];
        setValue("supplierLabel", supplierLabel);
        setValue("supplier", supplier);
        setValue("phrase", phrase);
      }

      const { id: gclientId } = sessionUser;

      // TODO : redux 데이터가 보낸 순서대로 들어오는지 파악 필요. 지금은 그렇다고 가정하고 되어 있음. 문제발생시 이 문제때문인지 아닌지 우선 고려할 것
      await selectGClientTypes();
      await selectGClientType04docus();
      
      //const resGClients = await selectAllGClientDirect();
      //const gclient = resGClients.find(gclient => gclient.id === gclientId);
      const gclient = await gclientActions.selectDirect(gclientId);

      console.log({ gclient })

      setSelectedGClientId(gclientId);
      setSelectedGClient(gclient);

      // 회원사 사용자일 때 정보 설정
      if (sessionUserLoginType === 'USER') {
        const { user } = sessionUser;
        if (user) {
          const { userId, name, mobile } = user;
          setValue("userId", userId);
          setValue("userName", name);
          setValue("mobile", mobile);
        }
      }
      
      setOpenBackdrop(false);
    }, [dispatch]
  )

  // 초기 페이지 로딩할 때 거래선 구분값 목록 구성 및 설정
  useEffect(
    () => {
      if (gclientTypes.length > 0 && gclientType04docus.length > 0) {
        const { gclientTypes: myGClientTypes, gclientType04docus: myGClientType04docus } = sessionUser;
        
        console.log({ myGClientTypes, myGClientType04docus })
        if (myGClientTypes && Array.isArray(myGClientTypes)) {
          // 대분류 설정 : 대분류 목록은 이미 gclientTypes를 기반으로 만들어져 있고 접속회사의 대분류 구분 값을 설정한다.
          const newSelectedGClientTypeIds = myGClientTypes.map(type => type.id);
          setSelectedGClientTypeIds(newSelectedGClientTypeIds);

          // 세분류 설정
          if (myGClientType04docus && Array.isArray(myGClientType04docus)) {
            // 세분류 목록 및 값 설정
            const newGClientTypeAndGClientType04docu = gclientTypes.map(gclientType => {
              const { id, name } = gclientType;
              return {
                gclientTypeId: id, // 대분류 아이디
                gclientTypeName: name, // 대분류 이름
                gclientType04docus: gclientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === id), // 세분류 목록
                selectedGClientType04docuIds: myGClientType04docus.filter(gclientType04docu => gclientType04docu.gclientTypeId === id).map(type => type.id), // 세분류 설정값
                show: newSelectedGClientTypeIds.includes(id), // 세분류 보이기 옵션
              }
            });
            console.log({ newGClientTypeAndGClientType04docu })
            setGClientTypeAndGClientType04docu(newGClientTypeAndGClientType04docu);
          }
        }
      }
    }, [gclientTypes, gclientType04docus, sessionUser]
  )

  const onSubmit = async ({ id, bizRegNumber, name, phone, address, email, supplierLabel, supplier, phrase, userId, userName, mobile, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership }) => {
    try {
      setLoading(true);

      if (sessionUserRole === 'ADMIN_ROLE') {
        const myGClientTypes = gclientTypes.filter(gclientType => selectedGClientTypeIds.includes(gclientType.id));
        // 아래 코드에서 filter를 통해 대분류가 설정되지 않은 그 하위의 세분류 항목은 제외한다. handleChangeGClientTypes 함수의 설명과 같이 이해
        const selectedGClientType04docuIds = [].concat(
          ...gclientTypeAndGClientType04docu.filter(gclientType => {
            const { gclientTypeId, show, selectedGClientType04docuIds } = gclientType;
            
            if (selectedGClientTypeIds.includes(gclientTypeId) && show && selectedGClientType04docuIds.length > 0) {
              return true;
            } else {
              return false;
            }
          }).map(item => item.selectedGClientType04docuIds)
        ); // arr1d = [].concat(...arr2d)
        // console.log({ selectedGClientTypeIds, selectedGClientType04docuIds, gclientTypeAndGClientType04docu });
        const myGClientType04docus = gclientType04docus
                                      .filter(gclientType04docu => selectedGClientType04docuIds.includes(gclientType04docu.id))
                                      .map(item => ({ ...item, gclientTypeName: myGClientTypes.find(type => type.id === item.gclientTypeId)?.name }));
  
        // 아래 주석 세줄은 전송데이터가 올바른지 API 호출하지 않고 console로 확인하기 위한 테스트 코드임
        // console.log({ id, bizRegNumber, name, phone, address, email, myGClientTypes, myGClientType04docus });
        // setLoading(false);
        // return;
        
        await modifyGClient({
          id,
          gclientTypes: myGClientTypes,
          gclientType04docus: myGClientType04docus,
          bizRegNumber,
          name,
          phone,
          address,
          email,
          inCharges,
          phrases: { supplierLabel, supplier, phrase },
          initPassword,
          loginCount,
          // 판유리협회 회원사 정보 중 추가 컬럼들 : start
          ceo,
          fax,
          joinDate,
          products,
          zipCode,
          phone2,
          otherPhones,
          address2,
          otherAddresses,
          membership,
          // 판유리협회 회원사 정보 중 추가 컬럼들 : end
        });
          
        if (sessionUserLoginType === 'USER') {
          await modifyUser({
            id: sessionUser.user.id,
            userId: sessionUser.user.userId,
            name: userName,
            mobile,
            gclientId: selectedGClientId,
          });
  
          selectAllByGClient(selectedGClientId);
        }
  
        dispatch(sessionActions.restoreSessionUser());
      } else if (sessionUserRole === 'USER_ROLE') {
        await modifyUser({
          id: sessionUser.user.id,
          userId: sessionUser.user.userId,
          name: userName,
          mobile,
          gclientId: selectedGClientId,
        });
  
        dispatch(sessionActions.restoreSessionUser());
      }
    } catch (e) {
      setTimeout(() => setLoading(false), 1000);
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  const handleClickFileDownload = async (documentPath) => {
    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];
  
    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const handleChangeGClientTypes = (e, newSelectedGClientIds) => {
    setSelectedGClientTypeIds(newSelectedGClientIds);

    const newGClientTypeAndGClientType04docu = gclientTypeAndGClientType04docu.map(gclientType => {
      const { gclientTypeId } = gclientType;
      return {
        ...gclientType,
        show: newSelectedGClientIds.includes(gclientTypeId),
      }
    });

    setGClientTypeAndGClientType04docu(newGClientTypeAndGClientType04docu);
  }

  const handleChangeGClientTypeAndGClientType04docu = (e, newSelected, gclientTypeId, idx) => {
    console.log({ e, newSelected, gclientTypeId, idx })
    // newSelected에 선택한 값의 배열이 그대로 들어오므로 기존 선택된 배열과 merge 등의 가공을 할 필요가 없음. (참고) const merge = [...new Set([...arr1, ...arr2])];
    const newGClientTypeAndGClientType04docu = gclientTypeAndGClientType04docu.map(gclientType => {
      if (gclientType.gclientTypeId === gclientTypeId) {
        return {
          ...gclientType,
          selectedGClientType04docuIds: newSelected,
        }
      } else {
        return gclientType;
      }
    });
    
    setGClientTypeAndGClientType04docu(newGClientTypeAndGClientType04docu);
  }

  // const handleClickViewEachDoc = (documentPath) => {
  //   // setOpenProgress(true);
  //   // setShowFileViewer(false);
    
  //   // const ext = getFileType(documentPath)
  //   // setFileType(ext);

  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);
  //   // console.log(path)
  //   // setDocumentPath(path);
    
  //   // setTimeout(() => {
  //   //   setOpenProgress(false);
  //   //   setShowFileViewer(true);
  //   // }, 1000);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }

  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    // window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  const handleClickPasswordChange = (e) => {
    e.stopPropagation();

    setOpenPasswordDialog(true);
    setMode('C');
  }

  const handleChangePhone = (e, type) => {
    const { value } = e.target;
    const fieldName = type === 'fax' ? 'fax' : 'phone';

    const areaCode02 = "02";
    const compareCode = value.substring(0, 2);
    if (compareCode === areaCode02) {
      if (value.length < 12) {
        setPhoneMask("00-000-00000"); // 한자리 여분을 두어야 함. 00-000-0000하면 더 이상 입력이 안됨
      } else {
        setPhoneMask("00-0000-0000");
      }
    } else {
      if(value.length < 13) {
        setPhoneMask("000-000-00000"); // 한자리 여분을 두어야 함
      } else {
        setPhoneMask("000-0000-0000");
      }
    }
    
    //setValue('phone', value);
    setValue(fieldName, value);
  }

  const handleChangeMobile = (e) => {
    const { value } = e.target;
    
    if(value.length < 13) {
      setMobileMask("000-000-00000"); // 한자리 여분을 두어야 함
    } else {
      setMobileMask("000-0000-0000");
    }
    
    setValue('mobile', value);
  }

  const handleClickLeave = () => {
    // selectedGClientId
    let id = "";
    if (sessionUserLoginType === 'GCLIENT') {
      id = selectedGClientId;
    } else if (sessionUserLoginType === 'USER') {
      const { user } = sessionUser;
      if (user) {
        id = user.id;
      }
    }
    
    setLeaveObject({ id });
    setConfirmOpenLeave(true);
  }

  const leave = async ({ id }) => {
    try {
      let messageAlert;
      if (sessionUserLoginType === 'GCLIENT') {
        messageAlert = (<>
          <span>{"탈퇴가 완료되었습니다."}</span><br />
          <span>{"3개월이 지나야 재가입하실 수 있습니다."}</span><br />
          <span>{"그동안 공사다큐 서비스를 사용해 주셔서 감사합니다."}</span>
        </>);
        
        await gclientLeave(id); // 실제로 삭제하지 않고 GClients의 valid = false, leaveDate 기록
      } else if (sessionUserLoginType === 'USER') {
        messageAlert = (<>
          <span>{"탈퇴가 완료되었습니다."}</span><br />
          <span>{"그동안 공사다큐 서비스를 사용해 주셔서 감사합니다."}</span>
        </>);
        
        await deleteUser(id); // 사용자는 실제 삭제
      }
  
      // 먼저 로그아웃 하고 나서 삭제 => 탈퇴 처리후 로그아웃으로 변경
      await dispatch(sessionActions.logout(sessionUser));
      await dispatch(menuActions.removeAllTabMenus());
  
      setAlertInfo({
        titleAlert: "안내",
        messageAlert,
        open: true,
      });
    } catch(e) {
      // TODO : 예외처리 필요
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            // display: 'flex', // 이 속성이 있을 경우 <Box>안에 있는 컴포넌트의 가로 사이즈가 늘어나면 <BOX>도 커짐
            // flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {/* 기본정보 */}
              <Grid item xs={12}>
                {/* TODO : 안의 콤포넌트에 따라 width가 달라지네??? */}
                <Accordion expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}>
                  <AccordionSummary aria-controls="basic-content" id="basic-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={10}>
                        <Typography variant="h6" component="div">{sessionUserLoginType === "GCLIENT" ? "기본정보" : "회원사 정보"}</Typography>
                      </Grid>
                      {
                        sessionUserLoginType === 'GCLIENT' && (
                          <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" alignItems="center">
                            <Button
                              onClick={handleClickPasswordChange}
                              fullWidth
                              variant="outlined"
                              startIcon={<Password />}
                            >
                              {"비밀번호 변경"}
                            </Button>
                          </Grid>
                        )
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="bizRegNumber"
                          name={"bizRegNumber"}
                          control={control}
                          required
                          fullWidth
                          label={"사업자등록번호"}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="name"
                          name={"name"}
                          control={control}
                          required
                          fullWidth
                          label={"회사명"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="ceo"
                          name={"ceo"}
                          control={control}
                          required
                          fullWidth
                          label={"대표자"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="phone"
                          name={"phone"}
                          control={control}
                          required
                          fullWidth
                          label={"대표전화번호"}
                          placeholder={"대표전화번호를 입력해주세요. - 는 자동입력됩니다."}
                          InputProps={{
                            inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                            inputProps: {
                              style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                // mask: "*00-*000-0000",
                                mask: phoneMask,
                                // definitions: {
                                //   '#': /[1-9]/,
                                // },
                              },
                              readOnly: sessionUserRole !== 'ADMIN_ROLE' && true
                            }
                          }}
                          errorStyle={{ message: false, border: true }}
                          onChange={(e) => handleChangePhone(e, 'phone')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="fax"
                          name={"fax"}
                          control={control}
                          fullWidth
                          label={"대표팩스번호"}
                          placeholder={"대표팩스번호를 입력해주세요. - 는 자동입력됩니다."}
                          InputProps={{
                            inputComponent: TextMaskCustom, 
                            inputProps: {
                              style: { 
                                mask: phoneMask,
                              },
                              readOnly: sessionUserRole !== 'ADMIN_ROLE' && true
                            }
                          }}
                          errorStyle={{ message: false, border: true }}
                          onChange={(e) => handleChangePhone(e, 'fax')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          autoComplete="address"
                          name={"address"}
                          control={control}
                          required
                          fullWidth
                          label={"주소"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={sessionUserRole === 'ADMIN_ROLE' ? 8 : 10}>
                        <FormInputText
                          autoComplete="email"
                          name={"email"}
                          control={control}
                          required
                          fullWidth
                          label={"회사대표메일"}
                          inputProps={{ readOnly: sessionUserRole !== 'ADMIN_ROLE' && true }}
                          errorStyle={{ message: false, border: true }}
                        />
                      </Grid>
                      {
                        sessionUserRole === 'ADMIN_ROLE' && (
                          <Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"}>
                            <Button fullWidth onClick={() => setOpenEmailAppPasswordDialog(true)} startIcon={<Email />} variant="outlined">
                              {"메일서명 설정"}
                            </Button>
                          </Grid>
                        )
                      }
                      <Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"}>
                        <Button fullWidth onClick={() => setOpenAddressBookDialog(true)} startIcon={<ContactMail />} variant="outlined">
                           {"주소록"}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Card sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                {"대분류"}
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <ToggleButtonGroup
                                value={selectedGClientTypeIds}
                                onChange={handleChangeGClientTypes}
                                aria-label="GClient Types"
                                color="primary"
                                sx={{ 
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  rowGap: 1,
                                }}
                              >
                                {
                                  gclientTypes.map((type, index) => {
                                    const { id, name } = type;
                                    return (
                                      <ToggleButton
                                        key={id || `gclientType-${index}`}
                                        value={id}
                                        aria-label={`대분류-${name}`}
                                        sx={{
                                          fontWeight: 'bold',
                                        }}
                                        disabled={sessionUserRole === 'ADMIN_ROLE' ? false : true}
                                      >
                                        {name}
                                      </ToggleButton>
                                    )
                                  })
                                }
                              </ToggleButtonGroup>
                            </Grid>
                            {
                              gclientTypeAndGClientType04docu && gclientTypeAndGClientType04docu.filter(gclientType => gclientType.show && gclientType.gclientType04docus.length > 0).length > 0 ? (
                                <>
                                  <Grid item xs={12}>
                                    <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                      {"세분류"}
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Card sx={{ p: 2 }}>
                                      <Grid container spacing={1}>
                                      {
                                        gclientTypeAndGClientType04docu.map((gclientType, idx) => {
                                          const { gclientTypeId, gclientTypeName, gclientType04docus, show, selectedGClientType04docuIds } = gclientType;
                                          return (
                                            <>
                                              {
                                                show && gclientType04docus.length > 0 ? (
                                                  <Grid item xs={12}>
                                                    <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                                      {gclientTypeName}
                                                    </Box>
                                                  </Grid>
                                                ) : (<></>)
                                              }
                                              {
                                                show && gclientType04docus.length > 0 ? (
                                                  <Grid item xs={12}>
                                                    <ToggleButtonGroup
                                                      value={selectedGClientType04docuIds}
                                                      onChange={(e, selectedIds) => handleChangeGClientTypeAndGClientType04docu(e, selectedIds, gclientTypeId, idx)}
                                                      aria-label="GClient Types"
                                                      color="primary"
                                                      sx={{ 
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        rowGap: 1,
                                                      }}
                                                    >
                                                      {
                                                        gclientType.gclientType04docus.map(type => {
                                                          const { id, name } = type;
                                                          return (
                                                            <ToggleButton
                                                              key={id}
                                                              value={id}
                                                              aria-label={`세분류-${name}`}
                                                              sx={{
                                                                fontWeight: 'bold',
                                                              }}
                                                              disabled={sessionUserRole === 'ADMIN_ROLE' ? false : true}
                                                            >
                                                              {name}
                                                            </ToggleButton>
                                                          )
                                                        })
                                                      }
                                                    </ToggleButtonGroup>
                                                  </Grid>
                                                ) : (<></>)
                                              }
                                            </>
                                          )
                                        })
                                      }
                                      </Grid>
                                    </Card>
                                  </Grid>
                                </>
                              ) : (<></>)
                            }
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                        {
                          (sessionUserRole === 'USER_ROLE' || (sessionUserLoginType === 'USER' && sessionUserRole === 'ADMIN_ROLE')) && (
                            <>
                              <Grid item xs={12}>
                                <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                                  <Grid container>
                                    <Grid item xs={12} sm={10} display="flex" alignItems="center">
                                      {"내정보"}
                                    </Grid>
                                      <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" alignItems="center">
                                        <Button
                                          onClick={handleClickPasswordChange}
                                          fullWidth
                                          variant="outlined"
                                          startIcon={<Password />}
                                        >
                                          {"비밀번호 변경"}
                                        </Button>
                                      </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={sessionUserLoginType === 'USER' ? 10 : 12}>
                                <FormInputText
                                  autoComplete="userId"
                                  required
                                  fullWidth
                                  label="사용자 아이디(이메일)"
                                  name={"userId"}
                                  control={control}
                                  errorStyle={{ message: false, border: true }}
                                  inputProps={{ readOnly: true }}
                                />
                              </Grid>
                              {
                                sessionUserLoginType === 'USER' && (
                                  <Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"}>
                                    <Button fullWidth onClick={() => setOpenEmailAppPasswordDialogUser(true)} startIcon={<Email />} variant="outlined">
                                      {"메일서명 설정"}
                                    </Button>
                                  </Grid>
                                )
                              }
                              <Grid item xs={12}>
                                <FormInputText
                                  autoComplete="userName"
                                  name={"userName"}
                                  control={control}
                                  required
                                  fullWidth
                                  label={"이름"}
                                  errorStyle={{ message: false, border: true }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormInputText
                                  autoComplete="mobile"
                                  name={"mobile"}
                                  control={control}
                                  required
                                  fullWidth
                                  label={"모바일"}
                                  InputProps={{
                                    inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                                    inputProps: {
                                      style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                        // mask: "000-0000-0000",
                                        mask: mobileMask,
                                        definitions: {
                                          '#': /[1-9]/,
                                        },
                                      },
                                    }
                                  }}
                                  errorStyle={{ message: false, border: true }}
                                  onChange={handleChangeMobile}
                                />
                              </Grid>
                              </>
                          )
                        }
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <LoadingButton
                          onClick={handleSubmit(onSubmit)}
                          fullWidth
                          variant="contained"
                          loading={loading}
                          // sx={{ mt: 3, mb: 2 }}
                        >
                          {"수정하기"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
               {/* 담당자 */}
              { sessionUserRole === 'ADMIN_ROLE' && (<Grid item xs={12}><Users /></Grid>) }
              {/* 날인정보 */}
              <Grid item xs={12}>
                <Accordion expanded={signExpanded} onChange={() => setSignExpanded(!signExpanded)}>
                  <AccordionSummary aria-controls="sign-content" id="sign-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">{"날인정보"}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CertifiedBox
                              gclient={selectedGClient}
                              control={control}
                              setValue={setValue}
                              getValues={getValues}
                              defaultValues={defaultValues}
                            />
                          </Grid>
                          {
                            sessionUserRole === 'ADMIN_ROLE' && (
                              <Grid item xs={12}>
                                <LoadingButton
                                  onClick={handleSubmit(onSubmit)}
                                  fullWidth
                                  variant="contained"
                                  loading={loading}
                                  // sx={{ mt: 3, mb: 2 }}
                                >
                                  {"수정하기"}
                                </LoadingButton>
                              </Grid>
                            )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* 보안 - 정보공개여부 */}
              { sessionUserRole === 'ADMIN_ROLE' && (<Grid item xs={12}><Security /></Grid>) }
              {/* 일반서류 - 모든 회원사에 반드시 있어야 하므로 Grid가 G04GeneralDocu 바깥에 있음 */}
              <Grid item xs={12}>
                <G04GeneralDocu
                  handleClickFileDownload={handleClickFileDownload}
                  handleClickViewEachDoc={handleClickViewEachDoc}
                  selectedGClient={selectedGClient}
                />
              </Grid>
              {/* 인증서 - 일반서류를 제외한 인증서~기타는 회원사에 따라 해당 문서가 없을 수도 있으므로 Grid가 컴포넌트 안쪽에 있음. 바깥에 있으면 Grid가 공간 차지 */}
              <G04docuGCertification
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 원자재 */}
              <G04docuGTestRawMaterial
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 가공부자재 */}
              <G04docuGTestSubMaterial
                division={'SUB_MATERIAL_PROCESS'}
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 시공부자재 */}
              <G04docuGTestSubMaterial
                division={'SUB_MATERIAL_BUILD'}
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 가공 */}
              <G04docuGTestProcess
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              {/* 기타 */}
              <G04docuEtc
                handleClickFileDownload={handleClickFileDownload}
                handleClickViewEachDoc={handleClickViewEachDoc}
                selectedGClient={selectedGClient}
              />
              <Grid item xs={12}>
                <LoadingButton
                  onClick={handleClickLeave}
                  fullWidth
                  variant="outlined"
                  loading={loading}
                >
                  {"탈퇴하기"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* 위로 가기 버튼 */}
        <Zoom in={trigger}>
            <Fab
              onClick={scrollToTop}
              color="primary"
              size="small"
              aria-label="scroll back to top"
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 300,
              }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Zoom>
      </Container>
      <PasswordChangeDialog
        open={openPasswordDialog}
        setOpen={setOpenPasswordDialog}
        mode={mode}
        setMode={setMode}
        // setCheckValue={setValue}
        // type={type}
        // setType={setType}
        // credential={getValues("bizRegNumber")}
      />
      <EmailSettingDialog
        type={"GCLIENT"}
        open={openEmailAppPasswordDialog}
        setOpen={setOpenEmailAppPasswordDialog}
        credential={getValues("bizRegNumber")}
        email={getValues("email")}
      />
      <EmailSettingDialog
        type={"USER"}
        open={openEmailAppPasswordDialogUser}
        setOpen={setOpenEmailAppPasswordDialogUser}
        credential={getValues("userId")}
        email={getValues("userId")}
      />
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      <ConfirmDialog
        removeId={leaveObject}
        title={"탈퇴안내"}
        open={confirmOpenLeave}
        setOpen={setConfirmOpenLeave}
        onConfirm={leave}
        onCancel={() => {}}
      >
        {
          sessionUserLoginType === 'GCLIENT' && (<>
            <span style={{ color: '#1976d2' }}>{"탈퇴하시면 3개월 이내 재가입이 되지 않습니다."}</span><br /><br />
            <span>{"그래도 탈퇴하시겠습니까?"}</span>
          </>)
        }
        {
          sessionUserLoginType === 'USER' && (<>
            <span style={{ color: '#1976d2' }}>{"탈퇴하시면 사용하셨던 자료는 열람이 되지 않습니다."}</span><br />
            <span style={{ color: "#ff5722" }}>{"추후 동일한 이메일 아이디로 가입하셔도 열람이 되지 않습니다."}</span><br /><br />
            <span>{"그래도 탈퇴하시겠습니까?"}</span>
          </>)
        }
      </ConfirmDialog>
      <AddressBookDialog
        type={"USER"}
        open={openAddressBookDialog}
        setOpen={setOpenAddressBookDialog}
        credential={getValues("userId")}
        email={getValues("userId")}
      />
      <DocumentViewerDrawer 
        open={documentViewerDrawerOpen}
        onClose={() => setDocumentViewerDrawerOpen(false)}
        url={currentUrl}
      />
    </ThemeProvider>
  );
}

export default Profile;