import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  LinearProgress,
} from '@mui/material';
import { DialogTitleClose, PaperComponent } from "../dialog";
import { CustomNoRowsOverlay } from "../datagrid";
import dayjs from 'dayjs';
import * as gclientPaymentsActions from "../../store/gclientPayments";
import { PARTNERSHIP_TYPE, MEMBERSHIP_TYPE, MEMBERSHIP_TYPE_LABELS } from '../../constants/gclientTypes';


const PaymentHistoryDialog = ({ open, setOpen }) => {
  const [loaded, setLoaded] = useState(true);
  const sessionUser = useSelector(state => state.session.sessionUser);

  const dispatch = useDispatch();
  const rows = useSelector((state) => state.gclientPayments.gclientPayments);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const columns = [
    { 
      field: 'membership', 
      headerName: '서비스',
      width: 170,
      alignHeader: 'center',
      valueFormatter: (params) => {
        return params.value ? MEMBERSHIP_TYPE_LABELS[params.value] || params.value : '';
      }
    },
    { 
      field: 'payDate', 
      headerName: '납부일자',
      width: 100,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
      }
    },
    { 
      field: 'amount', 
      headerName: '납부금액',
      width: 80,
      align: 'right',
      valueFormatter: (params) => {
        return params.value ? params.value.toLocaleString() + '원' : '';
      }
    },
    { 
      field: 'startDate', 
      headerName: '시작일자',
      width: 100,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
      }
    },
    { 
      field: 'endDate', 
      headerName: '종료일자',
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
      }
    }
  ];

  useEffect(() => {
    if (open && sessionUser) {
        setLoaded(false);
        dispatch(gclientPaymentsActions.selectAll(sessionUser.id))
        .then((response) => {
        console.log(response);
        })
        .catch(error => {
        console.error('목록 조회 실패:', error);
        setLoaded(true);
        })
        .finally(() => {
        setLoaded(true);
        });
    }
  }, [open, sessionUser]);
  

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {"납부이력"}
      </DialogTitleClose>
      <DialogContent>
      <Box sx={{ width: '100%', height: 200, position: 'relative' }}>
        <Box 
        sx={{ 
            position: 'absolute', 
            top: -20,           
            right: 0,        
            fontSize: '0.75em',
            color: '#666',
            backgroundColor: 'transparent'
        }}
        >
        [VAT포함]
        </Box>
        <Box sx={{ 
        width: '100%', 
        height: '100%',
        mt: 4  // VAT포함 텍스트 아래로 DataGrid 이동
        }}>
      <DataGridPro
        localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
        sx={{ 
          cursor: 'pointer', 
          fontSize: '0.85em',
          '& .MuiDataGrid-cell': {
            padding: '8px'
          },
          '& .MuiDataGrid-columnHeader': {  // 헤더 중앙정렬을 위한 스타일 추가
            justifyContent: 'center',
            '.MuiDataGrid-columnHeaderTitle': {
              textAlign: 'center'
            }
          }
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: LinearProgress,
        }}
        columnHeaderHeight={38}
        rowHeight={34}
        rows={rows}
        columns={columns}
        loading={!loaded}
        hideFooter={true} 
        getRowId={(row) => row.id || `${row.paymentDate}_${row.amount}`}
      />
    </Box>
  </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentHistoryDialog;